import { Component, OnInit, OnDestroy, Input } from '@angular/core';
import { Subscription } from 'rxjs';
import { Content } from 'src/app/core/models/content.model';
import { DynamicLayout } from 'src/app/core/models/dynamic-layout.model';
import { PointsSummary } from 'src/app/shared/models/points-summary';
import { PointsSummaryService } from 'src/app/shared/services/points-summary.service';
import { VetActivitySummary } from '../../models/vet-activity-summary';

@Component({
  selector: 'vet-vet-points-summary',
  templateUrl: './vet-points-summary.component.html',
  styleUrls: ['./vet-points-summary.component.scss']
})
export class VetPointsSummaryComponent implements OnInit, OnDestroy, DynamicLayout {
  @Input() baseContent: Content;

  title: string;
  banner: string;
  body: string;

  private pointsSummarySub: Subscription;
  activitySummary: VetActivitySummary;
  pointsSummary: PointsSummary;

  constructor(private pointsSummaryService: PointsSummaryService) { }

  ngOnInit(): void {
    const titleField = this.baseContent.fields.find(f => f.name === 'Title');
    const bannerField = this.baseContent.fields.find(f => f.name === 'Banner');
    const bodyField = this.baseContent.fields.find(f => f.name === 'Body');
    this.title = titleField.value.value;
    this.banner = bannerField.value.url;
    this.body = bodyField.value.value;

    this.pointsSummarySub = this.pointsSummaryService.pointsSummary$
      .subscribe(pointsSummaryData => {
        this.pointsSummary = pointsSummaryData;
      });

    this.pointsSummaryService.getVetActivitySummary().then(activitySummaryData => {
      this.activitySummary = activitySummaryData;
    });
  }

  ngOnDestroy(): void {
    if (this.pointsSummarySub) {
      this.pointsSummarySub.unsubscribe();
    }
  }
}
