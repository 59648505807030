<div class="login-page">
  <app-header heading-copy="# {{title}}" top-banner-url="{{banner}}"></app-header>
  <div class="container text-center">
    <form *ngIf="loginForm" [formGroup]="loginForm" (ngSubmit)="onSubmit()" onsubmit="return false"
      class="form-standard">

      <h4>ENTER YOUR DETAILS TO LOGIN</h4>
      <p>&nbsp;</p>
      <div *ngIf="message !== ''" class="text-center">
        <p><i>{{message}}</i></p>
      </div>
      <div class="row">
        <div class="col-lg-12 col-md-12 col-sm-12 col-xs-12">
          <div class="row">
            <div class="col-lg-1 col-md-4 col-sm-6 col-xs-12">
              <label>EMAIL</label>
            </div>
            <div class="col-lg-5 col-md-4 col-sm-6 col-xs-12">
              <input name="email" formControlName="email" class="form-control text-input" type="text"
                placeholder="Enter your email address" />
            </div>
            <div class="col-lg-1 col-md-4 col-sm-6 col-xs-12 pl-0">
              <label>PASSWORD</label>
            </div>
            <div class="col-lg-5 col-md-4 col-sm-6 col-xs-12">
              <input formControlName="password" class="form-control text-input" type="password"
                placeholder="Enter your password" />
            </div>

          </div>

          <div class="row">
            <div class="col-lg-12 col-md-12 col-sm-12 col-xs-12">
              <button class="btn btn-primary" type="submit">LOG IN</button>
            </div>
          </div>
        </div>
      </div>
    </form>
    <div class="row"></div>
    <div class="row">
      <div class="col-lg-12 col-md-12 col-sm-12 col-xs-12">
        <div class="row">
          <div class="col-lg-2 col-md-2 hidden-sm hidden-xs"></div>
          <div class="col-lg-8 col-md-8 col-sm-6 col-xs-12">
            <a href={{forgotPasswordUrl}}>Forgotten your password?</a>
          </div>
          <div class="col-lg-2 col-md-2 hidden-sm hidden-xs"></div>
        </div>

      </div>

    </div>
    <div class="row">


    </div>
    <app-footer></app-footer>
  </div>
</div>