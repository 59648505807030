import { Component, Input, OnInit } from '@angular/core';
import { TranslateService } from '@ngx-translate/core';
import { ContentService } from 'src/app/core/content/content.service';
import { Content } from 'src/app/core/models/content.model';
import { DynamicLayout } from 'src/app/core/models/dynamic-layout.model';

@Component({
  selector: 'app-faq-page',
  templateUrl: './faq-page.component.html',
  styleUrls: ['./faq-page.component.scss']
})
export class FaqPageComponent implements OnInit, DynamicLayout {
  @Input() baseContent: Content;

  faqListContent: Content;
  faqs: any[];

  body: string;
  title: string;
  banner: string;

  constructor(
    private contentService: ContentService,
    private translateService: TranslateService
  ) { }

  ngOnInit(): void {

    this.contentService
      .getContentById(this.baseContent.id, this.translateService.currentLang, true)
      .subscribe((response) => {
        console.log(response);
        this.faqListContent = response.data.contentById;
        console.log(this.faqListContent);

        this.faqs = this.faqListContent.children.map((c) => {
          const heading = c.fields.find((f) => f.name === 'Heading');
          const body = c.fields.find((f) => f.name === 'Body');
          return {
            heading: heading ? heading.value.value : undefined,
            body: body ? body.value.value : undefined,
          };
        });
      });

    const titleField = this.baseContent.fields.find(f => f.name === 'Title');
    const bannerField = this.baseContent.fields.find(f => f.name === 'Banner');
    const bodyField = this.baseContent.fields.find(f => f.name === 'Body');

    this.title = titleField.value.value;
    this.body = bodyField.value.value;
    this.banner = bannerField.value.url;
  }

}
