<div *ngIf="members.length >= 1" class="row">
  <div class="col-sm-12">
    <table class="table">
      <thead>
        <tr>
          <th>FIRST NAME</th>
          <th>LAST NAME</th>
          <th>VET PRACTICE NAME</th>
          <th>EMAIL ADDRESS</th>
          <th>POSTCODE</th>
          <th class="green-highlight">TRANSFER</th>
        </tr>
      </thead>
      <tbody *ngFor="let item of members; let i = index">
        <tr>
          <td>{{ item.firstName }}</td>
          <td>{{ item.lastName }}</td>
          <td>{{ item.vetPracticeName }}</td>
          <td>{{ item.email }}</td>
          <td>{{ item.postcode }}</td>
          <td class="green-highlight">
            <button type="button" class="btn btn-info" (click)="selectMember(item)">+</button
            ><img src="../../../../assets/images/tick.png" />
          </td>
        </tr>
      </tbody>
    </table>
  </div>
</div>
