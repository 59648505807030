import { Component, Input, OnInit } from '@angular/core';
import { FormBuilder, FormControl, FormGroup, Validators } from '@angular/forms';
import { Content } from 'src/app/core/models/content.model';
import { DynamicLayout } from 'src/app/core/models/dynamic-layout.model';
import { SettingsProvider } from 'src/app/shared/settings.provider';
import { User } from '../../models/user';
import { SupportService } from '../../services/support.service';

@Component({
  selector: 'app-contact-form',
  templateUrl: './contact-form.component.html',
  styleUrls: ['./contact-form.component.scss'],
})
export class ContactFormComponent implements OnInit, DynamicLayout {
  @Input() baseContent: Content;
  user: User;
  contactUsForm: FormGroup;
  submitting = false;
  subjects = [
    'Points Balance Query',
    'Rewards Redemption Query',
    'Change Of Contact Details',
    'Other',
  ];
  message: string;
  isVet: boolean;

  body: string;
  title: string;
  banner: string;

  constructor(
    private fb: FormBuilder,
    private settingsProvider: SettingsProvider,
    private supportService: SupportService,
  ) {}

  async ngOnInit() {
    this.user = this.settingsProvider.settings.data?.user;
    const currentPage = window.location.pathname;
    if (currentPage.startsWith('/vet')) {
      this.isVet = true;
      this.subjects = [
        'Points Balance Query',
        'Rewards Query',
        'Change Of Contact Details',
        'Other',
      ];
    } else if (currentPage.startsWith('/account-manager')) {
      this.subjects = [
        "Vet practice details can't be found",
        'Points balance query',
        "Activity I want to award points for isn't shown an option to select",
      ];
    }

    this.initialiseContactUsForm();

    const titleField = this.baseContent.fields.find((f) => f.name === 'Title');
    const bannerField = this.baseContent.fields.find((f) => f.name === 'Banner');
    const bodyField = this.baseContent.fields.find((f) => f.name === 'Body');

    this.title = titleField.value.value;
    this.body = bodyField.value.value;
    this.banner = bannerField.value.url;
  }

  initialiseContactUsForm() {
    if (this.user) {
      this.contactUsForm = new FormGroup({
        name: new FormControl(`${this.user.firstName} ${this.user.lastName}`),
        message: new FormControl('', [Validators.required]),
        subject: new FormControl(''),
        email: new FormControl(this.user.email),
      });
    } else {
      this.contactUsForm = new FormGroup({
        name: new FormControl('', [Validators.required]),
        message: new FormControl('', [Validators.required]),
        subject: new FormControl(''),
        email: new FormControl('', [Validators.required]),
      });
    }
  }

  async onSubmit() {
    console.log(this.contactUsForm);

    if (this.contactUsForm.valid) {
      let endpoint = 'cat';
      if (this.isVet) {
        endpoint = 'vet';
      }
      this.supportService
        .submitHelpRequest({ form: this.contactUsForm.value, endpoint })
        .then((result) => {
          this.message = 'Your request has been successfully submitted';
          this.initialiseContactUsForm();
        })
        .catch((error) => {
          this.message = 'There was an error sending your request, please try again';
        });
    } else {
      this.message = 'Please ensure you have completed all fields';
    }
  }
}
