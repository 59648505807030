<div class="row" *ngIf="product">
  <div class="col-4">
    <img
      *ngIf="!product.images || product.images.length == 0"
      class="card-img-top"
      src="https://via.placeholder.com/150.jpg"
    />
    <img
      *ngIf="product.images && product.images.length > 0"
      class="card-img-top"
      [src]="product.images[0].thumbnailBlobStorageUrl"
    />    
  </div>
  <div class="col-8">
    <h5 *ngIf="product.programProducts[0] && product.programProducts[0].currency && product.isVoucher">
      {{product.programProducts[0].currency.symbol}}{{product.programProducts[0].cost}} {{ product.name }}</h5>
    <h5 *ngIf="!product.isVoucher">{{ product.name }}</h5>
    <span class="text-bold">Points: {{ product.points }}</span>
    <br />
    <span>Product Code: {{ product.sku }}</span>
    <div class="mt-20">{{ product.description }}</div>
  </div>
</div>
