<div class="award-points-page">
  <app-header heading-copy="# {{ title }}" top-banner-url="{{ banner }}"></app-header>

  <div class="search-container">
    <div class="container">
      <form
        *ngIf="searchParamsForm"
        [formGroup]="searchParamsForm"
        (ngSubmit)="onSubmit()"
        class="form-standard"
      >
        <h2>&nbsp;</h2>
        <div class="row">
          <div class="col-md-7">
            <h2><strong>SEARCH POINTS AWARDED</strong></h2>
          </div>
          <div class="col-sm-5 btn-col">
            <div class="form-group">
              <button class="btn btn-info" type="button" (click)="onExport()">EXPORT</button>
              <button class="btn btn-info" type="button" (click)="onResetSearch()">CLEAR X</button>
              <button class="btn btn-primary" type="submit">SEARCH</button
              ><img src="../../../../assets/images/tick.png" />
            </div>
          </div>
        </div>

        <div class="row">
          <div class="col-sm-4">
            <div class="row">
              <div class="col-lg-3 col-md-3 col-sm-6 col-xs-12">
                <label>FROM</label>
              </div>
              <div class="col-lg-9 col-md-9 col-sm-6 col-xs-12">
                <input
                  name="fromDate"
                  formControlName="fromDate"
                  class="form-control text-input"
                  type="date"
                />
              </div>
            </div>
          </div>
          <div class="col-sm-4">
            <div class="row">
              <div class="col-lg-3 col-md-3 col-sm-6 col-xs-12">
                <label>TO</label>
              </div>
              <div class="col-lg-9 col-md-9 col-sm-6 col-xs-12">
                <input
                  name="toDate"
                  formControlName="toDate"
                  class="form-control text-input"
                  type="date"
                />
              </div>
            </div>
          </div>
          <div class="col-sm-4">
            <div class="row">
              <div class="col-lg-4 col-md-4 col-sm-6 col-xs-12">
                <label>&nbsp;</label>
              </div>
              <div class="col-lg-8 col-md-8 col-sm-6 col-xs-12">
                <input
                  name="search"
                  formControlName="search"
                  class="form-control text-input"
                  type="text"
                  placeholder="Search by Vet Practice Details"
                />
              </div>
            </div>
          </div>
        </div>
        <h2>&nbsp;</h2>
      </form>
    </div>
  </div>
  <div class="container">
    <h2>&nbsp;</h2>
    <h2><strong>POINTS TRANSFERS</strong></h2>
    <div *ngIf="visibleTransfers.length >= 1" class="row">
      <div class="col-sm-12">
        <table class="table">
          <thead>
            <tr>
              <th>VET PRACTICE</th>
              <th>CONTACT</th>
              <th>EMAIL ADDRESS</th>
              <th>AWARDED FOR</th>
              <th>POINTS</th>
              <th>VALUE</th>
              <th>DATE</th>
            </tr>
          </thead>
          <tbody *ngFor="let item of visibleTransfers; let i = index">
            <tr>
              <td>{{ item.vetPracticeName }}</td>
              <td>{{ item.firstName }} {{ item.lastName }}</td>
              <td>{{ item.emailAddress }}</td>
              <td>{{ item.pointsAwardedFor }}</td>
              <td>{{ item.totalPoints }}</td>
              <td>£{{ item.totalPointsValue }}</td>
              <td>{{ item.dateAwarded | date: 'dd-MM-yyyy' }}</td>
            </tr>
          </tbody>
        </table>
      </div>
    </div>
  </div>
  <app-footer></app-footer>
</div>
