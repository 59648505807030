import { User } from 'src/app/shared/models/user';

export class Settings {
  siteId: number;
  language: string;
  clientCode: string;
  user: User;
  claims: string[];
  siteSettings: any;
  programGroupCode: string;
  country: any;
  data: any;
}
