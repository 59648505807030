<div class="footer">
    <div class="row">
        <div class="col-lg-6 col-md-6 col-sm-12 col-xs-12 text">
            <p><small>&copy;{{currentYear}} NORBROOK LABORATORIES (G.B) LIMITED</small></p>
        </div>
        <div class="col-lg-6 col-md-6 col-sm-12 col-xs-12 norbrook-logo">
            <img src="../../../assets/images/norbrook-logo.png" />
        </div>
    </div>
    <hr />
</div>