import { Component, OnInit, Input } from '@angular/core';
import { AuthenticationService } from '../../services/authentication.service';
import { PointsSummaryService } from '../../services/points-summary.service';

@Component({
  selector: 'app-header',
  templateUrl: './header.component.html',
  styleUrls: ['./header.component.scss'],
})
export class HeaderComponent implements OnInit {
  // tslint:disable-next-line: no-input-rename
  @Input('heading-copy') headingCopy: string;
  // tslint:disable-next-line: no-input-rename
  @Input('top-banner-url') topBannerUrl: string;
  @Input() showHeaderSummary = false;

  headerContent = {
    bannerUrl: '../../../assets/images/terms-banner.jpg',
    headings: [],
  };
  redirectUrl: string;

  constructor(
    private authService: AuthenticationService,
    private pointsSummaryService: PointsSummaryService,
  ) {}

  ngOnInit(): void {
    if (this.headingCopy !== null && this.headingCopy !== '') {
      if (this.headingCopy.indexOf('# ') > -1) {
        const headings = this.headingCopy.split('# ');
        this.headerContent.headings = headings.map((heading) => {
          return this.formatHeading(heading);
        });
      } else {
        this.headerContent.headings.push(this.formatHeading(this.headingCopy));
      }
    }

    if (this.topBannerUrl !== null && this.topBannerUrl !== '') {
      this.headerContent.bannerUrl = this.topBannerUrl;
    }

    this.authService.checkCurrentUserStatus().then((isLoggedIn) => {
      if (isLoggedIn) {
        if (window.location.pathname.startsWith('/vet')) {
          this.redirectUrl = '/vet/home';
        } else {
          this.redirectUrl = '/account-manager/home';
        }
      } else {
        this.redirectUrl = '/';
      }
    });
  }

  formatHeading(headingText: string): string {
    headingText = this.parseMarkdown(headingText);
    headingText = headingText.replace('&amp;reg;', '<sup>&reg;</sup>');
    return headingText;
  }

  parseMarkdown(markdownText: string): string {
    const htmlText = markdownText
      .replace(/^\> (.*$)/gim, '<blockquote>$1</blockquote>')
      .replace(/\*\*(.*)\*\*/gim, '<strong>$1</strong>')
      .replace(/\*(.*)\*/gim, '<i>$1</i>')
      .replace(/\[(.*?)\]\((.*?)\)/gim, '<a href="$2">$1</a>')
      .replace(/\n$/gim, '<br />');

    return htmlText.trim();
  }
}
