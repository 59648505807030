import { AcceptTermsComponent } from 'src/app/account-manager/components/accept-terms/accept-terms.component';
import { AccountManagerHomeComponent } from 'src/app/account-manager/components/home/account-manager-home.component';
import { ArticleListPageComponent } from 'src/app/shared/components/articles/article-list-page/article-list-page.component';
import { ContactFormComponent } from 'src/app/shared/components/contact-form/contact-form.component';
import { DefaultTemplateComponent } from 'src/app/shared/components/default-template/default-template.component';
import { DynamicLayoutComponent } from 'src/app/shared/components/dynamic-layout/dynamic-layout.component';
import { FaqPageComponent } from 'src/app/shared/components/faq/faq-page.component';
import { LandingPageComponent } from 'src/app/shared/components/landing-page/landing-page.component';
import { LoginComponent } from 'src/app/shared/components/login/login.component';
import { ProfilePageComponent } from 'src/app/shared/components/profile/profile-page/profile-page.component';
import { ResetPasswordComponent } from 'src/app/shared/components/reset-password/reset-password.component';
import { CartPageComponent } from 'src/app/vet/components/cart-page/cart-page.component';
import { CatRegistrationPageComponent } from '../../vet/components/cat-owner-registration/registration-page/cat-registration-page.component';
import { CataloguePageComponent } from 'src/app/vet/components/catalogue-page/catalogue-page.component';
import { CheckoutDetailsPageComponent } from 'src/app/vet/components/checkout-details-page/checkout-details-page.component';
import { VetHomeComponent } from 'src/app/vet/components/home/vet-home.component';
import { MemberManagerPageComponent } from 'src/app/vet/components/member-manager-page/member-manager-page.component';
import { ProductDetailPageComponent } from 'src/app/vet/components/product-catalogue/product-detail-page/product-detail-page.component';
import { RedemptionCompletePageComponent } from 'src/app/vet/components/redemption-complete-page/redemption-complete-page.component';
import { VetPointsSummaryComponent } from 'src/app/vet/components/vet-points-summary/vet-points-summary.component';
import { VetRegistrationPageComponent } from 'src/app/vet/components/vet-registration/registration-page/vet-registration-page.component';
import { VetRegistrationSuccessComponent } from 'src/app/vet/components/vet-registration/registration-success/registration-success.component';
import { MemberSummaryComponent } from 'src/app/vet/components/member-summary/member-summary.component';

export const LayoutRoutes = [
  {
    path: 'vet/rewards/product/:id',
    component: ProductDetailPageComponent,
  },
  {
    path: '',
    component: DynamicLayoutComponent,
    children: [
      {
        path: '',
        component: LandingPageComponent,
      },
      {
        path: 'reset-password',
        component: ResetPasswordComponent,
      },
      {
        path: 'vet/register',
        component: VetRegistrationPageComponent,
      },
      {
        path: 'vet/registration-successful',
        component: VetRegistrationSuccessComponent,
      },
      {
        path: 'vet/login',
        component: LoginComponent,
      },
      {
        path: 'vet/forgot-password',
        component: ResetPasswordComponent,
      },
      {
        path: 'vet/home',
        component: VetHomeComponent,
      },
      {
        path: 'vet/rewards',
        component: CataloguePageComponent,
      },
      {
        path: 'vet/cart',
        component: CartPageComponent,
      },
      {
        path: 'vet/redemption-complete',
        component: RedemptionCompletePageComponent,
      },
      {
        path: 'vet/checkout-details',
        component: CheckoutDetailsPageComponent,
      },
      {
        path: 'vet/profile',
        component: ProfilePageComponent,
      },
      {
        path: 'vet/account-summary',
        component: DefaultTemplateComponent,
      },
      {
        path: 'vet/tools-and-cpd',
        component: ArticleListPageComponent,
      },
      {
        path: 'vet/promotions',
        component: ArticleListPageComponent,
      },
      {
        path: 'vet/contact-us',
        component: ContactFormComponent,
      },
      {
        path: 'vet/faq',
        component: FaqPageComponent,
      },
      {
        path: 'vet/earn-points',
        component: DefaultTemplateComponent,
      },
      {
        path: 'vet/terms-and-conditions',
        component: DefaultTemplateComponent,
      },
      {
        path: 'vet/activity-summary',
        component: VetPointsSummaryComponent,
      },
      {
        path: 'account-manager/login',
        component: LoginComponent,
      },
      {
        path: 'account-manager/accept-terms',
        component: AcceptTermsComponent,
      },
      {
        path: 'account-manager/forgot-password',
        component: ResetPasswordComponent,
      },
      {
        path: 'account-manager/home',
        component: AccountManagerHomeComponent,
      },
      {
        path: 'account-manager/terms-and-conditions',
        component: DefaultTemplateComponent,
      },
      {
        path: '',
        redirectTo: '/',
        pathMatch: 'full',
      },
      {
        path: '**',
        component: DynamicLayoutComponent,
      },
    ],
  },
];
