<form [formGroup]="filtersForm" class="form-catalog-filter">
  <div class="row">
    <div class="col-md-4 col-sm-12 col-xs-12 filter-container">
      <div class="form-group">
        <label>FILTER BY POINTS:</label>
        <select formControlName="range" class="form-control">
          <option *ngFor="let range of pointsRange">{{ range.label }}</option>
        </select>
      </div>
    </div>
    <div class="col-md-4 col-sm-12 col-xs-12 filter-container">
      <div class="form-group">
        <label>SORT:</label>
        <select formControlName="resultOrder" class="form-control">
          <option *ngFor="let resultOrder of resultOrders">{{ resultOrder.label }}</option>
        </select>
      </div>
    </div>
    <div class="col-md-4 col-sm-12 col-xs-12 filter-container">
      <div class="form-group">
        <label>SEARCH BY PRODUCT:</label>
        <div class="input-group ">
          <input
            type="text"
            formControlName="text"
            class="form-control search-control"
            placeholder="Search"
          />
          <div class="input-group-append search-control">
            <button type="submit" class="btn-product-filter">
              <span class="input-group-text search-control">
                <i class="fas fa-search"></i>
              </span>
            </button>
          </div>
        </div>
        <label class="text-right" (click)="clearSearch()"><small>Clear Search</small></label>
      </div>
    </div>
  </div>
</form>
