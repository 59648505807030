import { Component, Input, OnDestroy, OnInit } from '@angular/core';
import { FormControl, FormGroup, Validators } from '@angular/forms';
import { Router } from '@angular/router';
import { Subscription } from 'rxjs';
import { Content } from 'src/app/core/models/content.model';
import { DynamicLayout } from 'src/app/core/models/dynamic-layout.model';
import { PointsSummaryService } from 'src/app/shared/services/points-summary.service';
import { SettingsProvider } from 'src/app/shared/settings.provider';
import { MemberSearchParams } from 'src/app/vet/models/member-search-params';
import { MemberManagerService } from 'src/app/vet/services/member-manager.service';

@Component({
  selector: 'app-award-points',
  templateUrl: './award-points.component.html',
  styleUrls: ['./award-points.component.scss'],
})
export class AwardPointsComponent implements OnInit, OnDestroy, DynamicLayout {
  @Input() baseContent: Content;
  title: string;
  banner: string;
  submitted = false;
  submitModel: MemberSearchParams = {
    email: '',
    firstName: '',
    lastName: '',
    postcode: '',
    phone: '',
    vetPracticeName: '',
  } as MemberSearchParams;
  searchParamsForm: FormGroup;
  formInvalidAfterSubmit: boolean;
  errorMessages: string;
  private resultsSubscription: Subscription;
  private selectedMemberSub: Subscription;

  totalPointsAvailable: number;
  totalPointsValue: number;

  constructor(
    private pointsSummaryService: PointsSummaryService,
    private settingsProvider: SettingsProvider,
    private router: Router,
    private managerService: MemberManagerService,
  ) {}

  ngOnInit(): void {
    const titleField = this.baseContent.fields.find((f) => f.name === 'Title');
    const bannerField = this.baseContent.fields.find((f) => f.name === 'Banner');

    this.title = titleField.value.value;
    this.banner = bannerField.value.url;

    this.initSearchForm();

    this.selectedMemberSub = this.managerService.selectedMember$.subscribe((member) => {
      if (member.account !== null) {
        this.searchParamsForm.controls.email.patchValue(member.account.email);
        this.searchParamsForm.controls.firstName.patchValue(member.account.firstName);
        this.searchParamsForm.controls.lastName.patchValue(member.account.lastName);
        this.searchParamsForm.controls.phone.patchValue(member.account.phone);
        this.searchParamsForm.controls.vetPracticeName.patchValue(member.account.vetPracticeName);
        this.searchParamsForm.controls.postcode.patchValue(member.account.postcode);
      } else {
        this.searchParamsForm.controls.email.patchValue('');
        this.searchParamsForm.controls.firstName.patchValue('');
        this.searchParamsForm.controls.lastName.patchValue('');
        this.searchParamsForm.controls.phone.patchValue('');
        this.searchParamsForm.controls.vetPracticeName.patchValue('');
        this.searchParamsForm.controls.postcode.patchValue('');
      }
    });

    this.pointsSummaryService.getPointsSummary().subscribe((pointsSummary) => {
      this.totalPointsAvailable = pointsSummary.available;
      this.totalPointsValue = this.totalPointsAvailable * 0.01;
    });
  }

  initSearchForm(): void {
    this.searchParamsForm = new FormGroup({
      email: new FormControl('', [Validators.email]),
      firstName: new FormControl(''),
      lastName: new FormControl(''),
      postcode: new FormControl(''),
      phone: new FormControl(''),
      vetPracticeName: new FormControl(''),
    });
  }

  ngOnDestroy(): void {
    if (this.resultsSubscription) {
      this.resultsSubscription.unsubscribe();
    }
    if (this.selectedMemberSub) {
      this.selectedMemberSub.unsubscribe();
    }
  }

  onSubmit() {
    this.submitted = true;
    this.errorMessages = '';
    let hasParamValue = false;

    if (this.searchParamsForm.valid) {
      const formData = this.searchParamsForm.getRawValue();

      Object.keys(this.submitModel).forEach((key) => {
        if (formData[key] !== null && formData[key] !== '') {
          hasParamValue = true;
        }
        this.submitModel[key] = formData[key];
      });

      this.formInvalidAfterSubmit = false;

      this.managerService
        .submitMemberSearch(this.submitModel)
        .then((data) => {
          this.managerService.updateMemberResults(data.results);
          if (data.results.length === 0) {
            this.errorMessages =
              'We cannot find a record of this vet practice as being registered for the Norbrook Advantage Programme.';
          }
        })
        .catch((error) => {
          console.log('Error occurred.', error);
        });
    } else {
      this.formInvalidAfterSubmit = true;
      this.errorMessages = 'You must enter at least one value to search.';
    }
    //}
  }

  resetSearch(): void {
    this.managerService.resetSelectedMember();
    this.initSearchForm();
  }
}
