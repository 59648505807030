import { Address } from 'cluster';
import { BaseModel } from 'src/app/core/models/base-model';
import { UserRole } from './user-profile';

export class User extends BaseModel {
  firstName: string;
  lastName: string;
  email: string;
  title: string;
  homeTelephone: string;
  businessTelephone: string;
  mobileTelephone: string;
  acceptedTermsConditions: boolean;
  registrationDate: Date;
  programId: number;
  statusId: number;
  comment: string;
  address: Address;
  userMeta: any;
  userRole: UserRole;
  companyName: string;
}
