import { Component, OnInit } from '@angular/core';
import { CognitoHostedUIIdentityProvider } from '@aws-amplify/auth';
import base64url from 'base64url';
import { SettingsProvider } from 'src/app/shared/settings.provider';
import { ActivatedRoute, Router } from '@angular/router';
import { AuthenticationService } from '../../services/authentication.service';
import { UserService } from '../../services/user.service';
import { Auth } from 'aws-amplify';

@Component({
  selector: 'app-login-impersonate',
  templateUrl: './login-impersonate.component.html',
  styleUrls: ['./login-impersonate.component.scss'],
})
export class LoginImpersonateComponent implements OnInit {
  constructor(
    private settingsProvider: SettingsProvider,
    private route: ActivatedRoute,
    private router: Router,
    private authenticationService: AuthenticationService,
    private userService: UserService
  ) {}

  async ngOnInit(): Promise<void> {
    const userId = this.route.snapshot.queryParams.impersonateUserId;

    this.authenticationService.stopImpersonation();

    let loggedIn = false;

    try {
      await Auth.currentAuthenticatedUser();
      loggedIn = true;
    } catch (error) {
      console.log(error);
    }

    if (loggedIn) {
      await this.authenticationService.setImpersonateUser(userId);
      await this.settingsProvider.init();

      console.log('LOGIN IMPERSONATE PAGE: Redirect Home');
      this.router.navigate(['/auth/redirect']);
    } else {
      const state = {
        impersonateUserId: userId,
      };
      console.log(state);
      const stateString: string = JSON.stringify(state);
      console.log(stateString);
      const base64State = base64url.encode(stateString);

      console.log(base64State);

      Auth.federatedSignIn({
        provider: CognitoHostedUIIdentityProvider.Cognito,
        customState: base64State,
      }).then(cred => {
          console.log(cred);
          return Auth.currentAuthenticatedUser();
      }).then(user => {
          console.log(user);
          return Auth.currentAuthenticatedUser();
      }).catch(e => {
          console.log(e)
      }).finally(() => {
        this.authenticationService.setImpersonateUser(userId);
        this.settingsProvider.init();

      console.log('LOGIN IMPERSONATE PAGE: Redirect Home');
      this.router.navigate(['/auth/redirect']);

      });

    }
  }
}
