<div class="col-lg-12 col-md-12 col-sm-12 col-xs-12">
  <div class="article-list">

    <div class="articles-section">
      <div class="row">
        <app-article *ngFor="let article of articles" [article]="article"
          class="col-lg-12 col-md-12 col-sm-12 col-xs-12"></app-article>

      </div>
    </div>
  </div>
</div>