<div class="row" *ngIf="pointsSummary">
  <div class="col-lg-9 col-md-12 col-sm-12 col-xs-12 points-summary">
    <div class="row">
      <div class="col-lg-4 col-md-4 col-sm-4 col-xs-12">
        <h2 class="header"><strong>POINTS SUMMARY</strong></h2>
      </div>
      <div class="col-lg-4 col-md-4 col-sm-4 col-xs-12">
        <p><strong>POINTS</strong></p>
      </div>
      <div class="col-lg-4 col-md-4 col-sm-4 col-xs-12">
        <p><strong>VALUE</strong></p>
      </div>
    </div>
    <div class="row">
      <div class="col-lg-4 col-md-4 col-sm-4 col-xs-12">
        <p class="header"><strong>TOTAL POINTS ALLOCATION</strong></p>
      </div>
      <div class="col-lg-4 col-md-4 col-sm-4 col-xs-12">
        <p class="light-blue">{{ pointsSummary.earned }}</p>
      </div>
      <div class="col-lg-4 col-md-4 col-sm-4 col-xs-12">
        <p class="light-blue">£{{ (pointsSummary.earned * 0.01) | number:'1.2-2' }}</p>
      </div>
    </div>
    <div class="row">
      <div class="col-lg-4 col-md-4 col-sm-4 col-xs-12">
        <p class="header"><strong>POINTS AWARDED TO DATE</strong></p>
      </div>
      <div class="col-lg-4 col-md-4 col-sm-4 col-xs-12">
        <p class="light-blue">{{ pointsSummary.redeemed }}</p>
      </div>
      <div class="col-lg-4 col-md-4 col-sm-4 col-xs-12">
        <p class="light-blue">£{{ (pointsSummary.redeemed * 0.01) | number:'1.2-2' }}</p>
      </div>
    </div>

    <div class="row">
      <div class="col-lg-4 col-md-4 col-sm-4 col-xs-12">
        <p class="header"><strong>TOTAL POINTS REMAINING</strong></p>
      </div>
      <div class="col-lg-4 col-md-4 col-sm-4 col-xs-12">
        <p class="light-blue">{{ pointsSummary.available }}</p>
      </div>
      <div class="col-lg-4 col-md-4 col-sm-4 col-xs-12">
        <p class="light-blue">£{{ (pointsSummary.available * 0.01) | number:'1.2-2' }}</p>
      </div>
    </div>
  </div>
  <div class="col-lg-3 col-md-12 col-sm-12 col-xs-12">
    <div class="next-redemption-box">
      <transfers-made-box [pointsSummary]="pointsSummary"></transfers-made-box>
    </div>
  </div>
</div>
