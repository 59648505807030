import { Injectable } from '@angular/core';
import { BaseCoreService } from 'src/app/core/services/base-core-service';
import { Profile } from '../models/profile';
import { SubmissionResult } from 'src/app/core/models/form/submission-result';
import { ApiRestService } from '@motivforce/mx-library-angular';

@Injectable({
  providedIn: 'root'
})
export class ProfileService extends BaseCoreService {

  constructor(protected apiRestService: ApiRestService) {
    super('norbrook/profile', apiRestService);
  }

  async getProfile(): Promise<any> {
    return await this.apiRestService
      .get<Profile>(this.memberBaseUrl)
      .toPromise();
  }

  async updateVetProfile(model: Profile): Promise<any> {
    const endpoint = `${this.memberBaseUrl}/vet/update`;
    return await this.apiRestService.put<SubmissionResult>(endpoint, model).toPromise();
  }

  async updateCatProfile(model: Profile): Promise<any> {
    const endpoint = `${this.memberBaseUrl}/cat/update`;
    return await this.apiRestService.put<SubmissionResult>(endpoint, model).toPromise();
  }

  async updateCatDeceased(id: number): Promise<any> {
    const endpoint = `${this.memberBaseUrl}/cat/update/deceased/${id}`;
    return await this.apiRestService.post<any>(endpoint, {}).toPromise();
  }
}
