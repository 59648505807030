<ng-container *ngIf="cart">
  <div class="row">
    <div class="col-12">
      <div class="row">
        <h2>Please review your order</h2>
        <p>&nbsp;</p>
      </div>      
    </div>
  </div>
  <div class="row cart-heading">
    <div class="col-sm-2 cart-heading-col">PRODUCT</div>
    <div class="col-sm-4 cart-heading-col">DESCRIPTION</div>
    <div class="col-sm-2 cart-heading-col">QUANTITY</div>
    <div class="col-sm-2 cart-heading-col">POINTS</div>
    <div class="col-sm-2 cart-heading-col">TOTAL</div>
  </div>
  <div *ngIf="cart.products.length > 0">
    <pc-cart-item *ngFor="let item of cart.products" [cartProduct]="item" [updateDisabled]="updateDisabled"
    (cartUpdated)="onCartUpdated($event)"></pc-cart-item>
  </div>
  <div *ngIf="cart.products.length === 0">
    <p>Your cart is empty</p>
  </div>
  <div class="row cart-footer">
    <div class="col-sm-2"></div>
    <div class="col-sm-4"></div>
    <div class="col-sm-2"></div>
    <div class="col-sm-2"><label>Total:</label></div>
    <div class="col-sm-2">
      <label>{{ getTotal() }}</label>
    </div>
  </div>
</ng-container>
<ng-container *ngIf="loading">
  <div class="row">
    <div class="col-sm-12">
      <content-loader primaryColor="#e0e0e0" secondaryColor="#a0a0a0" height="30">
        <svg:rect x="0" y="0" width="100%" height="20" />
      </content-loader>
    </div>
  </div>
  <div class="row">
    <div class="col-sm-12">
      <content-loader primaryColor="#e0e0e0" secondaryColor="#a0a0a0" height="30">
        <svg:rect x="0" y="0" width="100%" height="20" />
      </content-loader>
    </div>
  </div>
  <div class="row">
    <div class="col-md-3 col-sm-12">
      <content-loader primaryColor="#e0e0e0" secondaryColor="#a0a0a0" height="220">
        <svg:rect x="0" y="0" width="100%" height="200" />
      </content-loader>
    </div>
    <div class="col-md-3 col-sm-12">
      <content-loader primaryColor="#e0e0e0" secondaryColor="#a0a0a0" height="200">
        <svg:rect x="0" y="0" width="100%" height="20" />
        <svg:rect x="0" y="40" width="70%" height="20" />
        <svg:rect x="0" y="80" width="80%" height="20" />
        <svg:rect x="0" y="120" width="50%" height="20" />
      </content-loader>
    </div>
    <div class="col-md-3 col-sm-12">
      <content-loader primaryColor="#e0e0e0" secondaryColor="#a0a0a0" height="200">
        <svg:rect x="0" y="0" width="100%" height="20" />
      </content-loader>
    </div>
    <div class="col-md-3 col-sm-12">
      <content-loader primaryColor="#e0e0e0" secondaryColor="#a0a0a0" height="200">
        <svg:rect x="0" y="0" width="100%" height="20" />
      </content-loader>
    </div>
  </div>
  <div class="row">
    <div class="col-md-3 col-sm-12">
      <content-loader primaryColor="#e0e0e0" secondaryColor="#a0a0a0" height="220">
        <svg:rect x="0" y="0" width="100%" height="200" />
      </content-loader>
    </div>
    <div class="col-md-3 col-sm-12">
      <content-loader primaryColor="#e0e0e0" secondaryColor="#a0a0a0" height="200">
        <svg:rect x="0" y="0" width="100%" height="20" />
        <svg:rect x="0" y="40" width="70%" height="20" />
        <svg:rect x="0" y="80" width="80%" height="20" />
        <svg:rect x="0" y="120" width="50%" height="20" />
      </content-loader>
    </div>
    <div class="col-md-3 col-sm-12">
      <content-loader primaryColor="#e0e0e0" secondaryColor="#a0a0a0" height="200">
        <svg:rect x="0" y="0" width="100%" height="20" />
      </content-loader>
    </div>
    <div class="col-md-3 col-sm-12">
      <content-loader primaryColor="#e0e0e0" secondaryColor="#a0a0a0" height="200">
        <svg:rect x="0" y="0" width="100%" height="20" />
      </content-loader>
    </div>
  </div>
</ng-container>