import { Injectable } from '@angular/core';
import { ApiRestService } from '@motivforce/mx-library-angular';
import { BehaviorSubject } from 'rxjs';
import { TransferPoints } from 'src/app/account-manager/models/transferPoints';
import { Transfers } from 'src/app/account-manager/models/transfers';
import { BaseCoreService } from 'src/app/core/services/base-core-service';
import { CoreApiService } from 'src/app/core/services/core-api.service';
import { VetPractice, VetPracticeDetailed } from 'src/app/shared/models/vet-practice';
import { CatFormData } from '../models/cat-form-data';
import { MemberSearchParams } from '../models/member-search-params';
import { ProductPurchaseFormData } from '../models/product-purchase-form-data';
import { TestResultFormData } from '../models/test-result-form-data';
import * as fileSaver from 'file-saver';
import { map } from 'rxjs/operators';

@Injectable({
  providedIn: 'root',
})
export class MemberManagerService extends BaseCoreService {
  private selectedMember = new BehaviorSubject<VetPracticeDetailed>({
    account: null,
    pointsSummary: null,
    memberManagerMessage: null,
  });
  private memberResults = new BehaviorSubject<VetPractice[]>([]);
  private transferResults = new BehaviorSubject<Transfers[]>([]);

  selectedMember$ = this.selectedMember.asObservable();
  memberResults$ = this.memberResults.asObservable();
  transfers$ = this.transferResults.asObservable();

  constructor(private coreApiService: CoreApiService, protected apiRestService: ApiRestService) {
    super('norbrook/membermanager', apiRestService);
  }

  updateSelectedMember(member: VetPracticeDetailed): void {
    this.selectedMember.next(member);
  }

  updateMemberResults(results): void {
    this.memberResults.next(results);
  }

  resetSelectedMember() {
    this.selectedMember.next({
      account: null,
      pointsSummary: null,
      memberManagerMessage: null,
    });
    this.memberResults.next([]);
  }

  submitMemberSearch(params: MemberSearchParams): Promise<any> {
    return this.coreApiService.post(`${this.memberBaseUrl}/search`, params).toPromise();
  }

  getMemberDetails(memberId: number): Promise<any> {
    return this.coreApiService.get(`${this.memberBaseUrl}/member/${memberId}`).toPromise();
  }

  getMemberDetailsForVetPractice(): Promise<any> {
    return this.coreApiService.get(`${this.memberBaseUrl}/member`).toPromise();
  }

  getTransfersMade(search: any): Promise<any> {
    return this.coreApiService.post(`${this.memberBaseUrl}/transferred`, search).toPromise();
  }

  downloadTransfersMade(search: any): void {
    this.coreApiService
      .download(`${this.memberBaseUrl}/transferred/download?fromDate=${search.fromDate || ''}&toDate=${search.toDate || ''}&search=${search.search || ''}`)
      .pipe(
        map((response) => {
          return {
            content: response.body,
            fileName: response.headers.get('Filename'),
            contentType: response.headers.get('Content-Type'),
          };
        }),
      )
      .subscribe((result) => {
        if (result.content && result.contentType) {
          const blob: any = new Blob([result.content], { type: result.contentType });
          fileSaver.saveAs(blob, result.fileName);
        }
      });
  }

  // selectMemberById(memberId: number, addPurchaseMessage?: string, addTestMessage?: string): void {
  //   let results = this.memberResults.getValue();

  //   if (results.length > 0) {
  //     results = results.filter((result) => {
  //       return result.memberId === memberId;
  //     });
  //   }

  //   if (results.length === 1) {
  //     this.getMemberDetails(memberId)
  //       .then((memberDetails) => {
  //         memberDetails.account = results[0];
  //         memberDetails.addPurchaseMessage = addPurchaseMessage;
  //         memberDetails.addTestMessage = addTestMessage;
  //         memberDetails.memberManagerMessage =
  //           !addPurchaseMessage && addPurchaseMessage !== '' ? addPurchaseMessage : addTestMessage;
  //         this.updateSelectedMember(memberDetails);
  //       })
  //       .catch((error) => {
  //         console.log('Error retrieving member details:', error);
  //         this.resetSelectedMember();
  //       });
  //   }
  // }

  addCat(catFormData: CatFormData): Promise<any> {
    return this.coreApiService.post(`${this.memberBaseUrl}/cat`, catFormData).toPromise();
  }

  updateCat(catFormData: CatFormData): Promise<any> {
    return this.coreApiService.put(`${this.memberBaseUrl}/cat`, catFormData).toPromise();
  }

  addPurchase(purchaseFormData: ProductPurchaseFormData): Promise<any> {
    return this.coreApiService.post(`${this.memberBaseUrl}/purchase`, purchaseFormData).toPromise();
  }

  addT4TestResult(testResultFormData: TestResultFormData): Promise<any> {
    return this.coreApiService
      .post(`${this.memberBaseUrl}/t4testresult`, testResultFormData)
      .toPromise();
  }

  transferPoints(transferPointsForm: TransferPoints): Promise<any> {
    return this.coreApiService
      .post(`${this.memberBaseUrl}/transfer`, transferPointsForm)
      .toPromise();
  }
}
