import { Injectable } from '@angular/core';
import Auth, { CognitoHostedUIIdentityProvider } from '@aws-amplify/auth';
import { Hub, ICredentials } from '@aws-amplify/core';
import { Subject, Observable, BehaviorSubject } from 'rxjs';
import { CognitoUser } from 'amazon-cognito-identity-js';
import base64url from 'base64url';
import * as ls from 'local-storage';
import { Router } from '@angular/router';
import { SettingsProvider } from '../settings.provider';

@Injectable({
  providedIn: 'root',
})
export class AuthenticationService {

  constructor(
    private settingsProvider: SettingsProvider,
    private router: Router,
  ) {
    Hub.listen('auth', ({ payload: { event, data } }) => {
      console.log('HUB', event);
      if (event === 'signIn') {
        this.settingsProvider.init().then((settings): any => {
          if (this.impersonateUserId) {
            return this.setImpersonateUser(this.impersonateUserId).then(() => {
              console.log('Refreshing user settings');
              this.settingsProvider.refreshUserSettings().then(() => {
                console.log('LOGIN REDIRECT PAGE: Redirect Home');
                this.authComplete.next(true);
              });
            });
          }

          return this.authComplete.next(true);
        });
      }
      if (event === 'customOAuthState') {
        console.log('Custom State called in Auth Service');
        try {
          const decodedJsonString = base64url.decode(data);
          const state = JSON.parse(decodedJsonString);

          console.log(state);

          if (state.impersonateUserId) {
            this.impersonateUserId = state.impersonateUserId;
          }
          // we are trying to impersonate a user
        } catch (err) {
          console.error(err);
        }
      }
    });
  }
  public static SIGN_IN = 'signIn';
  public static SIGN_OUT = 'signOut';
  public static FACEBOOK = CognitoHostedUIIdentityProvider.Facebook;
  public static GOOGLE = CognitoHostedUIIdentityProvider.Google;
  impersonateUserId: number;
  public loggedIn: boolean;
  // tslint:disable-next-line: variable-name
  private _authState: Subject<CognitoUser | any> = new Subject<CognitoUser | any>();
  authState: Observable<CognitoUser | any> = this._authState.asObservable();
  authComplete: BehaviorSubject<boolean> = new BehaviorSubject<boolean>(false);

  async currentUserInfo(): Promise<any> {
    return Auth.currentAuthenticatedUser();
  }

  async signUp(suppliedEmail: string, suppliedPassword: string): Promise<any> {

    try {
      return await Auth.signUp({
        username: suppliedEmail,
        password: suppliedPassword,
        attributes: {
          email: suppliedEmail
        }
      });
    } catch (err) {
      console.log('Error during registration: ', err);
      return null;
    }
  }

  signIn(username: string, password: string): Promise<CognitoUser | any> {
    return new Promise((resolve, reject) => {
      Auth.signIn(username, password)
        .then((user: CognitoUser | any) => {
          this.loggedIn = true;
          resolve(user);
        })
        .catch((error: any) => reject(error));
    });
  }

  signOut(): Promise<any> {
    this.stopImpersonation();
    return Auth.signOut().then(() => (this.loggedIn = false));
  }

  federatedSignIn(): Promise<ICredentials> {
    console.log('Federated Login');
    return Auth.federatedSignIn({
      provider: CognitoHostedUIIdentityProvider.Cognito,
    });
  }

  async setImpersonateUser(userId: number) {
    // console.log('IMPERSONATE: Setting user to impersonate', userId);
    // console.log('IMPERSONATE: Getting settings to ensure the user can impersonate');
    // const settings = await this.settingsProvider.getRemoteUserSettings();
    // const claims = settings.claims;
    // console.log('IMPERSONATE: Checking claims', claims);
    // if (claims) {
    //   const hasClaim = claims.find((c) => c === 'user.impersonate');

    //   if (hasClaim) {
    //     console.log('IMPERSONATE: Setting Impersonate User', userId);
        ls.set<number>('m9-impersonate-user', userId);
        return;
    //   }
    // }

    throw new Error('Not authorised to impersonate user');
  }

  getImpersonatedUserId() {
    return ls.get<number>('m9-impersonate-user');
  }

  stopImpersonation() {
    return ls.remove('m9-impersonate-user');
  }

  setLoggedIn() {
    this.loggedIn = true;
  }

  checkLoggedIn() {
    return this.loggedIn;
  }

  async checkCurrentUserStatus(): Promise<boolean> {
    try {
      await Auth.currentAuthenticatedUser();
      return true;
    } catch (e) {
      return false;
    }
  }
}
