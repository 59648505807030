import { Component, OnInit, OnDestroy } from '@angular/core';
import { Subscription } from 'rxjs';
import { FormControl, FormGroup, Validators } from '@angular/forms';
import { MemberSearchParams } from 'src/app/vet/models/member-search-params';
import { MemberManagerService } from 'src/app/vet/services/member-manager.service';

@Component({
  selector: 'vet-member-search-form',
  templateUrl: './member-search-form.component.html',
  styleUrls: ['./member-search-form.component.scss'],
})
export class MemberSearchFormComponent implements OnInit, OnDestroy {
  submitted = false;
  submitModel: MemberSearchParams = {
    email: '',
    firstName: '',
    lastName: '',
    memberNumber: '',
    phone: '',
    postcode: '',
    vetPracticeName: '',
  } as MemberSearchParams;
  searchParamsForm: FormGroup;
  formInvalidAfterSubmit: boolean;
  errorMessages: string;
  private resultsSubscription: Subscription;
  private selectedMemberSub: Subscription;
  hasMultipleResults = false;

  constructor(private managerService: MemberManagerService) {}

  ngOnInit(): void {
    this.searchParamsForm = new FormGroup({
      email: new FormControl('', [Validators.email]),
      firstName: new FormControl(''),
      lastName: new FormControl(''),
      memberNumber: new FormControl(''),
      phone: new FormControl(''),
    });
    this.resultsSubscription = this.managerService.memberResults$.subscribe((results) => {
      this.hasMultipleResults = results.length > 1;
    });
    this.selectedMemberSub = this.managerService.selectedMember$.subscribe((member) => {
      if (member.account !== null) {
        this.searchParamsForm.controls.email.patchValue(member.account.email);
        this.searchParamsForm.controls.firstName.patchValue(member.account.firstName);
        this.searchParamsForm.controls.lastName.patchValue(member.account.lastName);
        this.searchParamsForm.controls.phone.patchValue(member.account.phone);
        this.searchParamsForm.controls.memberNumber.patchValue(member.account);
      } else {
        this.searchParamsForm.controls.email.patchValue('');
        this.searchParamsForm.controls.firstName.patchValue('');
        this.searchParamsForm.controls.lastName.patchValue('');
        this.searchParamsForm.controls.phone.patchValue('');
        this.searchParamsForm.controls.memberNumber.patchValue('');
      }
    });
  }

  ngOnDestroy(): void {
    if (this.resultsSubscription) {
      this.resultsSubscription.unsubscribe();
    }
    if (this.selectedMemberSub) {
      this.selectedMemberSub.unsubscribe();
    }
  }

  get email() {
    return this.searchParamsForm.get('email');
  }

  onSubmit() {
    this.submitted = true;
    this.errorMessages = '';
    let hasParamValue = false;

    if (this.searchParamsForm.valid) {
      const formData = this.searchParamsForm.getRawValue();

      if (
        (formData.firstName !== '' && formData.lastName === '') ||
        (formData.firstName === '' && formData.lastName !== '')
      ) {
        this.errorMessages = 'You must enter both first and last name to search by name.';
      } else {
        Object.keys(this.submitModel).forEach((key) => {
          if (formData[key] !== null && formData[key] !== '') {
            hasParamValue = true;
          }
          this.submitModel[key] = formData[key];
        });

        if (hasParamValue) {
          this.formInvalidAfterSubmit = false;

          this.managerService
            .submitMemberSearch(this.submitModel)
            .then((data) => {
              this.managerService.updateMemberResults(data.results);
              if (data.results.length === 1) {
                //this.managerService.selectMemberById(data.results[0].memberId);
              } else if (data.results.length === 0) {
                this.errorMessages =
                  'We cannot find a record of this client as being registered for the Norbrook Advantage Programme.';
              } else if (data.results.length > 1) {
                this.errorMessages =
                  'We have found more than one matching user, please add more search criteria to reduce the number of results.';
              }
            })
            .catch((error) => {
              console.log('Error occurred.', error);
            });
        } else {
          this.formInvalidAfterSubmit = true;
          this.errorMessages = 'You must enter at least one value to search.';
        }
      }
    }
  }

  resetSearch(): void {
    this.managerService.resetSelectedMember();
  }
}
