import { Component, OnInit, Input } from '@angular/core';
import { TranslateService } from '@ngx-translate/core';
import { ContentService } from 'src/app/core/content/content.service';
import { Content } from 'src/app/core/models/content.model';
import { DynamicLayout } from 'src/app/core/models/dynamic-layout.model';

@Component({
  selector: 'app-article-list-page',
  templateUrl: './article-list-page.component.html',
  styleUrls: ['./article-list-page.component.scss'],
})
export class ArticleListPageComponent implements OnInit, DynamicLayout {
  @Input() baseContent: Content;

  articleListContent: Content;
  articles: any[];
  banner: string;
  title: string;
  body: string;

  constructor(
    private contentService: ContentService,
    private translateService: TranslateService,
  ) { }

  ngOnInit() {
    const titleField = this.baseContent.fields.find(f => f.name === 'Title');
    const bannerField = this.baseContent.fields.find(f => f.name === 'Banner');

    this.title = titleField.value.value;
    this.banner = bannerField.value.url;
    this.contentService
      .getContentById(this.baseContent.id, this.translateService.currentLang, true)
      .subscribe((response) => {
        console.log(response);
        this.articleListContent = response.data.contentById;
        console.log(this.articleListContent);

        this.articles = this.articleListContent.children.map((c) => {
          const thumb = c.fields.find((f) => f.name === 'Article Thumbnail');
          const title = c.fields.find((f) => f.name === 'Title');
          const overview = c.fields.find((f) => f.name === 'Overview Text');
          return {
            imageUrl: thumb ? thumb.value.url : undefined,
            title: title ? title.value.value : undefined,
            overview: overview ? overview.value.value : undefined,
            url: c.slug,
          };
        });

        const bannerField = this.articleListContent.fields.find((f) => f.name === 'Banner');
        const headingField = this.articleListContent.fields.find((f) => f.name === 'Title');
        const bodyField = this.articleListContent.fields.find((f) => f.name === 'Body');
        this.banner = bannerField.value.url;
        this.title = headingField.value.value;
        this.body = bodyField.value.value;
      });
  }
}
