import { Injectable } from '@angular/core';
import { Observable } from 'rxjs/Observable';
import { finalize } from 'rxjs/operators';
import { NgxSpinnerService } from 'ngx-bootstrap-spinner';
import { ApiRestService } from '@motivforce/mx-library-angular';

@Injectable({
  providedIn: 'root'
})
export class CoreApiService {

  constructor(protected apiRestService: ApiRestService, private spinner: NgxSpinnerService) { }

  get(endpoint: string): Observable<any> {
    this.spinner.show();
    return this.apiRestService.get(endpoint).pipe(finalize(() => {
      this.spinner.hide();
    }));
  }

  post(endpoint: string, data: any): Observable<any> {
    this.spinner.show();
    return this.apiRestService.post(endpoint, data).pipe(finalize(() => {
      this.spinner.hide();
    }));
  }

  put(endpoint: string, data: any): Observable<any> {
    this.spinner.show();
    return this.apiRestService.put(endpoint, data).pipe(finalize(() => {
      this.spinner.hide();
    }));
  }

  delete(endpoint: string): Observable<any> {
    this.spinner.show();
    return this.apiRestService.delete(endpoint).pipe(finalize(() => {
      this.spinner.hide();
    }));
  }

  download(endpoint: string): Observable<any> {
    this.spinner.show();
    return this.apiRestService.download(endpoint).pipe(finalize(() => {
      this.spinner.hide();
    }));
  }

  getWithPromise(endpoint: string): Promise<any> {
    return this.get(endpoint).toPromise();
  }

  postWithPromise(endpoint: string, data: any): Promise<any> {
    return this.apiRestService.post(endpoint, data).toPromise();
  }

  putWithPromise(endpoint: string, data: any): Promise<any> {
    return this.apiRestService.put(endpoint, data).toPromise();
  }

  deleteWithPromise(endpoint: string): Promise<any> {
    return this.apiRestService.delete(endpoint).toPromise();
  }
}
