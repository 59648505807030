import { Component, OnInit, OnDestroy, Input } from '@angular/core';
import { Subscription } from 'rxjs';
import { PointsSummary } from 'src/app/shared/models/points-summary';
import { MemberManagerService } from 'src/app/vet/services/member-manager.service';

@Component({
  selector: 'vet-member-points-balance',
  templateUrl: './member-points-balance.component.html',
  styleUrls: ['./member-points-balance.component.scss']
})
export class MemberPointsBalanceComponent implements OnInit, OnDestroy {
  // tslint:disable-next-line: variable-name
  private _pointsSummary: PointsSummary;

  get pointsSummary() {
    return this._pointsSummary;
  }

  @Input()
  set pointsSummary(x: PointsSummary) {
    this._pointsSummary = x;
  }
  private selectedMemberSub: Subscription;
  pointSummary: PointsSummary = { earned: 0, available: 0, redeemed: 0 } as PointsSummary;
  renderSummary = false;

  constructor(private managerService: MemberManagerService) { }

  ngOnInit(): void {
    this.selectedMemberSub = this.managerService.selectedMember$.subscribe(member => {
      if (member.pointsSummary !== null) {
        this.pointSummary = member.pointsSummary;
        this.renderSummary = true;
      } else {
        this.renderSummary = false;
      }
    });
  }

  ngOnDestroy(): void {
    if (this.selectedMemberSub) {
      this.selectedMemberSub.unsubscribe();
    }
  }

}
