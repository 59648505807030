import { Apollo } from 'apollo-angular';
import gql from 'graphql-tag';
import { Injectable } from '@angular/core';
import { BehaviorSubject } from 'rxjs';
import { ContentItem } from '../models/content-item';

const ALL_FIELDS = `
  id,
  name,
  slug,
  type,
  sortIndex,
  published,
  publishDate,
  unpublishDate,
  languageId,
  siteId,
  renderComponent,
  children,
  properties,
  fields {
    id,
    name,
    type,
    properties,
    sortIndex,
    advancedValue,
    value,
  }
`;

@Injectable({
  providedIn: 'root',
})
export class ContentService {
  public menu: BehaviorSubject<any> = new BehaviorSubject<any>({ items: [] });
  public footerMenu: BehaviorSubject<any> = new BehaviorSubject<any>([]);

  constructor(private apollo: Apollo) { }

  public getContentByUrlPath(
    url: string,
    languageId: string,
    includeChildren: boolean = false,
    select?: string,
  ) {
    // if a select list is included use it else return all fields
    const fields = select ? select : ALL_FIELDS;
    // create the graphql query
    const query = gql`
      query contentByUrlPath($urlPath: String!, $languageId: String!, $includeChildren: Boolean) {
        contentByUrlPath(urlPath: $urlPath, languageId: $languageId, includeChildren: $includeChildren) {
          ${fields}
        }
      }
    `;
    return this.apollo.query<any>({
      query,
      variables: {
        urlPath: url,
        languageId,
        includeChildren,
      },
    });
  }

  public getContentById(
    id: number,
    languageId: string,
    includeChildren: boolean = false,
    select?: string,
  ) {
    console.log('GetContentById', id, languageId, includeChildren);
    const fields = select ? select : ALL_FIELDS;

    // create the graphql query
    const query = gql`
      query contentById($id: Float!, $languageId: String!, $includeChildren: Boolean) {
        contentById(id: $id, languageId: $languageId, includeChildren: $includeChildren) {
          ${fields}
        }
      }
    `;
    console.log(includeChildren);
    return this.apollo.query<any>({
      query,
      variables: {
        id,
        languageId,
        includeChildren,
      },
    });
  }

  private transformMenu(menu: ContentItem) {
    return this.transformMenuItems(menu.children);
  }
  private transformMenuItems(menuItems: ContentItem[]) {
    return menuItems.map((mi) => {
      const labelField = mi.fields.find((f) => f.name === 'Text');
      const label = labelField ? labelField.value.value : '(no label)';

      return {
        path: mi.properties.url.value,
        label,
        items: this.transformMenuItems(mi.children),
      };
    });
  }

  public async initMenu(language: string) {
    const siteUrl = new URL(window.location.href);
    const menuPath = `${siteUrl.origin}/menu-top-menu`;

    const footerMenuPath = `${siteUrl.origin}/menu-footer-menu`;
  }
}
