<div class="modal-header ml-3">
  <h4 class="modal-title" id="modal-basic-title">TRANSFER POINTS</h4>
</div>
<div class="modal-body ml-3 mr-3 mt-1 mb-1">
  <form [formGroup]="transferForm" (ngSubmit)="onSubmit()">
    <div class="row">
      <div class="col-sm-12">
        <p>&nbsp;</p>

        <div class="row">
          <div class="col-lg-12 col-md-12 col-sm-12 col-xs-12">
            <label>NUMBER OF POINTS TO BE AWARDED</label>
          </div>
          <div class="col-lg-12 col-md-12 col-sm-12 col-xs-12">
            <input
              name="pointsToAward"
              formControlName="pointsToAward"
              class="form-control text-input"
              type="text"
              placeholder="Number of points to award"
            />
          </div>
        </div>
      </div>
    </div>
    <div class="row">
      <div class="col-sm-12 pt-5">
        <p>&nbsp;</p>

        <div class="row">
          <div class="col-lg-12 col-md-12 col-sm-12 col-xs-12">
            <label>REASON FOR AWARDING</label>
          </div>
          <div class="col-lg-12 col-md-12 col-sm-12 col-xs-12">
            <select
              formControlName="reasonForAwarding"
              name="reasonForAwarding"
              class="form-control text-input"
            >
              <optgroup label="FELINE HYPERTHYROIDISM AWARENESS">
                <option value="Hyperthyroidism practice audit">
                  Hyperthyroidism practice audit
                </option>
                <option value="Hyperthyroidism and Thyronorm lunch and learn">
                  Hyperthyroidism and Thyronorm lunch and learn
                </option>
                <option value="Beyond TT4 lunch and learn">Beyond TT4 lunch and learn</option>
                <option value="Waiting room display">Waiting room display</option>
                <option value="Social media campaign">Social media campaign</option>
                <option value="Social media hyperthyroidism survey">
                  Social media hyperthyroidism survey
                </option>
                <option value="Social media senior cat survey">
                  Social media senior cat survey
                </option>
                <option value="Senior cat client evening">Senior cat client evening</option>
                <option value="Hyperthyroidism awareness bonus activities">
                  Hyperthyroidism awareness bonus activities
                </option>
                <option value="Hyperthyroidism awareness month (promotions page)">
                  Hyperthyroidism awareness month (promotions page)
                </option>
              </optgroup>
              <optgroup label="​​ARTHRITIS AWARENESS">
                <option value="CAM Lunch and Learn">CAM Lunch and Learn</option>
                <option value="OA Nurse Clinics: Gait analysis and lameness scoring lunch and earn">
                  OA Nurse Clinics: Gait analysis and lameness scoring lunch and earn
                </option>
                <option value="Loxicom lunch and learn">Loxicom lunch and learn</option>
                <option value="Identifying pain in cats lunch and learn">
                  Identifying pain in cats lunch and learn
                </option>
                <option value="Postoperative pain relief lunch and learn">
                  Postoperative pain relief lunch and learn
                </option>
                <option value="Arthritis Awareness social media activities">
                  Arthritis Awareness social media activities
                </option>
                <option value="Arthritis social media survey">Arthritis social media survey</option>
                <option value="Senior dog client evening / coffee morning">
                  Senior dog client evening / coffee morning
                </option>
              </optgroup>
              <optgroup label="​​DERMATOLOGY">
                <option value="Moxiclear lunch and learn">Moxiclear lunch and learn</option>
                <option value="Betafuse lunch and learn">Betafuse lunch and learn</option>
                <option value="Pyoderma client evening talk">Pyoderma client evening talk</option>
                <option value="Pyoderma waiting room activities">
                  Pyoderma waiting room activities
                </option>
              </optgroup>
              <optgroup label="NURSE ACADEMY">
                <option value="Mastering Nurse clinics workshop">
                  Mastering Nurse clinics workshop
                </option>
                <option value="Super seniors workshop">Super seniors workshop</option>
              </optgroup>
            </select>
          </div>
        </div>
      </div>
    </div>
    <div *ngIf="!confirmationAmountChecked" class="col-sm-12 btn-col pt-5">
      <p>&nbsp;</p>
      <div class="form-group">
        <button class="btn btn-info" type="button" (click)="activeModal.close('Save click')">
          CLOSE
        </button>
        <button class="btn btn-primary" type="button" (click)="calculateValue()">SUBMIT</button
        ><img src="../../../../assets/images/tick.png" />
      </div>
    </div>
    <div *ngIf="confirmationAmountChecked">
      <div class="row">
        <div class="col-sm-12">
          <p>&nbsp;</p>

          <h5>YOU WILL BE AWARDING £{{ valueToAward | number: '1.2-2' }}</h5>
          <h5>DO YOU WISH TO PROCEED?</h5>
          <p>&nbsp;</p>
          <div class="form-group">
            <button class="btn btn-info" type="button" (click)="activeModal.close('Save click')">
              CANCEL
            </button>
            <button class="btn btn-primary" type="button" (click)="onSubmit()">YES</button
            ><img src="../../../../assets/images/tick.png" />
          </div>
        </div>
      </div>
    </div>
  </form>
</div>
