import { Component, Input, OnInit } from '@angular/core';
import { Content } from 'src/app/core/models/content.model';
import { DynamicLayout } from 'src/app/core/models/dynamic-layout.model';

@Component({
  selector: 'app-catalogue-page',
  templateUrl: './catalogue-page.component.html',
  styleUrls: ['./catalogue-page.component.scss']
})
export class CataloguePageComponent implements OnInit, DynamicLayout {
  @Input() baseContent: Content;

  title: string;
  banner: string;

  constructor() { }

  ngOnInit(): void {
    const titleField = this.baseContent.fields.find(f => f.name === 'Title');
    const bannerField = this.baseContent.fields.find(f => f.name === 'Banner');
    this.title = titleField.value.value;
    this.banner = bannerField.value.url;
  }

}
