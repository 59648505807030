<pc-product-filter></pc-product-filter>

<div class="row" infiniteScroll [infiniteScrollDistance]="1" [infiniteScrollUpDistance]="2"
  [infiniteScrollDisabled]="scrollDisabled || loading" (scrolled)="onScrollDown($event)" (scrolledUp)="onUp($event)">
  <div class="col-lg-3 col-md-6 col-sm-12 product-item-container" *ngFor="let product of products">
    <div class="card">
      <img *ngIf="!product.images || product.images.length == 0" class="card-img-top"
        src="https://via.placeholder.com/150.jpg" />
      <img *ngIf="product.images && product.images.length > 0" class="card-img-top"
        [src]="product.images[0].thumbnailBlobStorageUrl" />
      <div class="card-body">
        <h5 
        class="card-title" 
        *ngIf="product.programProducts[0] && product.programProducts[0].currency && product.isVoucher">
          {{product.programProducts[0].currency.symbol}}{{product.programProducts[0].cost}} {{ product.name }}
        </h5>
        <h5 class="card-title" *ngIf="!product.isVoucher">{{ product.name }}</h5>
        <p class="card-text">
          Points: {{ product.points }}<br />
          Product Code: {{ product.sku }}
        </p>
        <a routerLink="/vet/rewards/product/{{product.id}}" class="view-product-link"><i
            class="far fa-arrow-right mr-5"></i>View Product</a>
      </div>
    </div>
  </div>
</div>

<div class="row" *ngIf="loading">
  <div class="col-lg-3 col-md-6 col-sm-12 product-item-container">
    <content-loader primaryColor="#e0e0e0" secondaryColor="#a0a0a0" height="400">
      <svg:rect x="0" y="0" width="100%" height="200" />
      <svg:rect x="0" y="220" width="70%" height="20" />
      <svg:rect x="0" y="260" width="80%" height="20" />
      <svg:rect x="0" y="300" width="50%" height="20" />
      <svg:rect x="0" y="340" width="100%" height="20" />
    </content-loader>
  </div>
  <div class="col-lg-3 col-md-6 col-sm-12 product-item-container">
    <content-loader primaryColor="#e0e0e0" secondaryColor="#a0a0a0" height="400">
      <svg:rect x="0" y="0" width="100%" height="200" />
      <svg:rect x="0" y="220" width="70%" height="20" />
      <svg:rect x="0" y="260" width="80%" height="20" />
      <svg:rect x="0" y="300" width="50%" height="20" />
      <svg:rect x="0" y="340" width="100%" height="20" />
    </content-loader>
  </div>
  <div class="col-lg-3 col-md-6 col-sm-12 product-item-container">
    <content-loader primaryColor="#e0e0e0" secondaryColor="#a0a0a0" height="400">
      <svg:rect x="0" y="0" width="100%" height="200" />
      <svg:rect x="0" y="220" width="70%" height="20" />
      <svg:rect x="0" y="260" width="80%" height="20" />
      <svg:rect x="0" y="300" width="50%" height="20" />
      <svg:rect x="0" y="340" width="100%" height="20" />
    </content-loader>
  </div>
  <div class="col-lg-3 col-md-6 col-sm-12 product-item-container">
    <content-loader primaryColor="#e0e0e0" secondaryColor="#a0a0a0" height="400">
      <svg:rect x="0" y="0" width="100%" height="200" />
      <svg:rect x="0" y="220" width="70%" height="20" />
      <svg:rect x="0" y="260" width="80%" height="20" />
      <svg:rect x="0" y="300" width="50%" height="20" />
      <svg:rect x="0" y="340" width="100%" height="20" />
    </content-loader>
  </div>
</div>