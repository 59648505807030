import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { TranslateModule } from '@ngx-translate/core';
import { NgbModule } from '@ng-bootstrap/ng-bootstrap';
import { MatchValueDirective } from './directives/match-value.directive';
import { FooterComponent } from './components/footer/footer.component';
import { HeaderComponent } from './components/header/header.component';
import { LowerNavComponent } from './components/lower-nav/lower-nav.component';
import { ErrorModule } from './components/error/error.module';
import { FaqPageComponent } from './components/faq/faq-page.component';
import { LogoutComponent } from './components/logout/logout.component';
import { ResetPasswordComponent } from './components/reset-password/reset-password.component';
import { TestResultsComponent } from './components/test-results/test-results.component';
import { LoginComponent } from './components/login/login.component';
import { AmplifyAngularModule } from 'aws-amplify-angular';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { RouterModule } from '@angular/router';
import { ContentLoaderModule } from '@netbasal/ngx-content-loader';

@NgModule({
  declarations: [
    FooterComponent,
    HeaderComponent,
    LowerNavComponent,
    MatchValueDirective,
    FaqPageComponent,
    LoginComponent,
    LogoutComponent,
    ResetPasswordComponent,
    TestResultsComponent,
  ],
  imports: [
    CommonModule,
    AmplifyAngularModule,
    FormsModule,
    ReactiveFormsModule,
    RouterModule,
    ContentLoaderModule,
    NgbModule,
    ErrorModule],
  exports: [
    TranslateModule,
    FooterComponent,
    HeaderComponent,
    LowerNavComponent,
    TestResultsComponent,
    MatchValueDirective,
  ],
})
export class SharedModule { }
