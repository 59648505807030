import { getLocaleDateTimeFormat } from '@angular/common';
import { Component, Input, OnDestroy, OnInit } from '@angular/core';
import { FormControl, FormGroup } from '@angular/forms';
import { Content } from 'src/app/core/models/content.model';
import { DynamicLayout } from 'src/app/core/models/dynamic-layout.model';
import { MemberManagerService } from '../../services/member-manager.service';

@Component({
  selector: 'app-member-summary',
  templateUrl: './member-summary.component.html',
  styleUrls: ['./member-summary.component.scss'],
})
export class MemberSummaryComponent implements OnInit, DynamicLayout {
  @Input() baseContent: Content;

  title: string;
  banner: string;

  memberSummary: MemberSummary[];
  visibleMemberSummary: MemberSummary[];

  searchByKeywordForm: FormGroup;
  searchByT4TestForm: FormGroup;
  searchByPrescriptionForm: FormGroup;

  constructor(private memberManagerService: MemberManagerService) {}

  ngOnInit(): void {
    this.memberSummary = [];
    this.visibleMemberSummary = [];
    this.initialiseSearchTestForm();
    this.initialiseSearchPrescriptionForm();
    this.initialiseSearchByKeywordForm();
    const titleField = this.baseContent.fields.find((f) => f.name === 'Title');
    const bannerField = this.baseContent.fields.find((f) => f.name === 'Banner');
    this.title = titleField.value.value;
    this.banner = bannerField.value.url;

    this.memberManagerService.getMemberDetailsForVetPractice().then((memberData) => {
      for (let m = 0; m < memberData.length; m++) {
        const member = memberData[m];
        if (member.cats) {
          for (let c = 0; c < member.cats.length; c++) {
            const cat = member.cats[c];
            this.memberSummary.push({
              memberId: member.memberId,
              email: member.email,
              memberName: member.firstName + ' ' + member.lastName,
              phone: member.phoneNumber,
              catName: cat.name,
              t4TestDueDate: cat.t4TestDueDate,
              prescriptionDueDate: cat.prescriptionDueDate,
            });
          }
        }
      }

      this.visibleMemberSummary = [...this.memberSummary];
    });
  }

  initialiseSearchByKeywordForm(): void {
    this.searchByKeywordForm = new FormGroup({
      searchTerms: new FormControl(''),
    });
  }

  initialiseSearchTestForm(): void {
    this.searchByT4TestForm = new FormGroup({
      searchByT4TestFrom: new FormControl(''),
      searchByT4TestTo: new FormControl(''),
    });
  }

  initialiseSearchPrescriptionForm(): void {
    this.searchByPrescriptionForm = new FormGroup({
      searchByPrescriptionFrom: new FormControl(''),
      searchByPrescriptionTo: new FormControl(''),
    });
  }

  dateToday(): string {
    let today = new Date();
    const dd = today.getDate().toString().padStart(2, '0');
    const mm = (today.getMonth() + 1).toString().padStart(2, '0');
    const yyyy = today.getFullYear();

    return dd + '-' + mm + '-' + yyyy;
  }

  searchByT4Test() {
    let dateFrom = this.searchByT4TestForm.value.searchByT4TestFrom.toString();
    let dateTo = this.searchByT4TestForm.value.searchByT4TestTo.toString();
    if (dateTo === '') {
      dateTo = this.dateToday();
    }
    if (dateFrom !== '' && dateTo !== '') {
      this.visibleMemberSummary = [
        ...this.visibleMemberSummary.filter(
          (x) => x.t4TestDueDate >= dateFrom && x.t4TestDueDate <= dateTo,
        ),
      ];
    }
  }

  searchByPrescription() {
    let dateFrom = this.searchByPrescriptionForm.value.searchByPrescriptionFrom.toString();
    let dateTo = this.searchByPrescriptionForm.value.searchByPrescriptionTo.toString();
    if (dateTo === '') {
      dateTo = this.dateToday();
    }
    if (dateFrom !== '' && dateTo !== '') {
      this.visibleMemberSummary = [
        ...this.visibleMemberSummary.filter(
          (x) => x.prescriptionDueDate >= dateFrom && x.prescriptionDueDate <= dateTo,
        ),
      ];
    }
  }

  cleart4TestDates() {
    this.initialiseSearchTestForm();
    this.visibleMemberSummary = [...this.memberSummary];
    this.searchByPrescription();
  }

  clearPrescriptionDates() {
    this.initialiseSearchPrescriptionForm();
    this.visibleMemberSummary = [...this.memberSummary];
    this.searchByT4Test();
  }

  sortByTestDueDateAsc(): void {
    this.visibleMemberSummary = this.visibleMemberSummary.sort((a, b) => {
      if (a.t4TestDueDate && b.t4TestDueDate) {
        return a.t4TestDueDate.localeCompare(b.t4TestDueDate);
      } else if (a.t4TestDueDate && !b.t4TestDueDate) {
        return 1;
      } else if (!a.t4TestDueDate && b.t4TestDueDate) {
        return -1;
      }
    });
  }

  sortByTestDueDateDesc(): void {
    this.visibleMemberSummary = this.visibleMemberSummary.sort((a, b) => {
      if (a.t4TestDueDate && b.t4TestDueDate) {
        return b.t4TestDueDate.localeCompare(a.t4TestDueDate);
      } else if (a.t4TestDueDate && !b.t4TestDueDate) {
        return -1;
      } else if (!a.t4TestDueDate && b.t4TestDueDate) {
        return 1;
      }
    });
  }

  sortByPrescriptionDueDateAsc(): void {
    this.visibleMemberSummary = this.visibleMemberSummary.sort((a, b) => {
      if (a.prescriptionDueDate && b.prescriptionDueDate) {
        return a.prescriptionDueDate.localeCompare(b.prescriptionDueDate);
      } else if (a.prescriptionDueDate && !b.prescriptionDueDate) {
        return 1;
      } else if (!a.prescriptionDueDate && b.prescriptionDueDate) {
        return -1;
      }
    });
  }

  sortByPrescriptionDueDateDesc(): void {
    this.visibleMemberSummary = this.visibleMemberSummary.sort((a, b) => {
      if (a.prescriptionDueDate && b.prescriptionDueDate) {
        return b.prescriptionDueDate.localeCompare(a.prescriptionDueDate);
      } else if (a.prescriptionDueDate && !b.prescriptionDueDate) {
        return -1;
      } else if (!a.prescriptionDueDate && b.prescriptionDueDate) {
        return 1;
      }
    });
  }

  printMemberDetails(): void {
    window.print();
  }
}

interface MemberSummary {
  memberId: number | null;
  email: string | null;
  memberName: string | null;
  phone: string | null;
  catName: string;
  t4TestDueDate: string;
  prescriptionDueDate: string;
}
