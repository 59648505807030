<div class="login-page">
    <app-header heading-copy="# {{title}}" top-banner-url="{{banner}}"></app-header>
    <div class="container text-center">
        <form *ngIf="formGroup" [formGroup]="formGroup" (ngSubmit)="onSubmit()" onsubmit="return false"
            class="form-standard">
            <p>&nbsp;</p>
            <div [innerHtml]="body"></div>
            <div *ngIf="message !== ''" class="text-center">
                <p><i>{{message}}</i></p>
            </div>
            <div class="row">
                <div class="col-lg-12 col-md-12 col-sm-12 col-xs-12">
                    <div class="row" *ngIf="!verificationSent">
                        <div class="col-lg-2 col-md-2 hidden-sm hidden-xs"></div>
                        <div class="col-lg-2 col-md-2 col-sm-12 col-xs-12">
                            <label>EMAIL</label>
                        </div>
                        <div class="col-lg-4 col-md-4 col-sm-12 col-xs-12">
                            <input name="email" formControlName="email" class="form-control text-input" type="text"
                                placeholder="Enter your email address" />
                        </div>
                    </div>
                    <div class="row" *ngIf="verificationSent">
                        <div class="col-lg-2 col-md-2 hidden-sm hidden-xs"></div>
                        <div class="col-lg-2 col-md-2 col-sm-12 col-xs-12 pl-0">
                            <label>VERIFICATION CODE</label>
                        </div>
                        <div class="col-lg-4 col-md-4 col-sm-12 col-xs-12">
                            <input formControlName="verificationCode" class="form-control text-input"
                                placeholder="Enter your verification code" />
                        </div>
                    </div>

                    <div class="row" *ngIf="verificationSent">
                        <div class="col-lg-2 col-md-2 hidden-sm hidden-xs"></div>
                        <div class="col-lg-2 col-md-2 col-sm-12 col-xs-12 pl-0">
                            <label>NEW PASSWORD</label>
                        </div>
                        <div class="col-lg-4 col-md-4 col-sm-12 col-xs-12">
                            <input formControlName="newPassword" class="form-control text-input" type="password"
                                placeholder="Enter your new password" />
                        </div>
                    </div>

                    <div class="row" *ngIf="verificationSent">
                        <div class="col-lg-2 col-md-2 hidden-sm hidden-xs"></div>
                        <div class="col-lg-2 col-md-2 col-sm-12 col-xs-12 pl-0">
                            <label>CONFIRM PASSWORD</label>
                        </div>
                        <div class="col-lg-4 col-md-4 col-sm-12 col-xs-12">
                            <input formControlName="confirmedPassword" class="form-control text-input" type="password"
                                placeholder="Re-enter your new password" />
                        </div>
                    </div>

                    <div class="row">
                        <div class="col-lg-2 col-md-2 hidden-sm hidden-xs"></div>
                        <div class="col-lg-2 col-md-2 col-sm-6 col-xs-12 pl-0">
                            &nbsp;
                        </div>
                        <div class="col-lg-6 col-md-6 col-sm-12 col-xs-12 text-left">
                            <button class="btn btn-primary"
                                type="submit">{{ verificationSent ? 'RESET PASSWORD' : 'GET VERIFICATION CODE' }}</button>
                        </div>
                    </div>
                </div>
            </div>
        </form>

        <app-footer></app-footer>
    </div>
</div>