<div class="col-md-12">
    <h4>BLOOD TEST HISTORY</h4>
    <div class="cat-test-results" *ngFor="let cat of catDetails">
        <h5>{{cat.name}}'s RESULTS</h5>
        <table *ngIf="cat.testResults.length > 0" class="table">
            <thead>
                <tr>
                    <th class="width100">DATE</th>
                    <th>TOTAL T4 RESULT</th>
                </tr>
            </thead>
            <tbody>
                <tr *ngFor="let testResult of cat.testResults">
                    <td class="w-250">{{testResult.dateOfTest | date: 'dd LLLL yyyy' }}</td>
                    <td>{{testResult.testResult}}</td>
                </tr>
            </tbody>

        </table>
        <table *ngIf="cat.testResults.length == 0" class="table">
            <thead>
                <tr>
                    <th>No T4 Test results to show currently.</th>
                </tr>
            </thead>
        </table>

    </div>

</div>