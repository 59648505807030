import { Component, OnInit, OnDestroy } from '@angular/core';
import { Router } from '@angular/router';
import { FormBuilder, FormControl, FormGroup, Validators } from '@angular/forms';
import { Subscription } from 'rxjs';
import { SettingsProvider } from 'src/app/shared/settings.provider';
import { UserService } from '../../../../shared/services/user.service';
import { ProductCatalogueService } from '../../../services/product-catalogue.service';
import { Address } from '../../../../core/models/address';
import { Checkout } from '../../../models/redemptions/checkout';
import { PointsSummaryService } from '../../../../shared/services/points-summary.service';
import { PointsSummary } from '../../../../shared/models/points-summary';
import { Cart } from '../../../models/redemptions/cart';

@Component({
  selector: 'pc-checkout-details-form',
  templateUrl: './checkout-details-form.component.html',
  styleUrls: ['./checkout-details-form.component.scss']
})
export class CheckoutDetailsFormComponent implements OnInit, OnDestroy {
  checkoutDetailsForm: FormGroup;
  addressForm: FormGroup;
  addresses: any[];
  submitting = false;
  showForm = false;
  showInsufficientPoints = false;
  countryId: number;
  message: string;
  inufficientPointsMessage: string;

  private pointsSummarySub: Subscription;
  private pointsSummary: PointsSummary;
  private cartSub: Subscription;
  private cart: Cart;

  constructor(
    private fb: FormBuilder,
    private settingsProvider: SettingsProvider,
    private userService: UserService,
    private productCatalogueService: ProductCatalogueService,
    private router: Router,
    private pointsSummaryService: PointsSummaryService
  ) { }

  ngOnInit() {
    this.pointsSummarySub = this.pointsSummaryService.pointsSummary$
      .subscribe(pointsSummaryData => {
        if (pointsSummaryData != null) {
          console.log('Points in checkout:', pointsSummaryData);
          this.pointsSummary = pointsSummaryData;
          this.subscribeCart();
        } else {
          this.pointsSummary = { available: 0, earned: 0, redeemed: 0 } as PointsSummary;
        }
      });
  }

  ngOnDestroy() {
    if (this.pointsSummarySub) {
      this.pointsSummarySub.unsubscribe();
    }

    if (this.cartSub) {
      this.cartSub.unsubscribe();
    }
  }

  private subscribeCart() {
    this.cartSub = this.productCatalogueService.currentCart$
      .subscribe(currentCart => {
        if (currentCart != null) {
          this.cart = currentCart;
          if (this.cart.products.length > 0 && this.pointsSummary.available >= 0) {
            const cartPointTotal = this.getTotal();
            if (cartPointTotal > this.pointsSummary.available) {
              if (!this.showInsufficientPoints) {
                this.inufficientPointsMessage =  'You have an insufficient amount of points available to complete your order.';
              }
              this.showInsufficientPoints = true;
            } else {
              this.showInsufficientPoints = false;
              this.inufficientPointsMessage = null;
              this.initForm();
            }
          }
        }
      });
  }

  getTotal() {
    let total = 0;

    this.cart.products.forEach(cp => {
      total += cp.points * cp.quantity;
    });

    return total;
  }

  initForm() {
    const user = this.settingsProvider.settings.data.user;
    this.countryId = this.settingsProvider.settings.data.country.id;

    this.checkoutDetailsForm = new FormGroup({
      email: new FormControl(user.email),
      telephone: new FormControl('', [Validators.required]),
      firstName: new FormControl(user.firstName),
      lastName: new FormControl(user.lastName),
      address1: new FormControl('', [Validators.required]),
      address2: new FormControl(''),
      city: new FormControl('', [Validators.required]),
      county: new FormControl(''),
      postCode: new FormControl('', [Validators.required]),
    });

    this.showForm = true;
  }

  async onSubmit() {
    if (this.checkoutDetailsForm.valid) {
      const checkout = new Checkout();
      const address = new Address();
      address.address1 = this.checkoutDetailsForm.controls.address1.value;
      address.address2 = this.checkoutDetailsForm.controls.address2.value;
      address.city = this.checkoutDetailsForm.controls.city.value;
      address.county = this.checkoutDetailsForm.controls.county.value;
      address.postCode = this.checkoutDetailsForm.controls.postCode.value;
      address.mobileTelephone = this.checkoutDetailsForm.controls.telephone.value;
      address.countryId = this.countryId;
      checkout.shippingAddress = address;
      this.submitting = true;
      const c = await this.productCatalogueService.updateCheckout(checkout);

      this.productCatalogueService.completeOrder()
        .then(redemption => {
          this.productCatalogueService.updateCurrentRedemption(redemption);
          this.pointsSummaryService.refreshPointsSummary();
          this.router.navigateByUrl(`/vet/rewards/redemption-complete`);
        }).catch(error => {
          let showStandardMessage = true;

          if (error.message != null) {
            if (error.message.indexOf('TransactionsDoNotBalanceException') > -1) {
              this.message = 'Sorry, you do not have the required amount of points.';
              showStandardMessage = false;
            }
          } else {
            if (error != null && error === 'Insufficient points for redemption.') {
              this.message = error;
            }
          }

          if (showStandardMessage) {
            this.message = 'We apologise as there was a problem placing your order. Please try again.';
          }
        });
    } else {
      this.message = 'Please complete all required form fields.';
    }
  }
}
