import { Component, OnDestroy, OnInit } from '@angular/core';
import { Subscription } from 'rxjs';
import { Product } from 'src/app/vet/models/redemptions/product';
import { ProductCatalogueService } from 'src/app/vet/services/product-catalogue.service';

@Component({
  selector: 'pc-product-list',
  templateUrl: './product-list.component.html',
  styleUrls: ['./product-list.component.scss'],
})
export class ProductListComponent implements OnInit, OnDestroy {
  public loading = true;
  public products: Array<Product> = [];
  throttle = 300;
  scrollDistance = 1;
  scrollUpDistance = 2;
  scrollDisabled = false;
  private productListLoadingSub: Subscription;
  private productListingSub: Subscription;
  private searchSubscriptionsSub: Subscription;

  constructor(private productCatalogueService: ProductCatalogueService) {}

  ngOnInit() {
    this.productCatalogueService.refreshProductList();

    this.productListLoadingSub = this.productCatalogueService.productListLoading.subscribe(isLoading => {
      this.loading = isLoading;
    });

    this.productListingSub = this.productCatalogueService.productListing.subscribe(items => {
      this.products = items;
      this.products.forEach(product => {
        if (product.type === 'E-Voucher' || product.type === 'Gift Card' || product.type === 'Voucher') {
          product.isVoucher = true;
        }
      });
    });

    this.searchSubscriptionsSub = this.productCatalogueService.searchStatistics.subscribe(value => {
        this.scrollDisabled = value.currentPage && value.currentPage >= value.totalPages;
    });
  }

  ngOnDestroy(): void {
    if (this.productListLoadingSub) {
      this.productListLoadingSub.unsubscribe();
    }

    if (this.productListingSub) {
      this.productListingSub.unsubscribe();
    }

    if (this.searchSubscriptionsSub) {
      this.searchSubscriptionsSub.unsubscribe();
    }
  }

  onScrollDown(ev) {
    console.log('scrolled down!!', ev);

    const search = this.productCatalogueService.productSearchContext.getValue();
    search.pageNumber += 1;

    this.productCatalogueService.productSearchContext.next(search);
    this.productCatalogueService.getProducts();
  }

  onUp(ev) {
    console.log('scrolled up!', ev);
  }
}
