// This file can be replaced during build by using the `fileReplacements` array.
// `ng build --prod` replaces `environment.ts` with `environment.prod.ts`.
// The list of file replacements can be found in `angular.json`.

import { NgxLoggerLevel } from 'ngx-logger';

export const environment = {
  production: false,
  loggerLevel: NgxLoggerLevel.TRACE,
  api: {
    cms: 'https://devapi.motivforcem9.com/cms',
    core: {
      baseUrl: 'https://api-dev.motivforcem9.com/core/api',
      path: {
        member: 'members',
        norbrook: 'clients/norbrook',
      },
    },
  },
  amplify: {
    Auth: {
      // REQUIRED only for Federated Authentication - Amazon Cognito Identity Pool ID
      // identityPoolId: 'eu-west-2:9a615c10-690d-450c-8665-49800f7f2821',
      // REQUIRED - Amazon Cognito Region
      region: 'eu-west-2',
      // OPTIONAL - Amazon Cognito User Pool ID
      userPoolId: 'eu-west-2_aDUQgKnaD',
      // OPTIONAL - Amazon Cognito Web Client ID (26-char alphanumeric string)
      userPoolWebClientId: '2rr4oq89isl55e38fsksj3dqs6',
      // OPTIONAL - Enforce user authentication prior to accessing AWS resources or not
      mandatorySignIn: false,
      identityPoolId: 'eu-west-2:cf5970ab-7932-46fc-8e4c-d6decc34cd34',

      // OPTIONAL - Manually set the authentication flow type. Default is 'USER_SRP_AUTH'
      // authenticationFlowType: 'USER_PASSWORD_AUTH',
    },
    oauth: {
      domain: 'norbrook.auth.eu-west-2.amazoncognito.com',
      scope: ['phone', 'email', 'openid', 'profile', 'aws.cognito.signin.user.admin'],
      redirectSignIn: 'https://norbrookadvantage.co.uk/auth/redirect/',
      redirectSignOut: 'https://norbrookadvantage.co.uk/auth/logout/',
      responseType: 'code',
    },
  },
  asset: {
    cdn: 'https://norbrook-assets.s3.eu-west-2.amazonaws.com',
  },
};

/*
 * For easier debugging in development mode, you can import the following file
 * to ignore zone related error stack frames such as `zone.run`, `zoneDelegate.invokeTask`.
 *
 * This import should be commented out in production mode because it will have a negative impact
 * on performance if an error is thrown.
 */
// import 'zone.js/dist/zone-error';  // Included with Angular CLI.
