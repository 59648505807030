<div class="award-points-page">
  <app-header
    heading-copy="# {{ title }}"
    top-banner-url="{{ banner }}"
    [showHeaderSummary]="true"
  ></app-header>

  <div class="search-container">
    <div class="container">
      <div class="row">
        <div class="col-md-12">
          <div
            class="header-summary d-flex align-items-center justify-content-start space-between"
          >
            <div class="summary-box d-flex flex-row align-items-stretch">
              <span>POINTS AVAILABLE</span>
              <h4>{{ totalPointsAvailable }}</h4>
            </div>
            <div class="summary-box d-flex flex-row align-items-stretch">
              <span>TOTAL POINTS VALUE</span>
              <h4>£{{ totalPointsValue | number : '1.2-2' }}</h4>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>

  <div class="search-container">
    <div class="container">
      <form
        *ngIf="searchParamsForm"
        [formGroup]="searchParamsForm"
        (ngSubmit)="onSubmit()"
        class="form-standard"
      >
        <h2>&nbsp;</h2>
        <div class="row">
          <div class="col-md-8">
            <h2><strong>SEARCH FOR VET</strong></h2>
          </div>
          <div class="col-sm-4 btn-col">
            <div class="form-group">
              <button class="btn btn-info" type="button" (click)="resetSearch()">CLEAR X</button>
              <button class="btn btn-primary" type="submit">SEARCH</button
              ><img src="../../../../assets/images/tick.png" />
            </div>
          </div>
        </div>

        <div class="row">
          <div class="col-sm-6">
            <div class="row">
              <div class="col-lg-5 col-md-5 col-sm-6 col-xs-12">
                <label>FIRST NAME</label>
              </div>
              <div class="col-lg-7 col-md-7 col-sm-6 col-xs-12">
                <input
                  name="firstName"
                  formControlName="firstName"
                  class="form-control text-input"
                  type="text"
                  placeholder="First Name"
                />
              </div>
            </div>
          </div>
          <div class="col-sm-6">
            <div class="row">
              <div class="col-lg-5 col-md-5 col-sm-6 col-xs-12">
                <label>EMAIL ADDRESS</label>
              </div>
              <div class="col-lg-7 col-md-7 col-sm-6 col-xs-12">
                <input
                  name="email"
                  formControlName="email"
                  class="form-control text-input"
                  type="text"
                  placeholder="Email Address"
                />
              </div>
            </div>
          </div>
        </div>
        <div class="row">
          <div class="col-sm-6">
            <div class="row">
              <div class="col-lg-5 col-md-5 col-sm-6 col-xs-12">
                <label>LAST NAME</label>
              </div>
              <div class="col-lg-7 col-md-7 col-sm-6 col-xs-12">
                <input
                  name="lastName"
                  formControlName="lastName"
                  class="form-control text-input"
                  type="text"
                  placeholder="Last Name"
                />
              </div>
            </div>
          </div>
          <div class="col-sm-6">
            <div class="row">
              <div class="col-lg-5 col-md-5 col-sm-6 col-xs-12">
                <label>POSTCODE</label>
              </div>
              <div class="col-lg-7 col-md-7 col-sm-6 col-xs-12">
                <input
                  name="postcode"
                  formControlName="postcode"
                  class="form-control text-input"
                  type="text"
                  placeholder="Postcode"
                />
              </div>
            </div>
          </div>
        </div>
        <div class="row">
          <div class="col-sm-6">
            <div class="row">
              <div class="col-lg-5 col-md-5 col-sm-6 col-xs-12">
                <label>VET PRACTICE</label>
              </div>
              <div class="col-lg-7 col-md-7 col-sm-6 col-xs-12">
                <input
                  name="vetPracticeName"
                  formControlName="vetPracticeName"
                  class="form-control text-input"
                  type="text"
                  placeholder="Vet Practice"
                />
              </div>
            </div>
          </div>
          <div class="col-sm-6">
            <div class="row">
              <div class="col-lg-5 col-md-5 col-sm-6 col-xs-12">
                <label>PHONE NUMBER</label>
              </div>
              <div class="col-lg-7 col-md-7 col-sm-6 col-xs-12">
                <input
                  name="phone"
                  formControlName="phone"
                  class="form-control text-input"
                  type="text"
                  placeholder="Phone Number"
                />
              </div>
            </div>
          </div>
        </div>
        <h2>&nbsp;</h2>
      </form>
    </div>
  </div>
  <div *ngIf="submitted" class="container">
    <h2>&nbsp;</h2>
    <h2><strong>VET PRACTICE SEARCH RESULTS</strong></h2>
    <p>{{ errorMessages }}</p>
    <vet-member-search-results></vet-member-search-results>
  </div>
  <app-footer></app-footer>
</div>
