import { Injectable } from '@angular/core';
import { environment } from 'src/environments/environment';
import { BaseCoreService } from 'src/app/core/services/base-core-service';
import { Profile } from '../models/profile';
import { ApiRestService } from '@motivforce/mx-library-angular';
import { TransferPoints } from 'src/app/account-manager/models/transferPoints';

@Injectable({
  providedIn: 'root',
})
export class UserService extends BaseCoreService {
  constructor(protected apiRestService: ApiRestService) {
    super('users', apiRestService);
  }

  getProfile(): Promise<Profile> {
    return this.apiRestService
      .get<Profile>(`${environment.api.core.baseUrl}/members/users/profile`)
      .toPromise();
  }

  getAddresses(): Promise<any> {
    return this.apiRestService
      .get(`${environment.api.core.baseUrl}/members/users/addresses`)
      .toPromise();
  }
}
