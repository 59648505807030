<div class="layout-boxed content contact-us-page">
  <app-header heading-copy="{{title}}" top-banner-url="{{banner}}"></app-header>
  <div class="container">
    <div class="col-sm-12 pd-0" [innerHtml]="body"></div>
    <form [formGroup]="contactUsForm" (ngSubmit)="onSubmit()">
      <div class="col-sm-12 pd-0">
        <div *ngIf="message && message != ''">
          <i>{{message}}</i>
        </div>
        <div class="row">
          <div class="col-md-12">
            <div class="form-group">
              <div class="col-md-3">
                <label>NAME *</label>
              </div>
              <div class="col-md-9">
                <input type="text" formControlName="name" name="name" />
              </div>
            </div>
          </div>
        </div>
        <div class="row">
          <div class="col-md-12">
            <div class="form-group">
              <div class="col-md-3">
                <label>EMAIL *</label>
              </div>
              <div class="col-md-9">
                <input type="text" formControlName="email" name="email" />
              </div>
            </div>
          </div>
        </div>
        <div class="row">
          <div class="col-md-12">
            <div class="form-group">
              <div class="col-md-3">
                <label>SUBJECT *</label>
              </div>
              <div class="col-md-9">
                <select class="form-control" formControlName="subject" name="subject">
                  <option selected disabled value="">Select Subject</option>
                  <option *ngFor="let subject of subjects" [value]="subject">{{subject}}</option>
                </select>
              </div>
            </div>
          </div>
        </div>
        <div class="row" style="margin-top: 1em; padding-bottom: 1em">
          <div class="col-md-12">
            <div class="form-group">
              <div class="col-md-3">
                <label>MESSAGE *</label>
              </div>
              <div class="col-md-9">
                <textarea class="form-control" type="text" formControlName="message" rows="10"></textarea>
              </div>
            </div>
          </div>
        </div>
        <div class="row">
          <div class="col-md-12">
            <div class="form-group">
              <div class="col-md-12">
                <button class="btn btn-primary" type="submit">
                  SEND EMAIL
                </button>
              </div>

            </div>

          </div>

        </div>
      </div>

    </form>
    <app-footer></app-footer>
  </div>

</div>