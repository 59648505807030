import { Component, OnInit, OnDestroy } from '@angular/core';
import { FormControl, FormGroup, Validators } from '@angular/forms';
import { DialogNgbService } from '@motivforce/mx-library-angular';
import { Subscription } from 'rxjs';
import { VetPractice } from 'src/app/shared/models/vet-practice';
import { UserService } from 'src/app/shared/services/user.service';
import { MemberManagerService } from 'src/app/vet/services/member-manager.service';
import { TransferPoints } from '../../models/transferPoints';

@Component({
  selector: 'vet-member-search-results',
  templateUrl: './member-search-results.component.html',
  styleUrls: ['./member-search-results.component.scss'],
})
export class MemberSearchResultsComponent implements OnInit, OnDestroy {
  private resultsSubscription: Subscription;
  members: VetPractice[] = [];
  selectedMember: number;

  constructor(
    private managerService: MemberManagerService,
    private dialogService: DialogNgbService,
    private userService: UserService,
  ) {}

  ngOnInit(): void {
    this.resultsSubscription = this.managerService.memberResults$.subscribe((results) => {
      this.members = results;
    });
  }

  ngOnDestroy(): void {
    if (this.resultsSubscription) {
      this.resultsSubscription.unsubscribe();
    }
  }

  selectMember(member: VetPractice) {
    const memberResult = [member];
    this.selectedMember = member.memberId;
    this.dialogService.openFeatureDialog('transferPointsDialog', [this.selectedMember]);
  }
}
