<div class="member-manager-page">
    <app-header heading-copy="# {{title}}" top-banner-url="{{banner}}"></app-header>
    <div class="container">
        <div class="row">
            <div class="col-sm-12">
                &nbsp;
            </div>
        </div>
        <div class="row">
            <div class="col-sm-12">
                <div *ngIf="message && message != ''">
                    <i>{{ message }}</i>
                </div>
            </div>
            <div class="col-sm-12">
                <p>&nbsp;</p>
                <p>Search for member with either membership number, email address, name or phone number. Please note
                    that searching by name requires that a first and last name be entered.</p>
            </div>
        </div>

        <vet-member-search-form></vet-member-search-form>
        <vet-member-cat-list *ngIf="renderForms"></vet-member-cat-list>
        <vet-add-purchase-form *ngIf="renderForms" [addPurchaseMessage]="addPurchaseMessage"></vet-add-purchase-form>
        <vet-add-test-result-form *ngIf="renderForms" [addTestMessage]="addTestMessage"></vet-add-test-result-form>
        <vet-member-points-balance *ngIf="renderForms" [pointsSummary]="pointsSummary"></vet-member-points-balance>
        <app-footer></app-footer>
    </div>
</div>