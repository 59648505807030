import { HttpClient } from '@angular/common/http';
import { TranslateLoader } from '@ngx-translate/core';
import { Observable } from 'rxjs';
import gql from 'graphql-tag';
import { Apollo } from 'apollo-angular';
import { map } from 'rxjs/operators';
import { Settings } from '../../models/settings';
import { SettingsProvider } from 'src/app/shared/settings.provider';

export class M8TranslationLoader implements TranslateLoader {
    private settings: Settings;
    constructor(
        private apollo: Apollo,
        private settingsProvider: SettingsProvider
    ) {
        this.settings = settingsProvider.settings;
    }

    /**
     * Gets the translations from the server
     */
    public getTranslation(languageId: string): Observable<object> {
        const languageIdOverride = this.GetLanguageIdOverride();
        if (languageIdOverride) {
            languageId = languageIdOverride;
        }
        console.log(languageId);
        const query = gql`
        query translations($siteId: Float!, $languageId: String!) {
          translations(siteId: $siteId, languageId: $languageId) {
            siteId
            languageId
            translations
          }
        }
      `;
        return this.apollo
            .watchQuery<any>({
                query,
                variables: {
                    siteId: this.settings.siteId,
                    languageId,
                },
            })
            .valueChanges.pipe(map((response) => response.data.translations.translations));
    }

    private GetLanguageIdOverride() {
        let languageId: string = null;
        if (
            this.settings &&
            this.settings.siteSettings &&
            this.settings.siteSettings.languageIdOverride
        ) {
            languageId = this.settings.siteSettings.languageIdOverride;
        }

        return languageId;
    }
}
