import { BaseModel } from './base-model';

export class Address extends BaseModel {
  address1: string;
  address2: string;
  postCode: string;
  state: string;
  city: string;
  suburb: string;
  county: string;
  countryId: number;
  mobileTelephone: string;
  neighborhood: string;
  addressTypeId: number;
}
