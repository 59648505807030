import { Component, Input, OnInit } from '@angular/core';
import { FormControl, FormGroup, Validators } from '@angular/forms';
import { Auth } from 'aws-amplify';
import { NgxSpinnerService } from 'ngx-bootstrap-spinner';
import { Cat, Profile } from 'src/app/shared/models/profile';
import { User } from 'src/app/shared/models/user';
import { ProfileService } from 'src/app/shared/services/profile.service';

@Component({
  selector: 'app-profile-form',
  templateUrl: './profile-form.component.html',
  styleUrls: ['./profile-form.component.scss'],
})
export class ProfileFormComponent implements OnInit {
  @Input() bodyContent: string;

  submitted = false;
  submitModel: Profile = {
    firstName: '',
    lastName: '',
    email: '',
    phone: '',
    companyName: '',
    postcode: '',
    cats: [],
    userRoleName: '',
  } as Profile;

  message: string;
  passwordMessage: string;
  catMessage: string;
  user: User;
  cats: Cat[];
  isVet: boolean;

  profileForm: FormGroup;

  passwordForm = new FormGroup({
    newPassword: new FormControl(''),
    confirmPassword: new FormControl(''),
    oldPassword: new FormControl(''),
  });

  constructor(private profileService: ProfileService, private spinner: NgxSpinnerService) {}

  ngOnInit(): void {
    this.profileService.getProfile().then((profileData) => {
      if (profileData.userRoleName === 'vet.main.access') {
        this.isVet = true;
        this.initialiseVetForm();
      } else {
        this.initialiseCatForm();
      }

      Object.keys(this.profileForm.controls).forEach((key) => {
        this.profileForm.controls[key].setValue(profileData[key]);
      });

      if (profileData.cats && profileData.cats.length > 0) {
        this.cats = profileData.cats;
        this.cats = this.cats.filter((x) => !x.isDeceased);
      }
    });
  }

  initialiseCatForm(): void {
    this.profileForm = new FormGroup({
      firstName: new FormControl('', [Validators.required]),
      lastName: new FormControl('', [Validators.required]),
      email: new FormControl({ value: '', disabled: true }, [
        Validators.required,
        Validators.email,
      ]),
      phone: new FormControl('', [Validators.required]),
      companyName: new FormControl({ value: '', disabled: true }),
      userRoleName: new FormControl(''),
      cats: new FormControl([]),
      postcode: new FormControl(''),
      salesManagerName: new FormControl(''),
    });
  }

  initialiseVetForm(): void {
    this.profileForm = new FormGroup({
      firstName: new FormControl({ value: '', disabled: true }),
      lastName: new FormControl({ value: '', disabled: true }),
      email: new FormControl({ value: '', disabled: true }),
      phone: new FormControl({ value: '', disabled: true }),
      companyName: new FormControl({ value: '', disabled: true }),
      userRoleName: new FormControl(''),
      cats: new FormControl([]),
      postcode: new FormControl({ value: '', disabled: true }),
      salesManagerName: new FormControl({ value: '', disabled: true }),
    });
  }

  updateCat(id: number) {
    const currentCat = this.cats.find((cat) => cat.id === id);

    this.profileService
      .updateCatDeceased(id)
      .then((result) => {
        if (!result) {
          currentCat.message =
            "We're very sorry, an error has occurred, please contact us or try again later.";
        } else {
          currentCat.message = "We've received your update, thanks for letting us know.";
          console.log('Profile update successful.');
        }
      })
      .catch((error) => {
        currentCat.message =
          "We're very sorry, an error has occurred, please contact us or try again later.";
        console.log(error);
      })
      .finally(() => this.spinner.hide());
  }

  onSubmit() {
    this.submitted = true;
    this.spinner.show();

    if (this.profileForm.valid) {
      const formData = this.profileForm.getRawValue();

      Object.keys(this.submitModel).forEach((key) => {
        this.submitModel[key] = formData[key];
      });

      if (this.profileForm.value.userRoleName === 'manager.main.access') {
        this.profileService
          .updateCatProfile(this.submitModel)
          .then((result) => {
            if (result.hasErrors) {
              this.message = result.mesage;
            } else {
              this.message = 'Thank you, your profile has now been updated.';
              console.log('Profile update successful.');
            }
          })
          .catch((error) => {
            this.message = 'An error has occurred, please contact us or try again later.';
            console.log(error);
          })
          .finally(() => this.spinner.hide());
      } else {
        this.profileService
          .updateVetProfile(this.submitModel)
          .then((result) => {
            if (result.hasErrors) {
              this.message = result.mesage;
            } else {
              this.message = 'Thank you, your profile has now been updated.';
              console.log('Profile update successful.');
            }
          })
          .catch((error) => {
            this.message = 'An error has occurred, please contact us or try again later.';
            console.log(error);
          })
          .finally(() => this.spinner.hide());
      }

      console.log('Submitted form data:', this.submitModel);
    } else {
      console.log('Form is invalid.');
    }

    return false;
  }

  updatePassword(): void {
    if (this.passwordForm.valid) {
      const formValue = this.passwordForm.value;

      if (formValue.newPassword === formValue.confirmPassword) {
        this.spinner.show();
        Auth.currentAuthenticatedUser()
          .then((user) => {
            return Auth.changePassword(user, formValue.oldPassword, formValue.newPassword);
          })
          .then((data) => {
            console.log('Auth.changePassword() SUCCESS:', data);
            this.passwordMessage = 'Your password has been updated!';
          })
          .catch((error) => {
            console.log('Auth.changePassword() ERROR:', error);
            this.passwordMessage = 'There was an error updating your password.';
          })
          .finally(() => this.spinner.hide());
      } else {
        this.passwordMessage = 'The passwords entered do not match';
        console.log('Passwords do not match.');
      }
    } else {
      console.log('Profile form is invalid.');
    }
  }
}
