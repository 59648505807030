<div>
    <app-header heading-copy="# {{title}}" top-banner-url="{{banner}}"></app-header>
    <div class="container">
        <div class="row">
            <div class="col-sm-12">
                <p>&nbsp;</p>
            </div>
        </div>
        <div class="row">
            <div class="col-md-9">
                <div class="row">
                    <div class="col-sm-12">
                        <pc-cart></pc-cart>
                    </div>
                </div>
                <div class="row">
                    <div class="col-sm-12">
                        <p>&nbsp;</p>
                    </div>
                </div>
                <div class="row">
                    <div class="col-sm-12">
                        <pc-checkout-details-form></pc-checkout-details-form>
                    </div>
                </div>
            </div>

            <div class="col-md-3">
                <a routerLink="/vet/rewards/cart" class="btn btn-primary cart-button-top">BACK TO CART</a>
                <a routerLink="/vet/rewards" class="btn btn-primary full-width cart-button">BACK TO CATALOGUE</a>
            </div>
        </div>
        <app-footer></app-footer>
    </div>
</div>