<div class="component-row">
    <div class="row">
        <div class="col-sm-12">
            <h3>Add Thyronorm<sup>&reg;</sup> Purchase</h3>
        </div>
        <div class="col-sm-12">
            <div *ngIf="addPurchaseMessage && addPurchaseMessage != ''">
                <i>{{ addPurchaseMessage }}</i>
            </div>
        </div>
    </div>
    <form [formGroup]="purchaseForm" (ngSubmit)="onSubmit()" class="form-standard">
        <div class="row">

            <div class="col-sm-2">
                <img [src]="productImgUrl" class="product-img" />
            </div>
            <div class="col-sm-6">
                <div class="row">
                    <div class="col-sm-6">
                        <div class="form-group one-line">
                            <input formControlName="product30ml" type="checkbox" />
                            <label class="checkbox-label">&nbsp; &nbsp;30ml</label>
                        </div>
                    </div>
                    <div class="col-sm-6">
                        <div class="row">
                            <div class="col-sm-6">
                                <label>Quantity</label>
                            </div>
                            <div class="col-sm-4">
                                <input formControlName="product30mlQty" class="form-control text-input" type="number" />
                            </div>
                        </div>
                    </div>
                </div>
                <div class="row">
                    <div class="col-sm-6">
                        <div class="form-group one-line">
                            <input formControlName="product100ml" type="checkbox" />
                            <label class="checkbox-label">&nbsp; &nbsp;100ml</label>
                        </div>
                    </div>
                    <div class="col-sm-6">
                        <div class="row">
                            <div class="col-sm-6">
                                <label>Quantity</label>
                            </div>
                            <div class="col-sm-4">
                                <input formControlName="product100mlQty" class="form-control text-input"
                                    type="number" />
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            <div class="col-sm-4">
                <div class="row">
                    <div class="col-sm-6">
                        <label>Date of Purchase</label>
                    </div>
                    <div class="col-sm-6">
                        <input formControlName="purchaseDate" class="form-control text-input" type="date" />
                    </div>
                </div>
                <div class="row">
                    <div class="col-sm-12">
                        <button type="submit" class="btn btn-primary">SUBMIT</button>
                    </div>

                </div>
            </div>
        </div>
    </form>
</div>