import { Component, OnInit, Input } from '@angular/core';
import { Content } from 'src/app/core/models/content.model';
import { DynamicLayout } from 'src/app/core/models/dynamic-layout.model';

@Component({
  selector: 'app-article-detail',
  templateUrl: './article-detail.component.html',
  styleUrls: ['./article-detail.component.scss'],
})
export class ArticleDetailComponent implements OnInit, DynamicLayout {
  @Input()
  baseContent: Content;

  body: string;
  image: string;
  video: string;
  title: string;
  banner: string;
  backUrl: string;
  backLink: string;

  constructor() {}

  ngOnInit() {
    const videoField = this.baseContent.fields.find((f) => f.name === 'Video');
    const imageField = this.baseContent.fields.find((f) => f.name === 'Article Image');
    const titleField = this.baseContent.fields.find((f) => f.name === 'Title');
    const detailField = this.baseContent.fields.find((f) => f.name === 'Detail Text');
    const bannerField = this.baseContent.fields.find((f) => f.name === 'Banner');
    this.backLink = this.baseContent.properties.backLink
      ? this.baseContent.properties.backLink.value
      : '..';

    this.title = titleField ? titleField.value.value : '';
    this.body = detailField ? detailField.value.value : '';
    this.image = imageField ? imageField.value.url : '';
    this.video = videoField ? videoField.value.url : '';
    this.banner = bannerField ? bannerField.value.url : '';

    if (this.backLink) {
      this.backUrl = this.backLink;
    } else {
      if (window.location.pathname.startsWith('/vet')) {
        this.backUrl = '/vet/tools-and-cpd';
      } else {
        this.backUrl = '/cat/pet-care-advice';
      }
    }
  }
}
