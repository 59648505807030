import { Component, OnInit, OnDestroy, Input } from '@angular/core';
import { Subscription } from 'rxjs';
import { Content } from 'src/app/core/models/content.model';
import { DynamicLayout } from 'src/app/core/models/dynamic-layout.model';
import { ProductCatalogueService } from '../../services/product-catalogue.service';

@Component({
  selector: 'app-redemption-complete-page',
  templateUrl: './redemption-complete-page.component.html',
  styleUrls: ['./redemption-complete-page.component.scss']
})
export class RedemptionCompletePageComponent implements OnInit, OnDestroy, DynamicLayout {
  @Input() baseContent: Content;

  title: string;
  banner: string;

  showMessage = false;
  private redemptionSub: Subscription;
  redemptionId = 0;

  constructor(private productCatalogueService: ProductCatalogueService) { }

  ngOnInit(): void {
    const titleField = this.baseContent.fields.find(f => f.name === 'Title');
    const bannerField = this.baseContent.fields.find(f => f.name === 'Banner');
    this.title = titleField.value.value;
    this.banner = bannerField.value.url;

    this.redemptionSub = this.productCatalogueService.currentRedemption$
      .subscribe(redemption => {
        if (redemption != null) {
          this.redemptionId = redemption.id;
          this.showMessage = true;
        }
      });
  }

  ngOnDestroy() {
    this.redemptionSub.unsubscribe();
  }

}
