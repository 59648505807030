import { Component, OnInit, Input } from '@angular/core';
import { Content } from 'src/app/core/models/content.model';
import { DynamicLayout } from 'src/app/core/models/dynamic-layout.model';

@Component({
  selector: 'app-default-template',
  templateUrl: './default-template.component.html',
  styleUrls: ['./default-template.component.scss'],
})
export class DefaultTemplateComponent implements OnInit, DynamicLayout {
  @Input() baseContent: Content;

  body: string;
  title: string;
  banner: string;
  constructor() { }

  ngOnInit() {
    const titleField = this.baseContent.fields.find(f => f.name === 'Title');
    const bannerField = this.baseContent.fields.find(f => f.name === 'Banner');
    const bodyField = this.baseContent.fields.find(f => f.name === 'Body');

    this.title = titleField.value.value;
    this.body = bodyField.value.value;
    this.banner = bannerField.value.url;
  }
}
