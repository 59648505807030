<div>
    <app-header heading-copy="# {{title}}"
        top-banner-url="{{banner}}">
    </app-header>
    <div class="container">
        <div class="content">
            <div *ngIf="showMessage" class="row">
                <div class="col-sm-12">
                    <h2>Thank you for placing your order</h2>
                    <p>Your order number is: {{redemptionId}}</p>
                </div>
            </div>
    
            <div class="row">
                <div class="col-lg-12">
                    <a [routerLink]="['/vet/account-summary']" class="btn btn-primary mr-5">VIEW REWARDS ORDERED</a>
                    <a [routerLink]="['/vet/rewards']" class="btn btn-primary">CONTINUE SHOPPING</a>
                </div>
            </div>
        </div>
        

        <app-footer></app-footer>
    </div>
</div>