import { Component, OnInit, OnDestroy, Input } from '@angular/core';
import { Subscription, Observable } from 'rxjs';
import { FormControl, FormGroup, Validators } from '@angular/forms';
import { CatFormData } from 'src/app/vet/models/cat-form-data';
import { MemberManagerService } from 'src/app/vet/services/member-manager.service';
import { formatDate, formatDateTime } from 'src/app/core/utils/formatDate-func';
import { VetPracticeDetailed } from 'src/app/shared/models/vet-practice';

@Component({
  selector: 'vet-member-cat-form',
  templateUrl: './member-cat-form.component.html',
  styleUrls: ['./member-cat-form.component.scss'],
})
export class MemberCatFormComponent implements OnInit, OnDestroy {
  @Input() selectedCatDetails: Observable<VetPracticeDetailed>;
  private selectedCatDetailsSub: Subscription;
  private selectedMemberSub: Subscription;
  catDetails: VetPracticeDetailed;
  catForm: FormGroup;
  errorMessages: string;
  submitted = false;
  submitModel: CatFormData = {
    birthDate: null,
    catId: null,
    catOwnerId: null,
    isDeceased: false,
    name: '',
    prescriptionDueDate: null,
    t4TestDueDate: null,
  } as CatFormData;
  renderForm = false;
  showTestResultHistory: boolean;

  constructor(private managerService: MemberManagerService) {}

  ngOnInit(): void {
    this.selectedMemberSub = this.managerService.selectedMember$.subscribe((member) => {
      if (member != null) {
      }
    });
    this.selectedCatDetailsSub = this.selectedCatDetails.subscribe((selectionData) => {
      this.catDetails = selectionData;

      this.catForm = new FormGroup({});
      const formData = this.catForm.getRawValue();
      console.log('Preloaded Cat form values', formData);
      this.renderForm = true;
    });
  }

  ngOnDestroy(): void {
    if (this.selectedMemberSub) {
      this.selectedMemberSub.unsubscribe();
    }

    if (this.selectedCatDetailsSub) {
      this.selectedCatDetailsSub.unsubscribe();
    }
  }

  toggleTestResults() {
    if (this.showTestResultHistory) {
      this.showTestResultHistory = false;
    } else {
      this.showTestResultHistory = true;
    }
  }

  onSubmit() {
    this.submitted = true;

    if (this.catForm.valid) {
      const formData = this.catForm.getRawValue();

      Object.keys(this.submitModel).forEach((key) => {
        if (key !== 'catId' && key !== 'catOwnerId') {
          this.submitModel[key] = formData[key];
        }
      });
      this.errorMessages = 'Please ensure all fields for the cat form are filled out correctly.';
      console.log('Cat enrolment form is invalid.');
    }
  }
}
