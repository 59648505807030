import { Component, Input, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { Content } from 'src/app/core/models/content.model';
import { DynamicLayout } from 'src/app/core/models/dynamic-layout.model';
import { RegistrationService } from 'src/app/shared/services/registration.service';

@Component({
  selector: 'app-accept-terms',
  templateUrl: './accept-terms.component.html',
  styleUrls: ['./accept-terms.component.scss'],
})
export class AcceptTermsComponent implements OnInit, DynamicLayout {
  @Input() baseContent: Content;

  body: string;
  title: string;
  banner: string;
  message: string;

  constructor(private registrationService: RegistrationService, private router: Router) {}

  ngOnInit(): void {
    const titleField = this.baseContent.fields.find((f) => f.name === 'Title');
    const bannerField = this.baseContent.fields.find((f) => f.name === 'Banner');
    const bodyField = this.baseContent.fields.find((f) => f.name === 'Body');

    this.title = titleField.value.value;
    this.body = bodyField.value.value;
    this.banner = bannerField.value.url;
  }

  acceptTerms(): void {
    this.registrationService
      .updateTermsAndConditions()
      .then((result) => {
        this.router.navigate(['/account-manager/home']);
      })
      .catch((error) => {
        this.message = 'There was an error accepting the terms and conditions, please try again';
      });
  }
}
