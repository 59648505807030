import { ApiRestService } from '@motivforce/mx-library-angular';
import { environment } from '../../../environments/environment';

export class BaseCoreService {
  apiBaseUrl: string;
  memberBaseUrl: string;
  constructor(endpoint: string, protected apiRestService: ApiRestService) {
    this.apiBaseUrl = this.getBaseUrl(endpoint);
    this.memberBaseUrl = this.getMemberBaseUrl(endpoint);
  }

  protected getBaseUrl(endpoint: string = ''): string {
    return `${environment.api.core.baseUrl}/${endpoint}`;
  }

  protected getMemberBaseUrl(endpoint: string = ''): string {
    return this.getBaseUrl(`${environment.api.core.path.member}/clients/${endpoint}`);
  }
}

