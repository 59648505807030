import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { ReactiveFormsModule } from '@angular/forms';
import { RouterModule } from '@angular/router';
import { DefaultTemplateComponent } from 'src/app/shared/components/default-template/default-template.component';
import { LandingPageComponent } from 'src/app/shared/components/landing-page/landing-page.component';
import { DynamicLayoutComponent } from 'src/app/shared/components/dynamic-layout/dynamic-layout.component';
import { LayoutHostDirective } from 'src/app/shared/directives/layout-host.directive';
import { ProfilePageComponent } from 'src/app/shared/components/profile/profile-page/profile-page.component';
import { ArticleListPageComponent } from 'src/app/shared/components/articles/article-list-page/article-list-page.component';
import { ArticleListComponent } from 'src/app/shared/components/articles/article-list/article-list.component';
import { ArticleDetailComponent } from 'src/app/shared/components/articles/article-detail/article-detail.component';
import { ArticleComponent } from 'src/app/shared/components/articles/article/article.component';
import { ContactFormComponent } from 'src/app/shared/components/contact-form/contact-form.component';
import { ContentLoaderModule } from '@netbasal/ngx-content-loader';
import { ErrorModule } from 'src/app/shared/components/error/error.module';
import { SharedModule } from 'src/app/shared/shared.module';
import { NgbModule } from '@ng-bootstrap/ng-bootstrap';
import { MemberManagerModule } from 'src/app/vet/components/member-manager/member-manager.module';
import { ProductCatalogueModule } from 'src/app/vet/components/product-catalogue/product-catalogue.module';
import { ProfileFormComponent } from 'src/app/shared/components/profile/profile-form/profile-form.component';
import { VetModule } from 'src/app/vet/vet.module';
import { AccountManagerModule } from 'src/app/account-manager/account-manager.module';

@NgModule({
  declarations: [
    DefaultTemplateComponent,
    LandingPageComponent,
    DynamicLayoutComponent,
    LayoutHostDirective,
    ProfilePageComponent,
    ProfileFormComponent,
    ArticleListPageComponent,
    ArticleListComponent,
    ArticleDetailComponent,
    ArticleComponent,
    ContactFormComponent,
  ],
  imports: [
    CommonModule,
    RouterModule,
    ReactiveFormsModule,
    ContentLoaderModule,
    ErrorModule,
    SharedModule,
    NgbModule,
    MemberManagerModule,
    ProductCatalogueModule,
    VetModule,
    AccountManagerModule,
  ],
  exports: [],
  entryComponents: [DefaultTemplateComponent],
})
export class LayoutModule {}
