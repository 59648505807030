import { Component, Input, OnDestroy, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { Subscription } from 'rxjs';
import { Content } from 'src/app/core/models/content.model';
import { DynamicLayout } from 'src/app/core/models/dynamic-layout.model';
import { PointsSummary } from 'src/app/shared/models/points-summary';
import { PointsSummaryService } from 'src/app/shared/services/points-summary.service';
import { SettingsProvider } from 'src/app/shared/settings.provider';

@Component({
  selector: 'account-manager-home',
  templateUrl: './account-manager-home.component.html',
  styleUrls: ['./account-manager-home.component.scss'],
})
export class AccountManagerHomeComponent implements OnInit, OnDestroy, DynamicLayout {
  @Input() baseContent: Content;
  private pointsSummarySub: Subscription;

  pointsSummary: PointsSummary;
  title: string;
  banner: string;
  pointsNeededToNextRedemption: number;
  enoughPointsToRedeem: boolean;

  constructor(
    private pointsSummaryService: PointsSummaryService,
    private settingsProvider: SettingsProvider,
    private router: Router,
  ) {}

  ngOnInit(): void {
    const titleField = this.baseContent.fields.find((f) => f.name === 'Title');
    const bannerField = this.baseContent.fields.find((f) => f.name === 'Banner');

    this.title = titleField.value.value;
    this.banner = bannerField.value.url;

    this.settingsProvider.getRemoteUserSettings().then((settings) => {
      if (!settings.data.user.acceptedTermsConditions) {
        this.router.navigate(['/account-manager/accept-terms']);
      }

      this.pointsSummary = null;
      this.pointsSummarySub = this.pointsSummaryService
        .getAccountManagerPointsSummary()
        .subscribe((result) => {
          this.pointsSummary = result;
        });
    });
  }

  ngOnDestroy() {
    this.pointsSummarySub.unsubscribe();
  }
}
