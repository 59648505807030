<div class="desktop-menu">
  <ul *ngIf="authenticated && catUser" ngbNav #nav="ngbNav" class="top-nav">
    <li ngbNavItem *ngIf="balance">Points Balance: {{ balance }}</li>
    <li ngbNavItem>
      <a href="/account-manager/profile"><i class="fa fa-user-circle"></i> Hello {{ name }}</a>
    </li>
    <li ngbNavItem>
      <a href (click)="logoutUser()"><i class="fa fa-lock"></i> Log Out</a>
    </li>
  </ul>

  <ul *ngIf="authenticated && !catUser" ngbNav #nav="ngbNav" class="top-nav">
    <li ngbNavItem>Points Balance: {{ balance }}</li>
    <li ngbNavItem>
      <a href="/vet/profile"><i class="fa fa-user-circle"></i> Hello {{ name }}</a>
    </li>
    <li ngbNavItem>
      <a href (click)="logoutUser()"><i class="fa fa-lock"></i> Log Out</a>
    </li>
  </ul>

  <ul *ngIf="!authenticated && catUser" ngbNav #nav="ngbNav" class="lower-nav hidden-sm hidden-xs">
    <li ngbNavItem>
      <a href="/account-manager/contact-us" class="nav-link">CONTACT US</a>
    </li>
  </ul>

  <ul *ngIf="!authenticated && !catUser" ngbNav #nav="ngbNav" class="lower-nav">
    <li ngbNavItem>
      <a href="/vet/register" class="nav-link">JOIN NOW</a>
    </li>
    <li ngbNavItem>
      <a href="/vet/faq" class="nav-link">FAQ</a>
    </li>
    <li ngbNavItem>
      <a href="/vet/contact-us" class="nav-link">CONTACT US</a>
    </li>
  </ul>

  <ul *ngIf="catUser && authenticated" ngbNav #nav="ngbNav" class="lower-nav">
    <li ngbNavItem>
      <a href="/account-manager/home" class="nav-link">HOME</a>
    </li>
    <li ngbNavItem>
      <a href="/account-manager/award-points" class="nav-link">AWARD POINTS</a>
    </li>
    <li ngbNavItem>
      <a href="/account-manager/points-awarded-summary" class="nav-link">POINTS AWARDED SUMMARY</a>
    </li>
    <!-- <li ngbDropdown class="nav-item">
      <a href (click)="(false)" class="nav-link" ngbDropdownToggle>MY ACCOUNT</a>
      <div ngbDropdownMenu>
        <button ngbDropdownItem><a href="/cat/rewards">MY REWARDS</a></button>
        <button ngbDropdownItem><a href="/cat/profile">MY PROFILE</a></button>
      </div>
    </li> -->
    <!-- <li ngbNavItem>
      <a href="/cat/pet-care-advice" class="nav-link">PET CARE ADVICE</a>
    </li> -->
    <li ngbNavItem>
      <a href="/account-manager/contact-us" class="nav-link">CONTACT US</a>
    </li>
  </ul>

  <ul
    *ngIf="!catUser && authenticated"
    ngbNav
    #nav="ngbNav"
    class="lower-nav justify-content-right"
  >
    <li ngbNavItem>
      <a href="/vet/home" class="nav-link">HOME</a>
    </li>
    <li>
      <a href="/vet/rewards" class="nav-link">REWARDS</a>
    </li>
    <li ngbDropdown class="nav-item">
      <a href (click)="(false)" class="nav-link" ngbDropdownToggle>MY ACCOUNT</a>
      <div ngbDropdownMenu>
        <button ngbDropdownItem><a href="/vet/account-summary">ACCOUNT SUMMARY</a></button>
        <button ngbDropdownItem><a href="/vet/profile">MY PROFILE</a></button>
      </div>
    </li>
    <li>
      <a href="/vet/promotions" class="nav-link">PROMOTIONS</a>
    </li>
    <li>
      <a href="/vet/earn-points" class="nav-link">EARN POINTS</a>
    </li>
    <li>
      <a href="/vet/tools-and-cpd" class="nav-link">TOOLS & CPD</a>
    </li>
    <li ngbDropdown class="nav-item">
      <a href (click)="(false)" class="nav-link" ngbDropdownToggle>HELP</a>
      <div ngbDropdownMenu>
        <button ngbDropdownItem><a href="/vet/contact-us">CONTACT US</a></button>
        <button ngbDropdownItem><a href="/vet/faq">FAQ</a></button>
        <button ngbDropdownItem><a href="/vet/terms-and-conditions">TERMS & CONDITIONS</a></button>
      </div>
    </li>
  </ul>
</div>

<div class="mobile-menu">
  <ul *ngIf="!authenticated && catUser" ngbNav #nav="ngbNav" class="lower-nav hidden-lg hidden-md">
    <li ngbDropdown class="nav-item">
      <a href (click)="(false)" class="nav-link" ngbDropdownToggle><i class="fa fa-bars"></i></a>
      <div ngbDropdownMenu>
        <button ngbDropdownItem><a href="/account-manager/faq" class="nav-link">FAQ</a></button>
        <button ngbDropdownItem>
          <a href="/account-manager/contact-us" class="nav-link">CONTACT US</a>
        </button>
      </div>
    </li>
  </ul>

  <ul *ngIf="!authenticated && !catUser" ngbNav #nav="ngbNav" class="lower-nav hidden-lg hidden-md">
    <li ngbDropdown class="nav-item">
      <a href (click)="(false)" class="nav-link" ngbDropdownToggle><i class="fa fa-bars"></i></a>
      <div ngbDropdownMenu>
        <button ngbDropdownItem><a href="/vet/register" class="nav-link">JOIN NOW</a></button>
        <button ngbDropdownItem><a href="/vet/faq" class="nav-link">FAQ</a></button>
        <button ngbDropdownItem><a href="/vet/contact-us" class="nav-link">CONTACT US</a></button>
      </div>
    </li>
  </ul>

  <ul *ngIf="catUser && authenticated" ngbNav #nav="ngbNav" class="lower-nav">
    <li ngbDropdown class="nav-item">
      <a href (click)="(false)" class="nav-link" ngbDropdownToggle><i class="fa fa-bars"></i></a>
      <div ngbDropdownMenu class="drop-down">
        <button ngbDropdownItem>
          <a href="/cat/rewards">BALANCE: {{ balance }}</a>
        </button>
        <button ngbDropdownItem><a href="/account-manager/home">HOME</a></button>
        <button ngbDropdownItem><a href="/account-manager/contact-us">CONTACT US</a></button>
        <button ngbDropdownItem><a href="/account-manager/faq">FAQ</a></button>
        <button ngbDropdownItem>
          <a href="/account-manager/terms-and-conditions">T&amp;Cs</a>
        </button>
        <button ngbDropdownItem>
          <a href (click)="logoutUser()"><i class="fa fa-lock"></i> Log Out</a>
        </button>
      </div>
    </li>
  </ul>

  <ul
    *ngIf="!catUser && authenticated"
    ngbNav
    #nav="ngbNav"
    class="lower-nav justify-content-right"
  >
    <li ngbDropdown class="nav-item">
      <a href (click)="(false)" class="nav-link" ngbDropdownToggle><i class="fa fa-bars"></i></a>
      <div ngbDropdownMenu>
        <button ngbDropdownItem>Points Balance: {{ balance }}</button>
        <button ngbDropdownItem>
          <a href="/vet/profile"><i class="fa fa-user-circle"></i> Hello {{ name }}</a>
        </button>
        <button ngbDropdownItem>
          <a href (click)="logoutUser()"><i class="fa fa-lock"></i> Log Out</a>
        </button>
        <button ngbDropdownItem><a href="/vet/home" class="nav-link">HOME</a></button>
        <button ngbDropdownItem><a href="/vet/rewards">REWARDS</a></button>
        <button ngbDropdownItem><a href="/vet/account-summary">ACCOUNT SUMMARY</a></button>
        <button ngbDropdownItem><a href="/vet/profile">MY PROFILE</a></button>
        <button ngbDropdownItem><a href="/vet/promotions">PROMOTIONS</a></button>
        <button ngbDropdownItem><a href="/vet/earn-points">EARN POINTS</a></button>
        <button ngbDropdownItem><a href="/vet/tools-and-cpd">TOOLS & CPD</a></button>
        <button ngbDropdownItem><a href="/vet/contact-us">CONTACT US</a></button>
        <button ngbDropdownItem><a href="/vet/faq">FAQ</a></button>
        <button ngbDropdownItem><a href="/vet/terms-and-conditions">TERMS & CONDITIONS</a></button>
      </div>
    </li>
  </ul>
</div>
