import { Injectable } from '@angular/core';
import Auth from '@aws-amplify/auth';
import { HttpClient } from '@angular/common/http';
import { environment } from '../../environments/environment';
import { BehaviorSubject, Subscription } from 'rxjs';
import { Settings } from '../core/models/settings';
import { ApiRestService } from '@motivforce/mx-library-angular';

@Injectable()
export class SettingsProvider {
  public settings: Settings = new Settings();
  public settingsChanged: BehaviorSubject<boolean> = new BehaviorSubject(false);
  public initComplete: BehaviorSubject<boolean> = new BehaviorSubject<boolean>(false);

  public refresUserSettingsSubscription: Subscription;

  constructor(
    private http: HttpClient,
    private apiRestService: ApiRestService
  ) { }

  async init(): Promise<any> {
    this.settings.language = 'en-US';

    let currentUser;

    try {
      currentUser = await Auth.currentAuthenticatedUser();
    } catch (error) {
      console.log('User not authenticated');
    }

    if (currentUser) {
      await this.refreshUserSettings();
    }

    return this.settings;
  }

  async refreshUserSettings() {
    const settings = await this.http
      .get(`${environment.api.core.baseUrl}/members/users/settings`, {})
      .toPromise();
    this.settings = { ...this.settings, ...settings };
    this.settingsChanged.next(true);
    console.log(this.settings);
  }

  async getRemoteUserSettings() {
    const settings: any = await this.http
      .get(`${environment.api.core.baseUrl}/members/users/settings`, {})
      .toPromise();

    return settings;
  }
}
