import { Component, Input, OnInit } from '@angular/core';
import { ActivatedRoute, Router, ParamMap } from '@angular/router';
import { Product } from 'src/app/vet/models/redemptions/product';
import { ProductCatalogueService } from 'src/app/vet/services/product-catalogue.service';

@Component({
  selector: 'app-product-detail-page',
  templateUrl: './product-detail-page.component.html',
  styleUrls: ['./product-detail-page.component.scss']
})
export class ProductDetailPageComponent implements OnInit {
  public productId;
  public addingToCart = false;
  public product: Product = null;  
  
  constructor(private route: ActivatedRoute, private router: Router, private productCatalogueService: ProductCatalogueService) { }

  ngOnInit() {
    this.route.paramMap.subscribe(params => {
      this.productId = params.get('id');
    });
  }

  async addToCart() {
    this.addingToCart = true;
    await this.productCatalogueService.addProductToCart(this.productId);

    this.router.navigateByUrl('/vet/rewards/cart');
  }

}
