import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { FormsModule } from '@angular/forms';
import { ReactiveFormsModule } from '@angular/forms';
import { MemberSearchFormComponent } from './member-search/member-search-form/member-search-form.component';
import { MemberSearchResultsComponent } from '../../../account-manager/components/member-search-results/member-search-results.component';
import { MemberPointsBalanceComponent } from './member-points-balance/member-points-balance.component';
import { MemberCatFormComponent } from './cat/member-cat-form/member-cat-form.component';
import { MemberCatListComponent } from './cat/member-cat-list/member-cat-list.component';
import { AddPurchaseFormComponent } from './add-product/add-purchase-form.component';
import { AddTestResultFormComponent } from './add-test-result/add-test-result-form.component';

@NgModule({
  declarations: [
    MemberSearchFormComponent,
    AddPurchaseFormComponent,
    AddTestResultFormComponent,
    MemberPointsBalanceComponent,
    MemberCatFormComponent,
    MemberCatListComponent,
  ],
  imports: [CommonModule, FormsModule, ReactiveFormsModule],
  exports: [
    MemberSearchFormComponent,
    MemberCatListComponent,
    MemberCatFormComponent,
    AddPurchaseFormComponent,
    AddTestResultFormComponent,
    MemberPointsBalanceComponent,
  ],
})
export class MemberManagerModule {}
