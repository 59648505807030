<div class="component-row">
    <div class="row">
        <div class="col-sm-12">
            <h3>Cat Owner Points Balance</h3>
        </div>
    </div>
    <form *ngIf="renderSummary" class="form-standard">
        <div class="row">
            <div class="col-sm-6">
                <div class="row">
                    <div class="col-sm-6">
                        <label>Total Points Earned</label>
                    </div>
                    <div class="col-sm-6">
                        <input [(ngModel)]="pointSummary.earned" [ngModelOptions]="{standalone: true}" [disabled]="true"
                            class="form-control text-input dark-blue-bg" type="text" />
                    </div>
                </div>
            </div>
            <div class="col-sm-6">
                <div class="row">
                    <div class="col-sm-6">
                        <label>Points Available to Spend</label>
                    </div>
                    <div class="col-sm-6">
                        <input [(ngModel)]="pointSummary.available" [ngModelOptions]="{standalone: true}"
                            [disabled]="true" class="form-control text-input dark-blue-bg" type="text" />
                    </div>
                </div>
            </div>
        </div>
        <div class="row">
            <div class="col-sm-6">
                <div class="row">
                    <div class="col-sm-6">
                        <label>Points Redeemed</label>
                    </div>
                    <div class="col-sm-6">
                        <input [(ngModel)]="pointSummary.redeemed" [ngModelOptions]="{standalone: true}"
                            [disabled]="true" class="form-control text-input dark-blue-bg" type="text" />
                    </div>
                </div>
            </div>
        </div>
    </form>
</div>