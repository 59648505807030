import { Component, OnDestroy, OnInit } from '@angular/core';
import { FormBuilder, FormGroup, FormControl } from '@angular/forms';
import { Subscription } from 'rxjs';
import { debounceTime, distinctUntilChanged } from 'rxjs/operators';
import { ProductCatalogueService } from 'src/app/vet/services/product-catalogue.service';

@Component({
  selector: 'pc-product-filter',
  templateUrl: './product-filter.component.html',
  styleUrls: ['./product-filter.component.scss'],
})
export class ProductFilterComponent implements OnInit, OnDestroy {
  filterSubscription: Subscription;

  pointsRange = [
    {
      label: 'All',
      min: null,
      max: null,
    },
    {
      label: '0-2,500',
      min: 0,
      max: 2500,
    },
    {
      label: '2,501-5,000',
      min: 2501,
      max: 5000,
    },
    {
      label: '5,001-10,000',
      min: 5001,
      max: 10000,
    },
    {
      label: '10,001-15,000',
      min: 10001,
      max: 15000,
    },
    {
      label: '15,001-20,000',
      min: 15001,
      max: 20000,
    },
    {
      label: '20,001-25,000',
      min: 20001,
      max: 25000,
    },
    {
      label: '25,001-50,000',
      min: 25001,
      max: 50000,
    },
    {
      label: '50,001 +',
      min: 50001,
      max: 150000,
    },
  ];

  resultOrders = [
    {
      label: 'A-Z',
      isDesc: false,
    },
    {
      label: 'Z-A',
      isDesc: true,
    },
  ];

  constructor(private productCatalogueService: ProductCatalogueService, public fb: FormBuilder) {
    this.onChange = this.onChange.bind(this);
  }

  filtersForm = new FormGroup({
    range: new FormControl('All'),
    resultOrder: new FormControl('A-Z'),
    text: new FormControl(''),
  });

  onChange() {
    const searchProperties = this.productCatalogueService.productSearchContext.getValue();
    const rangeFilter = this.pointsRange.find(i => i.label === this.filtersForm.value.range);
    const sortOrder = this.resultOrders.find(i => i.label === this.filtersForm.value.resultOrder);

    if (rangeFilter) {
      searchProperties.min = rangeFilter.min;
      searchProperties.max = rangeFilter.max;
    } else {
      searchProperties.min = null;
      searchProperties.max = null;
    }

    searchProperties.orderBy = {
      fields: [
        {
          sortBy: 'name',
          sortDirection: sortOrder.isDesc ? 'desc' : 'asc',
        },
      ],
    };

    searchProperties.text = this.filtersForm.value.text;
    this.productCatalogueService.productSearchContext.next(searchProperties);
    this.productCatalogueService.refreshProductList();
  }

  ngOnInit() {
    this.filterSubscription = this.filtersForm.valueChanges
      .pipe(debounceTime(250), distinctUntilChanged())
      .subscribe(this.onChange);
  }

  clearSearch() {
    this.filtersForm = new FormGroup({
      range: new FormControl('All'),
      resultOrder: new FormControl('A-Z'),
      text: new FormControl(''),
    });

    this.productCatalogueService.productSearchContext.value.categoryId = null;
    this.onChange();
    this.filtersForm.valueChanges
      .pipe(debounceTime(250), distinctUntilChanged())
      .subscribe(this.onChange);
  }

  onSubmit() {}

  ngOnDestroy() {
    if (this.filterSubscription) {
      this.filterSubscription.unsubscribe();
    }
  }
}
