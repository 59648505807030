import { Component, OnInit, NgZone, Input } from '@angular/core';
import { Auth } from 'aws-amplify';
import { Router } from '@angular/router';
import { FormControl, FormGroup, Validators } from '@angular/forms';
import { SettingsProvider } from 'src/app/shared/settings.provider';
import { NgxSpinnerService } from 'ngx-bootstrap-spinner';
import { DynamicLayout } from 'src/app/core/models/dynamic-layout.model';
import { Content } from 'src/app/core/models/content.model';
import { AuthenticationService } from '../../services/authentication.service';

@Component({
  selector: 'app-login',
  templateUrl: './login.component.html',
  styleUrls: ['./login.component.scss'],
})
export class LoginComponent implements OnInit, DynamicLayout {
  @Input() baseContent: Content;

  title: string;
  banner: string;
  forgotPasswordUrl: string;
  submitted = false;
  email = '';
  password = '';
  message: string;

  loginForm = new FormGroup({
    email: new FormControl('', [Validators.required, Validators.email]),
    password: new FormControl('', [Validators.required]),
  });

  constructor(
    private authService: AuthenticationService,
    private settingsProvider: SettingsProvider,
    private router: Router,
    private zone: NgZone,
    private spinner: NgxSpinnerService,
  ) {}

  async ngOnInit() {
    const titleField = this.baseContent.fields.find((f) => f.name === 'Title');
    const bannerField = this.baseContent.fields.find((f) => f.name === 'Banner');
    const forgotPasswordField = this.baseContent.fields.find(
      (f) => f.name === 'Forgot Password Url',
    );
    this.title = titleField.value.value;
    this.banner = bannerField.value.url;
    this.forgotPasswordUrl = forgotPasswordField.value.value;

    try {
      const user = await this.authService.currentUserInfo();
      console.log(user);
      if (user) {
        this.authService.signOut();
      }
    } catch (e) {
      console.log(e);
    }
  }

  async loginWithCognito() {
    try {
      this.spinner.show();
      const user = await Auth.signIn(this.email.toString(), this.password.toString());
      const tokens = user.signInUserSession;

      if (tokens != null) {
        this.authService.setLoggedIn();
        const user = await this.authService.currentUserInfo();
        console.log(user);
        if (user) {
          await this.settingsProvider
            .getRemoteUserSettings()
            .then((settings) => {
              if (settings.data.claims.includes('user.norbrook.vet.access')) {
                if (settings.data.user.statusId !== 1) {
                  this.authService.signOut();
                  this.message =
                    'Your registration will be verified within the next 5 days. Once approved, you will receive an email confirming ' +
                    'your account has been activated and you will be able to log in.';
                } else {
                  this.router.navigate(['/vet/home']);
                }
              } else if (settings.data.claims.includes('user.norbrook.manager.access')) {
                if (!settings.data.user.acceptedTermsConditions) {
                  this.router.navigate(['/account-manager/accept-terms']);
                } else {
                  this.router.navigate(['/account-manager/home']);
                }
              } else {
                this.message = 'Incorrect user name or password.';
              }
            })
            .finally(() => {
              this.spinner.hide();
            });
        }
      }
    } catch (error) {
      this.spinner.hide();
      if (error.message === 'User is not confirmed.') {
        this.message =
          'Please check your inbox for your Norbrook Advantage Programme verification' +
          " link email and click on the 'Verify Email' link to validate your email address.";
      } else {
        this.message = error.message;

        if (this.message.includes('http')) {
          this.message = 'There has been a server error. Please, try again later.';
        }
      }

      console.log(error);
      console.log('User Authentication failed');
    }
  }

  async onSubmit() {
    this.submitted = true;

    if (this.loginForm.valid) {
      const formData = this.loginForm.getRawValue();
      this.email = formData.email;
      this.password = formData.password;
      await this.loginWithCognito();
    } else {
      console.log('Form is still invalid.');
    }

    return false;
  }
}
