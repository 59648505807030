import { Component, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { Auth } from 'aws-amplify';
import { AuthenticationService } from '../../services/authentication.service';
import { SettingsProvider } from '../../settings.provider';

@Component({
  selector: 'app-login-redirect',
  templateUrl: './login-redirect.component.html',
  styleUrls: ['./login-redirect.component.scss'],
})
export class LoginRedirectComponent implements OnInit {
  constructor(
    private authService: AuthenticationService,
    private settingsProvider: SettingsProvider,
    private router: Router,
  ) {}

  async ngOnInit() {
    let loggedIn = false;

    try {
      await Auth.currentAuthenticatedUser();
      loggedIn = true;
    } catch (error) {
      console.log(error);
    }

    if (loggedIn) {
      const user = await this.authService.currentUserInfo();

      if (user) {
        await this.settingsProvider
          .getRemoteUserSettings()
          .then((settings) => {
            if (settings.data.claims.includes('user.norbrook.vet.access')) {
              this.router.navigate(['/vet/home']);
            } else if (settings.data.claims.includes('user.norbrook.manager.access')) {
              if (!settings.data.user.acceptedTermsConditions) {
                this.router.navigate(['/account-manager/accept-terms']);
              } else {
                this.router.navigate(['/account-manager/home']);
              }
            }
          })
          .finally();
      }
    } else {
      setTimeout(() => {
        document.location.reload();
      }, 5000);
    }
  }
}
