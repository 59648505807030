<form
  *ngIf="registrationDetailsForm"
  [formGroup]="registrationDetailsForm"
  (ngSubmit)="onSubmit()"
  class="form-standard"
>
  <h4>REGISTER YOUR DETAILS</h4>
  <p><i>Please note, all fields are mandatory unless stated otherwise</i></p>

  <div *ngIf="errorMessages && errorMessages != ''">
    <i>{{ errorMessages }}</i>
  </div>

  <div *ngIf="showErrorMessage(registrationDetailsForm, submitted)">
    <div *ngIf="showErrorMessage(registrationDetailsForm.controls.vetPracticeName, submitted)">
      <div *ngIf="registrationDetailsForm.controls.vetPracticeName.errors.required">
        <i>Vet Practice name is required</i>
      </div>
    </div>
    <div *ngIf="showErrorMessage(registrationDetailsForm.controls.vetPracticePostcode, submitted)">
      <div *ngIf="registrationDetailsForm.controls.vetPracticePostcode.errors.required">
        <i>Vet Practice postcode is required</i>
      </div>
    </div>
    <div *ngIf="showErrorMessage(registrationDetailsForm.controls.firstName, submitted)">
      <div *ngIf="registrationDetailsForm.controls.firstName.errors.required">
        <i>Contact first name is required</i>
      </div>
    </div>
    <div *ngIf="showErrorMessage(registrationDetailsForm.controls.lastName, submitted)">
      <div *ngIf="registrationDetailsForm.controls.lastName.errors.required">
        <i>Contact last name is required</i>
      </div>
    </div>
    <div *ngIf="showErrorMessage(registrationDetailsForm.controls.email, submitted)">
      <div *ngIf="registrationDetailsForm.controls.email.errors.required">
        <i>Valid email address is required</i>
      </div>
    </div>
    <div *ngIf="showErrorMessage(registrationDetailsForm.controls.phoneNumber, submitted)">
      <div *ngIf="registrationDetailsForm.controls.phoneNumber.errors.required">
        <i>Vet Practice phone number is required</i>
      </div>
    </div>
    <div *ngIf="showErrorMessage(registrationDetailsForm.controls.password, submitted)">
      <div *ngIf="registrationDetailsForm.controls.password.errors.required">
        <i>Please enter a valid password</i>
      </div>
      <div *ngIf="registrationDetailsForm.controls.password.errors.pattern">
        <i
          >The password needs to be at least 8 characters long and contain 1 uppercase letter, 1
          lowercase letter, 1 number and 1 special character from the following = + - ^ $ * . [ ]
          &#123; &#125; ( ) ? ! @ # % & / \\ , > \' : ; | _ ~ `.</i
        >
      </div>
    </div>
    <div *ngIf="showErrorMessage(registrationDetailsForm.controls.confirmPassword, submitted)">
      <div *ngIf="registrationDetailsForm.controls.confirmPassword.errors.required">
        <i>Please ensure your passwords match</i>
      </div>
    </div>
    <div *ngIf="showErrorMessage(registrationDetailsForm.controls.acceptedTnCs, submitted)">
      <div *ngIf="registrationDetailsForm.controls.acceptedTnCs.errors.required">
        <i>You must accept the Terms and Conditions</i>
      </div>
    </div>
    <div *ngIf="submitted && confirmPasswordFormControl?.errors" class="invalid-feedback">
      <i>Passwords must match.</i>
    </div>
  </div>

  <div class="row">
    <div class="col-lg-6 col-md-12 col-sm-12 col-xs-12">
      <div class="row">
        <div class="col-lg-6 col-md-6 col-sm-6 col-xs-12">
          <label>VET PRACTICE NAME</label>
        </div>
        <div class="col-lg-6 col-md-6 col-sm-6 col-xs-12">
          <input
            name="vetPracticeName"
            formControlName="vetPracticeName"
            class="form-control text-input"
            type="text"
            placeholder="Name of Vet Practice"
          />
        </div>
      </div>
      <div class="row">
        <div class="col-lg-6 col-md-6 col-sm-6 col-xs-12">
          <label>VET PRACTICE POSTCODE</label>
        </div>
        <div class="col-lg-6 col-md-6 col-sm-6 col-xs-12">
          <input
            formControlName="vetPracticePostcode"
            class="form-control text-input"
            type="text"
            placeholder="Vet Practice postcode"
          />
        </div>
      </div>

      <div class="row">
        <div class="col-lg-6 col-md-6 col-sm-6 col-xs-12">
          <label>CONTACT FIRST NAME</label>
        </div>
        <div class="col-lg-6 col-md-6 col-sm-6 col-xs-12">
          <input
            formControlName="firstName"
            class="form-control text-input"
            type="text"
            placeholder="Key contact first name"
          />
        </div>
      </div>
      <div class="row">
        <div class="col-lg-6 col-md-6 col-sm-6 col-xs-12">
          <label>CONTACT LAST NAME</label>
        </div>
        <div class="col-lg-6 col-md-6 col-sm-6 col-xs-12">
          <input
            formControlName="lastName"
            class="form-control text-input"
            type="text"
            placeholder="Key contact last name"
          />
        </div>
      </div>
      <div class="row">
        <div class="col-lg-6 col-md-6 col-sm-6 col-xs-12">
          <label>PHONE NUMBER</label>
        </div>
        <div class="col-lg-6 col-md-6 col-sm-6 col-xs-12">
          <input
            formControlName="phoneNumber"
            class="form-control text-input"
            type="text"
            placeholder="Vet Practice phone number"
          />
        </div>
      </div>
    </div>

    <div class="col-lg-6 col-md-12 col-sm-12 col-xs-12">
      <div class="row">
        <div class="col-lg-5 col-md-6 col-sm-6 col-xs-12">
          <label>EMAIL</label>
        </div>
        <div class="col-lg-7 col-md-6 col-sm-6 col-xs-12">
          <input
            formControlName="email"
            class="form-control text-input"
            type="text"
            placeholder="Vet Practice email address"
          />
        </div>
      </div>
      <div class="row">
        <div class="col-lg-5 col-md-6 col-sm-6 col-xs-12">
          <label>CONFIRM EMAIL</label>
        </div>
        <div class="col-lg-7 col-md-6 col-sm-6 col-xs-12">
          <input
            formControlName="confirmEmail"
            class="form-control text-input"
            type="text"
            placeholder="Please confirm email address"
          />
        </div>
      </div>
      <div class="row">
        <div class="col-lg-5 col-md-6 col-sm-6 col-xs-12">
          <label>PASSWORD</label>
        </div>
        <div class="col-lg-7 col-md-6 col-sm-6 col-xs-12">
          <input
            formControlName="password"
            type="password"
            class="form-control text-input"
            placeholder="Set your password"
          />
          <div
            *ngIf="submitted && registrationDetailsForm.controls.passwordFormControl?.errors"
            class="invalid-feedback"
          >
            Password is required.
          </div>
        </div>
      </div>

      <div class="row">
        <div class="col-lg-5 col-md-6 col-sm-6 col-xs-12">
          <label>CONFIRM PASSWORD</label>
        </div>
        <div class="col-lg-7 col-md-6 col-sm-6 col-xs-12">
          <input
            formControlName="confirmPassword"
            type="password"
            class="form-control text-input"
            placeholder="Reconfirm your password"
          />
        </div>
      </div>
    </div>
  </div>
  <div class="row checkbox-input">
    <div class="col-md-1">
      <input formControlName="acceptedTnCs" type="checkbox" class="checkbox" />&nbsp;&nbsp;
    </div>
    <div class="col-md-11">
      <label class="checkbox-label"
        >Please tick here to confirm you have read and agree to the
        <a
          href="https://norbrook-assets.s3.eu-west-2.amazonaws.com/Norbrook+Advantage+Programme+T%26Cs_October+2023_FINAL.pdf"
          target="_blank"
          >Terms & Conditions</a
        ></label
      >
    </div>
  </div>
  <div class="row">
    <div class="col-md-12">
      <div class="form-group"><button class="btn btn-primary" type="submit">SUBMIT</button></div>
    </div>
  </div>
</form>
