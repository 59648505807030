import { Injectable } from '@angular/core';
import { BaseCoreService } from 'src/app/core/services/base-core-service';
import { Registration } from '../models/registration';
import { SubmissionResult } from 'src/app/core/models/form/submission-result';
import { ApiRestService } from '@motivforce/mx-library-angular';

@Injectable({
  providedIn: 'root',
})
export class RegistrationService extends BaseCoreService {
  constructor(protected apiRestService: ApiRestService) {
    super('norbrook/registration', apiRestService);
  }

  async registerVet(model: Registration): Promise<any> {
    return await this.apiRestService.post<SubmissionResult>(`${this.memberBaseUrl}/vet`, model).toPromise();
  }

  async registerCatOwner(model: Registration): Promise<any> {
    return await this.apiRestService
      .post<SubmissionResult>(`${this.memberBaseUrl}/cat-owner`, model)
      .toPromise();
  }

  async updateTermsAndConditions(): Promise<any> {
    return await this.apiRestService.post<any>(`${this.memberBaseUrl}/update-terms-and-conditions`, {}).toPromise();
  }
}
