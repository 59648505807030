import { Component, OnInit } from '@angular/core';
import { FormBuilder, FormGroup, Validators, AbstractControl, FormArray } from '@angular/forms';
import { NgxSpinnerService } from 'ngx-bootstrap-spinner';
import { Registration } from 'src/app/shared/models/registration';
import { RegistrationService } from 'src/app/shared/services/registration.service';

@Component({
  selector: 'cat-registration-form',
  templateUrl: './cat-registration-form.component.html',
  styleUrls: ['./cat-registration-form.component.scss'],
})
export class CatRegistrationFormComponent implements OnInit {
  submitted = false;

  catOwnerRegistration: Registration = {
    firstName: '',
    lastName: '',
    email: '',
    confirmEmail: '',
    phoneNumber: '',
    password: '',
  };

  registrationDetailsForm: FormGroup = this.formBuilder.group({
    firstName: [null, [Validators.required, Validators.pattern('^.{2,}$')]],
    lastName: [null, [Validators.required]],
    email: [null, [Validators.required, Validators.email]],
    confirmEmail: [null, [Validators.required, Validators.email]],
    phoneNumber: [null, [Validators.required]],
    password: [
      null,
      [
        Validators.required,
        Validators.pattern('^(?=.{8,})(?=.*[a-z])(?=.*[A-Z])(?=.*[^\\w\\d])(?=.*\\d).*$'),
      ],
    ],
    confirmPassword: [null, [Validators.required]],
    cats: this.formBuilder.array(
      [
        this.formBuilder.group({
          name: [null, Validators.required],
          birthDate: [null, Validators.required],
        }),
      ],
      Validators.required,
    ),
  });

  formInvalidAfterSubmit: boolean;
  errorMessages: string;

  constructor(
    private registrationService: RegistrationService, 
    private formBuilder: FormBuilder,
    private spinner: NgxSpinnerService) { }

  ngOnInit(): void { }

  onSubmit() {
    this.submitted = true;

    if (this.registrationDetailsForm.valid) {
      const formValue = this.registrationDetailsForm.value;

      if (formValue.email !== formValue.confirmEmail) {
        this.errorMessages = 'The email address does not match the confirm email address.';
      } else {
        delete formValue.confirmPassword;

        this.catOwnerRegistration = Object.assign(this.catOwnerRegistration, formValue);

        this.spinner.show();
        this.registrationService
          .registerCatOwner(this.catOwnerRegistration)
          .then((result) => {
            if (result.hasErrors) {              
              this.errorMessages = result.message;
            } else {              
              this.errorMessages =
                'Cat owner is succesfully registered. They will receive an email confirming their registration and then they can log in.';
            }
          })
          .catch((error) => {
            console.log(error);
          })
          .finally(() => this.spinner.hide());
      }
    } else {
      console.log('Form is still invalid.');
    }
    
    return false;
  }

  showErrorMessage(formControl: AbstractControl, submitted: boolean): boolean {
    return (
      formControl && formControl.invalid && (formControl.dirty || formControl.touched || submitted)
    );
  }

  addCat(): void {
    const catsFormArray = this.registrationDetailsForm.controls.cats as FormArray;

    catsFormArray.push(
      this.formBuilder.group({
        name: [null, Validators.required],
        birthDate: [null, Validators.required],
      }),
    );
  }

  removeCat(): void {
    const catsFormArray = this.registrationDetailsForm.controls.cats as FormArray;
    const lastIndex = catsFormArray.length;
    catsFormArray.removeAt(lastIndex - 1);
  }
}
