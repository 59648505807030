import { BaseModel } from 'src/app/core/models/base-model';
import { Product } from './product';

export class CartProduct extends BaseModel {
  quantity: number;
  points: number;
  cartId: number;
  name: string;
  imageUrl: string;
  productId: number;
  currencySymbol: string;
  cost: number;
  isVoucher: boolean;
}
