import { Component, OnDestroy, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { Subscription } from 'rxjs';
import { SettingsProvider } from 'src/app/shared/settings.provider';
import { User } from '../../models/user';
import { AuthenticationService } from '../../services/authentication.service';
import { PointsSummaryService } from '../../services/points-summary.service';
import { ProfileService } from '../../services/profile.service';

@Component({
  selector: 'app-lower-nav',
  templateUrl: './lower-nav.component.html',
  styleUrls: ['./lower-nav.component.scss'],
})
export class LowerNavComponent implements OnInit, OnDestroy {
  user: User;
  catUser: boolean;
  balance: number;
  name: string;
  authenticated: boolean;
  private pointsSummarySubscription: Subscription;

  constructor(
    private authService: AuthenticationService,
    private router: Router,
    private profileService: ProfileService,
    private pointsSummaryService: PointsSummaryService,
    private settingsProvider: SettingsProvider,
  ) {}

  ngOnInit() {
    this.user = new User();
    this.name = '';
    this.balance = 0;
    const currentLocation = window.location.pathname;
    if (currentLocation.startsWith('/account-manager')) {
      this.catUser = true;
    }

    this.authService.checkCurrentUserStatus().then((isLoggedIn) => {
      if (isLoggedIn) {
        this.authService.setLoggedIn();

        this.authenticated = true;
        this.profileService.getProfile().then((result) => {
          if (result.userRoleName === 'manager.main.access') {
            this.catUser = true;
            this.settingsProvider.getRemoteUserSettings().then((settings) => {
              if (!settings.data.user.acceptedTermsConditions) {
                this.authenticated = false;
              }
            });

            this.user.firstName = result.firstName;
            this.user.lastName = result.lastName;
            this.name = this.user.firstName + ' ' + this.user.lastName;
          } else {
            this.name = this.user.companyName = result.companyName;
          }

          this.pointsSummarySubscription = this.pointsSummaryService
            .getPointsSummary()
            .subscribe((points) => {
              this.balance = points.available;
            });
        });
      }
    });
  }

  ngOnDestroy() {
    if (this.pointsSummarySubscription) {
      this.pointsSummarySubscription.unsubscribe();
    }
  }

  logoutUser() {
    this.authService.signOut().then(() => {
      this.router.navigate(['/']);
    });
  }
}
