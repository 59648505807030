<div class="component-row">
  <form
    *ngIf="searchParamsForm"
    [formGroup]="searchParamsForm"
    (ngSubmit)="onSubmit()"
    class="form-standard"
  >
    <div *ngIf="errorMessages && errorMessages != ''">
      <i>{{ errorMessages }}</i>
    </div>
    <div *ngIf="searchParamsForm.invalid && (searchParamsForm.dirty || searchParamsForm.touched)">
      <div *ngIf="email && email.invalid && (email.dirty || email.touched)">
        <div *ngIf="email.errors.email"><i>Valid email address must be entered.</i></div>
      </div>
    </div>
    <div class="row">
      <div class="col-sm-6">
        <div class="row">
          <div class="col-lg-6 col-md-6 col-sm-6 col-xs-12">
            <label>First Name</label>
          </div>
          <div class="col-lg-6 col-md-6 col-sm-6 col-xs-12">
            <input
              name="firstName"
              formControlName="firstName"
              class="form-control text-input"
              type="text"
              placeholder="First Name"
            />
          </div>
        </div>
      </div>
      <div class="col-sm-6">
        <div class="row">
          <div class="col-lg-6 col-md-6 col-sm-6 col-xs-12">
            <label>Last Name</label>
          </div>
          <div class="col-lg-6 col-md-6 col-sm-6 col-xs-12">
            <input
              name="lastName"
              formControlName="lastName"
              class="form-control text-input"
              type="text"
              placeholder="Last Name"
            />
          </div>
        </div>
      </div>
    </div>
    <div class="row">
      <div class="col-sm-6">
        <div class="row">
          <div class="col-lg-6 col-md-6 col-sm-6 col-xs-12">
            <label>Email Address</label>
          </div>
          <div class="col-lg-6 col-md-6 col-sm-6 col-xs-12">
            <input
              name="email"
              formControlName="email"
              class="form-control text-input"
              type="email"
              placeholder="Email Address"
            />
          </div>
        </div>
      </div>
      <div class="col-sm-6">
        <div class="row">
          <div class="col-lg-6 col-md-6 col-sm-6 col-xs-12">
            <label>Phone Number</label>
          </div>
          <div class="col-lg-6 col-md-6 col-sm-6 col-xs-12">
            <input
              name="phone"
              formControlName="phone"
              class="form-control text-input"
              type="text"
              placeholder="Phone Number"
            />
          </div>
        </div>
      </div>
    </div>
    <div class="row">
      <div class="col-sm-6">
        <div class="row">
          <div class="col-lg-6 col-md-6 col-sm-6 col-xs-12">
            <label>Membership Number</label>
          </div>
          <div class="col-lg-6 col-md-6 col-sm-6 col-xs-12">
            <input
              name="memberNumber"
              formControlName="memberNumber"
              class="form-control text-input"
              type="number"
              placeholder="Membership Number"
            />
          </div>
        </div>
      </div>
      <div class="col-sm-6 btn-col">
        <div class="form-group">
          <button class="btn btn-primary" type="submit">SEARCH</button>
          <button class="btn btn-info" type="button" (click)="resetSearch()">RESET</button>
        </div>
      </div>
    </div>
  </form>
  <!-- <vet-member-search-results></vet-member-search-results> -->
</div>
