<div>
    <app-header heading-copy="# {{title}}" top-banner-url="{{banner}}"></app-header>
    <div class="container">
        <div class="row">
            <div class="col-sm-12">
                <p>&nbsp;</p>
            </div>
        </div>
            <div class="col-md-12">
                <div class="row mt-20">
                    <div class="col-lg-3 col-md-3 col-sm-6 col-xs-12">                          
                        <pc-category-list></pc-category-list>
                        <div class="col-lg-12 col-md-12 col-sm-12 col-xs-12">
                            <div class="row">
                                <a routerLink="/vet/rewards/cart" class="btn btn-primary cart-button mt-25 full-width">VIEW CART</a>
                            </div>
                        </div>
                    </div>
                    <div class="col-lg-9 col-md-9 col-sm-6 col-xs-12">
                        <pc-product-list></pc-product-list>
                    </div>
                </div>

            </div>

        <app-footer></app-footer>
    </div>
</div>