import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { MemberManagerModule } from './components/member-manager/member-manager.module';
import { ProductCatalogueModule } from './components/product-catalogue/product-catalogue.module';
import { MemberManagerPageComponent } from './components/member-manager-page/member-manager-page.component';
import { CartPageComponent } from './components/cart-page/cart-page.component';
import { CatRegistrationPageComponent } from './components/cat-owner-registration/registration-page/cat-registration-page.component';
import { CataloguePageComponent } from './components/catalogue-page/catalogue-page.component';
import { CheckoutDetailsPageComponent } from './components/checkout-details-page/checkout-details-page.component';
import { VetHomeComponent } from './components/home/vet-home.component';
import { RedemptionCompletePageComponent } from './components/redemption-complete-page/redemption-complete-page.component';
import { VetPointsSummaryComponent } from './components/vet-points-summary/vet-points-summary.component';
import { VetRegistrationFormComponent } from './components/vet-registration/registration-form/vet-registration-form.component';
import { VetRegistrationPageComponent } from './components/vet-registration/registration-page/vet-registration-page.component';
import { VetRegistrationSuccessComponent } from './components/vet-registration/registration-success/registration-success.component';
import { SharedModule } from '../shared/shared.module';
import { RouterModule } from '@angular/router';
import { AmplifyAngularModule } from 'aws-amplify-angular';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { ContentLoaderModule } from '@netbasal/ngx-content-loader';
import { CatRegistrationFormComponent } from './components/cat-owner-registration/registration-form/cat-registration-form.component';
import { InfiniteScrollModule } from 'ngx-infinite-scroll';
import { MemberSummaryComponent } from './components/member-summary/member-summary.component';

@NgModule({
  declarations: [
    MemberManagerPageComponent,
    CartPageComponent,
    CatRegistrationFormComponent,
    CatRegistrationPageComponent,
    CataloguePageComponent,
    CheckoutDetailsPageComponent,
    VetHomeComponent,
    RedemptionCompletePageComponent,
    MemberSummaryComponent,
    VetPointsSummaryComponent,
    VetRegistrationFormComponent,
    VetRegistrationPageComponent,
    VetRegistrationSuccessComponent,
  ],
  imports: [
    MemberManagerModule,
    ProductCatalogueModule,
    SharedModule,
    RouterModule,
    AmplifyAngularModule,
    FormsModule,
    ReactiveFormsModule,
    ContentLoaderModule,
    CommonModule,
    InfiniteScrollModule
  ]
})
export class VetModule { }
