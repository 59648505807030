<div class="component-row">
    <div class="row">
        <div class="col-sm-12">
            <h3>Add T4 Results</h3>
        </div>
        <div class="col-sm-12">
            <div *ngIf="addTestMessage && addTestMessage != ''">
                <i>{{ addTestMessage }}</i>
            </div>
        </div>
    </div>
    <form [formGroup]="testResultForm" (ngSubmit)="onSubmit()" class="form-standard">

        <div class="row">
            <div class="col-sm-6">
                <div class="row">
                    <div class="col-sm-6">
                        <label>Choose Cat</label>
                    </div>
                    <div class="col-sm-6">
                        <select class="form-control" (change)="changeCat($event)">
                            <option *ngFor="let option of cats" [value]="option.catId">
                                <span *ngIf="option.catId > 0">{{
                                option.name
                            }}</span></option>
                        </select>
                    </div>
                </div>
            </div>
            <div class="col-sm-6">
                <div class="row">
                    <div class="col-sm-6">
                        <label>Date of Test</label>
                    </div>
                    <div class="col-lg-6 col-md-6 col-sm-6 col-xs-12">
                        <input formControlName="dateOfTest" class="form-control text-input" type="date" />
                    </div>
                </div>
            </div>
        </div>
        <div class="row">
            <div class="col-sm-6">
                <div class="row">
                    <div class="col-sm-6">
                        <label>T4 Test Result</label>
                    </div>
                    <div class="col-sm-6">
                        <input formControlName="testResult" class="form-control text-input" type="text" />
                    </div>
                </div>
            </div>
            <div class="col-sm-3">
                <div class="row checkbox-input">
                    <div class="col-md-12">
                        <input formControlName="redeemPoints" type="checkbox" class="checkbox" />&nbsp;&nbsp;<label
                            class="checkbox-label">Redeem Points for Test</label>
                    </div>
                    <div class="col-md-11">

                    </div>
                </div>
            </div>
            <div class="col-sm-3">
                <button type="submit" class="btn btn-primary">SUBMIT</button>
            </div>

        </div>
    </form>
</div>