import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { ModalBasicComponent } from './ui/modal-basic/modal-basic.component';
import { HtmlContentComponent } from './content/html-content/html-content.component';
import { GraphQLModule } from './graphql.module';
import { DialogModule } from '@motivforce/mx-library-angular';

@NgModule({
  declarations: [ModalBasicComponent, HtmlContentComponent],
  imports: [CommonModule, GraphQLModule, DialogModule],
})
export class CoreModule {}
