import { Component, Input, OnInit } from '@angular/core';
import { PointsSummary } from 'src/app/shared/models/points-summary';

@Component({
  selector: 'transfers-made-box',
  templateUrl: './transfers-made-box.component.html',
  styleUrls: ['./transfers-made-box.component.scss'],
})
export class TransfersMadeBoxComponent implements OnInit {
  // tslint:disable-next-line: variable-name
  private _pointsSummary: PointsSummary;

  get pointsSummary() {
    return this._pointsSummary;
  }

  @Input()
  set pointsSummary(x: PointsSummary) {
    this._pointsSummary = x;
    this.calculateRemainingPoints();
  }

  pointsNeededToNextRedemption: number;
  enoughPointsToRedeem: boolean;

  constructor() {}

  ngOnInit(): void {}

  calculateRemainingPoints() {
    if (this.pointsSummary && this.pointsSummary.available < 100) {
      this.pointsNeededToNextRedemption = 100 - this.pointsSummary.available;
    } else if (this.pointsSummary && this.pointsSummary.available >= 100) {
      this.enoughPointsToRedeem = true;
    }
  }
}
