<form *ngIf="registrationDetailsForm" [formGroup]="registrationDetailsForm"  (ngSubmit)="onSubmit()" onsubmit="return false"
  class="form-standard" [appMatchValue]="['password', 'confirmPassword']">
  <h4>REGISTER CAT OWNER DETAILS</h4>
  <p><i>Please note, all fields are mandatory unless stated otherwise</i></p>

  <div *ngIf="errorMessages && errorMessages != ''">
    <i>{{ errorMessages }}</i>
  </div>

  <div *ngIf="showErrorMessage(registrationDetailsForm, submitted)"  class="errorMessage">
    <div *ngIf="showErrorMessage(registrationDetailsForm.controls.firstName, submitted)">
      <div *ngIf="registrationDetailsForm.controls.firstName.errors.required">
        <i>First name is required</i>
      </div>
      <div *ngIf="registrationDetailsForm.controls.firstName.errors.pattern">
        <i>Full first name is required</i>
      </div>
    </div>
    <div *ngIf="showErrorMessage(registrationDetailsForm.controls.lastName, submitted)">
      <div *ngIf="registrationDetailsForm.controls.lastName.errors.required">
        <i>Last name is required</i>
      </div>
    </div>
    <div *ngIf="showErrorMessage(registrationDetailsForm.controls.email, submitted)">
      <div *ngIf="
          registrationDetailsForm.controls.email.errors.required ||
          registrationDetailsForm.controls.email.errors.email
        ">
        <i>Valid email address is required</i>
      </div>
    </div>
    <div *ngIf="showErrorMessage(registrationDetailsForm.controls.phoneNumber, submitted)">
      <div *ngIf="registrationDetailsForm.controls.phoneNumber.errors.required">
        <i>Phone number is required</i>
      </div>
    </div>
    <div *ngIf="showErrorMessage(registrationDetailsForm.controls.password, submitted)">
      <div *ngIf="registrationDetailsForm.controls.password.errors.required">
        <i>Please enter a valid password</i>
      </div>
      <div *ngIf="registrationDetailsForm.controls.password.errors.pattern">
        <i>The password needs to be at least 8 characters long and contain 1 uppercase letter, 1
          lowercase letter, 1 number and 1 special character from the following = + - ^ $ * . [ ]
          &#123; &#125; ( ) ? ! @ # % & / \\ , > \' : ; | _ ~ `.</i>
      </div>
    </div>
    <div *ngIf="showErrorMessage(registrationDetailsForm.controls.confirmPassword, submitted)">
      <div *ngIf="registrationDetailsForm.controls.confirmPassword.errors.required">
        <i>Confirm password is required</i>
      </div>
      <div *ngIf="registrationDetailsForm.controls.confirmPassword.errors.matchValueError">
        <i>Confirm password & password must be same.</i>
      </div>
    </div>
    <div *ngIf="showErrorMessage(registrationDetailsForm.controls.cats, submitted)">
      <i>Cat name and date of birth are required</i>
    </div>

  </div>

  <div class="row">
    <div class="col-lg-6 col-md-12 col-sm-12 col-xs-12">
      <div class="row">
        <div class="col-lg-5 col-md-6 col-sm-6 col-xs-12">
          <label>FIRST NAME</label>
        </div>
        <div class="col-lg-7 col-md-6 col-sm-6 col-xs-12">
          <input formControlName="firstName" class="form-control text-input" type="text" placeholder="Full first name" />
        </div>
      </div>
      <div class="row">
        <div class="col-lg-5 col-md-6 col-sm-6 col-xs-12">
          <label>LAST NAME</label>
        </div>
        <div class="col-lg-7 col-md-6 col-sm-6 col-xs-12">
          <input formControlName="lastName" class="form-control text-input" type="text" placeholder="Full last name" />
        </div>
      </div>      
      <div class="row">
        <div class="col-lg-5 col-md-6 col-sm-6 col-xs-12">
          <label>PHONE NUMBER</label>
        </div>
        <div class="col-lg-7 col-md-6 col-sm-6 col-xs-12">
          <input formControlName="phoneNumber" class="form-control text-input" type="text" placeholder="Phone number" />
        </div>
      </div>
    </div>

    <div class="col-lg-6 col-md-12 col-sm-12 col-xs-12">
      <div class="row">
        <div class="col-lg-5 col-md-6 col-sm-6 col-xs-12">
          <label>EMAIL</label>
        </div>
        <div class="col-lg-7 col-md-6 col-sm-6 col-xs-12">
          <input formControlName="email" class="form-control text-input" type="text" placeholder="Email address" />
        </div>
      </div>
      <div class="row">
        <div class="col-lg-5 col-md-6 col-sm-6 col-xs-12">
          <label>CONFIRM EMAIL</label>
        </div>
        <div class="col-lg-7 col-md-6 col-sm-6 col-xs-12">
          <input formControlName="confirmEmail" class="form-control text-input" type="text" placeholder="Please confirm email address" />
        </div>
      </div>
      <div class="row">
        <div class="col-lg-5 col-md-6 col-sm-6 col-xs-12">
          <label>PASSWORD</label>&nbsp;
        </div>
        <div class="col-lg-7 col-md-6 col-sm-6 col-xs-12">
          <input formControlName="password" type="password" class="form-control text-input"
            placeholder="Set password" />
          <div *ngIf="submitted && registrationDetailsForm.controls.password?.errors" class="invalid-feedback">
            Password is required.
          </div>
        </div>
      </div>

      <div class="row">
        <div class="col-lg-5 col-md-6 col-sm-6 col-xs-12">
          <label>CONFIRM PASSWORD</label>
        </div>
        <div class="col-lg-7 col-md-6 col-sm-6 col-xs-12">
          <input formControlName="confirmPassword" type="password" class="form-control text-input"
            placeholder="Reconfirm password" />
        </div>
      </div>
    </div>
  </div>
<hr/>
  <div class="row" *ngFor="let catFormGroup of registrationDetailsForm.controls.cats.controls; let i = index"
    formArrayName="cats">
    <div class="col-lg-6 col-md-12 col-sm-12 col-xs-12">
      <div class="row">
        <div class="col-lg-5 col-md-6 col-sm-6 col-xs-12">
          <label>CAT NAME</label>
        </div>
        <div class="col-lg-7 col-md-6 col-sm-6 col-xs-12">
          <input [formControl]="catFormGroup.controls.name" class="form-control text-input" placeholder="Cat name" />
        </div>
      </div>
    </div>

    <div class="col-lg-6 col-md-12 col-sm-12 col-xs-12">
      <div class="row">
        <div class="col-lg-5 col-md-6 col-sm-6 col-xs-12">
          <label>CAT DATE OF BIRTH</label>
        </div>
        <div class="col-lg-7 col-md-6 col-sm-6 col-xs-12">
          <input [formControl]="catFormGroup.controls.birthDate" class="form-control text-input"
            placeholder="Cat date of birth" type="date" />
        </div>
      </div>
    </div>
  </div>
  <div class="row">
    <div class="col-md-12">
      <div class="form-group">
        <div class="row">
          <div class="col-md-3">
            <button class="btn btn-primary" type="button" (click)="addCat()">ADD ANOTHER CAT</button>
          </div>
          <div class="col-md-3">
            <span *ngIf="registrationDetailsForm.controls.cats.controls.length > 1"><button class="btn btn-primary"
                type="button" (click)="removeCat()">REMOVE CAT</button></span>
          </div>
        </div>
      </div>
    </div>
  </div>
  <div class="row checkbox-input">

    <div class="col-md-12">
      <label class="checkbox-label">Your client will be able to read and accept the Terms & Conditions of participation
        upon their first log in
        to the Norbrook Advantage Programme website.</label>
    </div>
  </div>
  <div class="row">
    <div class="col-md-12">
      <div class="form-group"><button class="btn btn-primary" type="submit">SUBMIT</button></div>
    </div>
  </div>
</form>