<div class="article-detail-page">
  <app-header heading-copy="# {{title}}" top-banner-url="{{banner}}"></app-header>
  <div class="container">
    <div class="article-content">
      <div class="row">
        <div class="col-md-12">
          <div [innerHtml]="body"></div>
        </div>
        <div class="col-md-12 text-center video" *ngIf="video">
          <video controls>
            <source src="{{video}}" type="video/mp4">
          </video>
        </div>
        <div class="col-md-12 text-center" *ngIf="image">
          <img src="{{image}}" />
        </div>
        <div class="col-md-12">
          <a href="{{backUrl}}" class="btn btn-primary">BACK TO PREVIOUS PAGE</a>
        </div>
      </div>
    </div>
    <app-footer></app-footer>
  </div>
</div>