import { Component, OnInit, ViewChild, ComponentFactoryResolver, OnDestroy } from '@angular/core';
import { SettingsProvider } from 'src/app/shared/settings.provider';
import { Router, NavigationEnd } from '@angular/router';
import { Subscription } from 'rxjs';
import mapper from 'src/app/component-mapper';
import { LayoutHostDirective } from '../../directives/layout-host.directive';
import { Content } from 'src/app/core/models/content.model';
import { Settings } from 'src/app/core/models/settings';
import { ContentService } from 'src/app/core/content/content.service';
import { DynamicLayout } from 'src/app/core/models/dynamic-layout.model';

@Component({
  selector: 'app-dynamic-layout',
  templateUrl: './dynamic-layout.component.html',
  styleUrls: ['./dynamic-layout.component.scss'],
})
export class DynamicLayoutComponent implements OnInit, OnDestroy {
  componentMap = mapper;
  @ViewChild(LayoutHostDirective, { static: true }) layoutHost: LayoutHostDirective;

  content: Content;
  settings: Settings;
  componentRef: any;
  adminFilter: any;
  routerEventsSubscription: Subscription;

  constructor(
    private contentService: ContentService,
    private componentFactoryResolver: ComponentFactoryResolver,
    private router: Router,
    settingsProvider: SettingsProvider,
  ) {
    this.settings = settingsProvider.settings;
  }

  ngOnInit() {
    this.routerEventsSubscription = this.router.events.subscribe((ev) => {
      if (ev instanceof NavigationEnd) {
        console.log(window.location.href);
        this.resetContent();
      }
    });
    this.getContent();
  }

  ngOnDestroy() {
    if (this.routerEventsSubscription) {
      this.routerEventsSubscription.unsubscribe();
    }
  }

  resetContent() {
    const viewContainerRef = this.layoutHost.viewContainerRef;

    viewContainerRef.clear();
    this.componentRef = null;

    this.getContent();
  }

  getContent() {
    console.log(window.location.href);
    const language = this.settings.language;

    // TODO: should we always get all children?
    this.contentService
      .getContentByUrlPath(window.location.href, language, false)
      .subscribe((response) => {
        this.content = response.data.contentByUrlPath;
        console.log('calling load component');
        this.loadComponent();
      });
  }

  loadComponent(): void {
    console.log('loadComponent');
    console.log(this.content);
    const component = this.content.renderComponent || 'default';
    console.log(component);
    console.log(this.componentMap);
    console.log(this.componentMap[component]);

    const componentFactory = this.componentFactoryResolver.resolveComponentFactory(
      this.componentMap[component],
    );

    const viewContainerRef = this.layoutHost.viewContainerRef;

    this.componentRef = viewContainerRef.createComponent(componentFactory);
    (this.componentRef.instance as DynamicLayout).baseContent = this.content;
  }
}
