<div class="vet-member-summary">
    <app-header heading-copy="# {{title}}" top-banner-url="{{banner}}" class="no-print"></app-header>
    <div class="container">
        <div *ngIf="visibleMemberSummary">
            <div class="row">
                <div class="col-sm-12">                    
                    <div class="row">
                        <div class="col-lg-12 col-md-12 col-sm-12 no-print">
                            <div class="member-summary-search">
                                <div class="row">
                                    <div class="col-md-6">
                                        <label>SEARCH TEST DUE DATES BETWEEN:</label>
                                    </div>
                                    <div class="col-md-6">
                                        <label>SEARCH PRESCRIPTION DUE DATES BETWEEN:</label>
                                    </div>
                                </div>
                                <div class="row">
                                    <div class="col-lg-6 col-md-6 col-sm-12 col-xs-12 filter-container">
                                    <form *ngIf="searchByT4TestForm" [formGroup]="searchByT4TestForm" (ngSubmit)="searchByT4Test()"
                                    onsubmit="return false" class="form-standard">                                
                                        <div class="form-group">                                                                                        
                                            <div class="input-group">
                                                <input
                                                    type="date"
                                                    formControlName="searchByT4TestFrom"
                                                    class="form-control search-control input-box from-box"
                                                    placeholder="Search"
                                                />     
                                                <input
                                                    type="date"
                                                    formControlName="searchByT4TestTo"
                                                    class="form-control search-control input-box to-box"
                                                    placeholder="Search"
                                                />    
                                                <div class="input-group-append search-control">
                                                    <button type="submit" class="btn-filter">
                                                    <span class="input-group-text search-control">
                                                        <i class="fas fa-search"></i>
                                                    </span>
                                                    </button>
                                                </div> 
                                            </div>                                              
                                            <label class="text-right" (click)="cleart4TestDates()"><small><strong>Clear Date Search</strong></small></label>
                                        </div>
                                    </form>
                                </div>  
                                <div class="col-lg-6 col-md-6 col-sm-12 col-xs-12 filter-container">
                                    <form *ngIf="searchByPrescriptionForm" [formGroup]="searchByPrescriptionForm" (ngSubmit)="searchByPrescription()"
                                        onsubmit="return false" class="form-standard">    
                                        <div class="form-group">                                                                                        
                                            <div class="input-group">
                                                <input
                                                    type="date"
                                                    formControlName="searchByPrescriptionFrom"
                                                    class="form-control search-control input-box from-box"
                                                    placeholder="Search"
                                                />     
                                                <input
                                                    type="date"
                                                    formControlName="searchByPrescriptionTo"
                                                    class="form-control search-control input-box to-box"
                                                    placeholder="Search"
                                                />    
                                                <div class="input-group-append search-control">
                                                    <button type="submit" class="btn-filter">
                                                    <span class="input-group-text search-control">
                                                        <i class="fas fa-search"></i>
                                                    </span>
                                                    </button>
                                                </div> 
                                            </div>                                              
                                            <label class="text-right" (click)="clearPrescriptionDates()"><small><strong>Clear Date Search</strong></small></label>
                                        </div>
                                    </form>   
                                </div>                            
                            </div>
                        </div>                       
                    </div>
                    <div class="no-print">
                        <div class="col-lg-12 col-md-12 col-sm-12 col-xs-12">
                            <button type="submit" class="btn btn-primary" (click)="printMemberDetails()">PRINT RESULTS</button>
                        </div>
                    </div>
                    <div class="col-lg-12 col-md-12 col-sm-12 col-xs-12 no-print">
                        <hr/>
                    </div>
                    <div class="col-lg-12 col-md-12 col-sm-12 col-xs-12">
                        <h3>MEMBER DETAILS</h3>
                    </div>     
                    <table class="table">
                        <thead>
                            <tr>
                                <th>MEMBER ID #</th>
                                <th>NAME</th>
                                <th>EMAIL ADDRESS</th>
                                <th>PHONE NUMBER</th>
                                <th>CAT NAME</th>
                                <th>NEXT TEST DUE <i class="fas fa-arrow-up" (click)="sortByTestDueDateAsc()"></i> <i class="fas fa-arrow-down" (click)="sortByTestDueDateDesc()"></i></th>
                                <th>PRESCRIPTION DUE <i class="fas fa-arrow-up" (click)="sortByPrescriptionDueDateAsc()"></i> <i class="fas fa-arrow-down" (click)="sortByPrescriptionDueDateDesc()"></i></th>
                            </tr>
                        </thead>
                        <tbody *ngIf="visibleMemberSummary.length > 0">
                            <tr *ngFor="let member of visibleMemberSummary">
                                    <td>{{ member.memberId }}</td>
                                    <td>{{ member.memberName }}</td>
                                    <td>{{ member.email }}</td>
                                    <td>{{ member.phone }}</td>
                                    <td>{{ member.catName }}</td>
                                    <td>{{ member.t4TestDueDate | date: 'dd MMMM yyyy' }}</td>
                                    <td>{{ member.prescriptionDueDate | date: 'dd MMMM yyyy' }}</td>
                                
                            </tr>
                        </tbody>
                        <tbody *ngIf="visibleMemberSummary.length === 0">
                            <tr>
                                <td colspan="7" class="text-center">
                                    No members found
                                </td>
                            </tr>
                        </tbody>
                    </table>
                </div>
            </div>
        </div>
        <app-footer class="no-print"></app-footer>
    </div>
</div>
