import { Component, Input, OnInit } from '@angular/core';
import { FormBuilder, Validators } from '@angular/forms';
import { Router } from '@angular/router';
import { Auth } from 'aws-amplify';
import { NgxSpinnerService } from 'ngx-bootstrap-spinner';
import { Content } from 'src/app/core/models/content.model';
import { DynamicLayout } from 'src/app/core/models/dynamic-layout.model';
import { ValidatorsUtil } from 'src/app/core/utils/validators.util';

@Component({
  selector: 'app-reset-password',
  templateUrl: './reset-password.component.html',
  styleUrls: ['./reset-password.component.scss']
})
export class ResetPasswordComponent implements OnInit, DynamicLayout {
  @Input()
  baseContent: Content;

  verificationSent = false;
  message: string;
  title: string;
  banner: string;
  body: string;
  loginUrl: string;

  formGroup = this.formBuilder.group({
    email: [null, [Validators.required, Validators.email]],
    verificationCode: [
      null,
      [ValidatorsUtil.conditionalValidator(() => this.verificationSent, Validators.required)],
    ],
    newPassword: [
      null,
      [ValidatorsUtil.conditionalValidator(() => this.verificationSent, Validators.required)],
    ],
    confirmedPassword: [
      null,
      [ValidatorsUtil.conditionalValidator(() => this.verificationSent, Validators.required)],
    ],
  });

  constructor(
    private formBuilder: FormBuilder,
    private router: Router,
    private spinner: NgxSpinnerService
  ) { }

  ngOnInit(): void {
    const titleField = this.baseContent.fields.find(f => f.name === 'Title');
    const bannerField = this.baseContent.fields.find(f => f.name === 'Banner');
    const body = this.baseContent.fields.find(f => f.name === 'Body');
    const loginUrl = this.baseContent.fields.find(f => f.name === 'Login Url');
    this.title = titleField.value.value;
    this.banner = bannerField.value.url;
    this.body = body.value.value;
    this.loginUrl = loginUrl.value.value;
  }

  onSubmit(): void {
    
    if (this.formGroup.valid) {
      const formValue = this.formGroup.value;
      this.spinner.show();
      if (!this.verificationSent) {
        Auth.forgotPassword(formValue.email)
          .then((data) => {
            this.verificationSent = true;
            this.formGroup.updateValueAndValidity();
          })
          .catch((error) => {
            this.message = 'There has been an error requesting your password reset.';
            console.log('Auth.forgotPassword() ERROR:', error);
          })
          .finally(() => {
            this.spinner.hide();
          });
      } else {
        if (formValue.newPassword === formValue.confirmedPassword) {
          Auth.forgotPasswordSubmit(
            formValue.email,
            formValue.verificationCode,
            formValue.newPassword,
          )
            .then((data) => {
              console.log('Auth.forgotPasswordSubmit() SUCCESS:', data);
              this.message = 'Your password has successfully been reset.';
              this.router.navigate([this.loginUrl]);
            })
            .catch((error) => {
              this.message = 'We have been unable to reset your password. Please make '
              + 'sure the password needs to be at least 8 characters long and contain 1 uppercase letter, 1 lowercase letter, '
              + '1 number and 1 special character from the following = + - ^ $ * . [ ] { } ( ) ? ! @ # % & / \\ , > \' : ; | _ ~ `.';
              console.log('Auth.forgotPasswordSubmit() ERROR:', error);
            })
            .finally(() => {
              this.spinner.hide();
            });
        } else {
          this.message = 'The passwords entered do not match.';
          console.log('Passwords do not match.');
        }
      }
    } else {
      this.message = 'The entered details are invalid.';
      console.log('The form is invalid.');
    }
  }
}
