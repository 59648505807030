import { Component, OnInit, Input } from '@angular/core';
import { CatDetails } from '../../models/vet-practice-details';

@Component({
  selector: 'app-test-results',
  templateUrl: './test-results.component.html',
  styleUrls: ['./test-results.component.scss'],
})
export class TestResultsComponent implements OnInit {
  @Input() catDetails: CatDetails[];

  constructor() {}

  ngOnInit(): void {}
}
