<div class="row align-items-center text-center">
  <div class="col-sm-2">
    <div class="cart-list-img">
      <img *ngIf="cartProduct.imageUrl" class="card-img-top" [src]="cartProduct.imageUrl" />
    </div>
  </div>
  <div class="col-sm-4 cart-list-description">
    <a routerLink="/vet/rewards/product/{{cartProduct.productId}}">
      <span *ngIf="cartProduct.isVoucher">
        {{cartProduct.currencySymbol}}{{cartProduct.cost}} {{ cartProduct.name }}
      </span>
      <span *ngIf="!cartProduct.isVoucher">{{ cartProduct.name }}</span>
      </a>

  </div>
  <div class="col-sm-2">
    <div class="cart-item-qty-outer cart-item-qty-after">
      <input type="text" name="quantity" id="quantity" [(ngModel)]="cartProduct.quantity" [disabled]="updateDisabled" />
    </div>
    <div class="row" *ngIf="!updateDisabled">
      <div class="col-sm-12">
        <input type="button" value="UPDATE" class="cart-buttons" (click)="updateQuantity()" />
        <input type="button" value="REMOVE" class="cart-buttons" (click)="removeProduct()" />
      </div>
    </div>
  </div>
  <div class="col-md-2">{{ cartProduct.points }}</div>
  <div class="col-md-2">{{ cartProduct.points * cartProduct.quantity }}</div>
</div>