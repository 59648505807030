import { BrowserModule } from '@angular/platform-browser';
import { NgModule, APP_INITIALIZER, CUSTOM_ELEMENTS_SCHEMA } from '@angular/core';
import { HTTP_INTERCEPTORS } from '@angular/common/http';
import { AppRoutingModule } from './app-routing.module';
import { AppComponent } from './app.component';
import { AmplifyAngularModule, AmplifyService } from 'aws-amplify-angular';

// TODO: remove the in memory data (JUST FOR DEMO);
import { HttpClientInMemoryWebApiModule } from 'angular-in-memory-web-api';
import { HttpClientModule, HttpClient } from '@angular/common/http';
import { SettingsProvider } from './shared/settings.provider';
import { NgxSpinnerModule } from 'ngx-bootstrap-spinner';
import { FormsModule } from '@angular/forms';
import { ReactiveFormsModule } from '@angular/forms';
import { Apollo } from 'apollo-angular';
import { HttpLinkModule } from 'apollo-angular-link-http';
import { NgbModule } from '@ng-bootstrap/ng-bootstrap';
import { TranslateModule, TranslateLoader } from '@ngx-translate/core';
import { M8TranslationLoader } from './core/content/translation/m8-translation-loader';
import { CoreModule } from './core/core.module';
import { InMemoryDataService } from './core/services/in-memory-data.service';
import { ContentService } from './core/content/content.service';
import { CoreTokenInterceptor } from './core/interceptors/core-token.interceptor';
import { LayoutModule } from './core/layout/layout.module';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import { CommonModule } from '@angular/common';
import { AuthenticationModule } from './core/authentication.module';
import { environment } from 'src/environments/environment';
import { MxLibraryAngularModule } from '@motivforce/mx-library-angular';

export function M8TranslationLoaderFactory(apollo: Apollo, settingsProvider: SettingsProvider) {
  return new M8TranslationLoader(apollo, settingsProvider);
}

export function settingsProviderFactory(settingsProvider: SettingsProvider) {
  return () => settingsProvider.init();
}

@NgModule({
  declarations: [AppComponent],
  imports: [
    CommonModule,
    CoreModule,
    NgbModule,
    BrowserAnimationsModule,
    LayoutModule,
    BrowserModule,
    AppRoutingModule,
    AmplifyAngularModule,
    NgxSpinnerModule,
    HttpClientModule,
    AuthenticationModule,
    FormsModule,
    ReactiveFormsModule,
    HttpLinkModule,
    AuthenticationModule,
    MxLibraryAngularModule.forRoot({ environment: environment }),
    TranslateModule.forRoot({
      loader: {
        provide: TranslateLoader,
        useFactory: M8TranslationLoaderFactory,
        deps: [Apollo, SettingsProvider],
      },
    }),
    HttpClientInMemoryWebApiModule.forRoot(InMemoryDataService, {
      passThruUnknownUrl: true,
      dataEncapsulation: false,
      delay: 1000,
    }),
  ],
  schemas: [CUSTOM_ELEMENTS_SCHEMA],
  providers: [
    AmplifyService,
    ContentService,
    SettingsProvider,
    {
      provide: APP_INITIALIZER,
      useFactory: settingsProviderFactory,
      deps: [SettingsProvider],
      multi: true,
    },
    {
      provide: HTTP_INTERCEPTORS,
      useClass: CoreTokenInterceptor,
      multi: true,
    },
  ],
  bootstrap: [AppComponent],
})
export class AppModule {}
