import { Component, OnInit } from '@angular/core';
import { BehaviorSubject } from 'rxjs';
import { ProductCategory } from 'src/app/vet/models/redemptions/product-category';
import { ProductCatalogueService } from 'src/app/vet/services/product-catalogue.service';

@Component({
  selector: 'pc-category-list',
  templateUrl: './category-list.component.html',
  styleUrls: ['./category-list.component.scss'],
})
export class CategoryListComponent implements OnInit {
  public loading = true;
  public categories: Array<ProductCategory> = [];

  public selectedCategoryId = null;

  public onSelectedCategoryIdChange: BehaviorSubject<number> = new BehaviorSubject<number>(null);

  constructor(private productCatalogueService: ProductCatalogueService) {
    // set the local selectedCategory value when the observable changes
    this.onSelectedCategoryIdChange.subscribe((categoryId) => {
      console.log('changing category to', categoryId);
      this.selectedCategoryId = categoryId;
    });

    this.productCatalogueService.categories.subscribe((categories) => {
      this.categories = categories;
    });

    this.productCatalogueService.categoriesLoading.subscribe((loading) => {
      this.loading = loading;
    });
  }

  ngOnInit() {
    console.log('Getting categories...');
    this.productCatalogueService.getAllCategories();
  }

  changeCategory(subCategoryId: number): void {
    console.log('changeCategory', subCategoryId);
    console.log(this.productCatalogueService.productListLoading.getValue());
    if (!this.productCatalogueService.productListLoading.getValue()) {
      const searchProperties = this.productCatalogueService.productSearchContext.getValue();
      searchProperties.subCategoryId = subCategoryId;
      this.productCatalogueService.productSearchContext.next(searchProperties);
      this.onSelectedCategoryIdChange.next(subCategoryId);
      this.productCatalogueService.refreshProductList();
    }
  }
}
