import { Injectable } from '@angular/core';
import { HttpClient, HttpErrorResponse } from '@angular/common/http';
import { throwError, BehaviorSubject } from 'rxjs';
import { CoreApiService } from '../../core/services/core-api.service';
import { Product } from '../models/redemptions/product';
import { ProductSearch } from 'src/app/core/models/search/product-search';
import { PagedStatistics } from 'src/app/core/models/paged-statistics';
import { ProductCategory } from '../models/redemptions/product-category';
import { environment } from 'src/environments/environment';
import { Cart } from '../models/redemptions/cart';
import { Address } from 'src/app/core/models/address';
import { Checkout } from '../models/redemptions/checkout';
import { Redemption } from '../models/redemptions/redemption';
import { ApiRestService } from '@motivforce/mx-library-angular';

@Injectable({
  providedIn: 'root',
})
export class ProductCatalogueService {
  productListing: BehaviorSubject<Array<Product>> = new BehaviorSubject<Array<Product>>([]);
  productListLoading: BehaviorSubject<boolean> = new BehaviorSubject<boolean>(false);
  productSearchContext: BehaviorSubject<ProductSearch> = new BehaviorSubject<ProductSearch>({
    text: null,
    max: null,
    min: null,
    categoryId: null,
    subCategoryId: null,
    onSpecial: false,
    pageNumber: 1,
    pageSize: 18,
    orderBy: null,
  });
  private currentCart = new BehaviorSubject<Cart>(null);
  currentCart$ = this.currentCart.asObservable();
  private currentRedemption = new BehaviorSubject<Redemption>(null);
  currentRedemption$ = this.currentRedemption.asObservable();

  searchStatistics: BehaviorSubject<PagedStatistics> = new BehaviorSubject<PagedStatistics>({
    currentPage: 1,
    totalPages: 1,
    totalRecords: 0,
  });

  categories: BehaviorSubject<any> = new BehaviorSubject<any>([]);
  categoriesLoading: BehaviorSubject<boolean> = new BehaviorSubject<boolean>(false);

  constructor(protected apiRestService: ApiRestService, coreApiService: CoreApiService) {}

  /**
   * Updates the subject value to empty array
   *
   * @memberof ProductCatalogueService
   */
  resetProductListing(): void {
    this.searchStatistics.next({ currentPage: 1, totalPages: 1, totalRecords: 0 });
    const search = this.productSearchContext.getValue();

    search.pageNumber = 1;
    this.productSearchContext.next(search);
    this.productListing.next([]);
  }

  async getAllCategories(): Promise<void> {
    this.categoriesLoading.next(true);
    const categories = await this.apiRestService
      .get<Array<ProductCategory>>(
        `${environment.api.core.baseUrl}/members/catalogs/categories/get-with-subcategories`,
      )
      .toPromise();

    this.categories.next(categories);

    this.categoriesLoading.next(false);
  }

  async getProducts(): Promise<any> {
    this.productListLoading.next(true);
    const searchContext = this.productSearchContext.getValue();
    console.log('Searching catalog:', searchContext);
    const searchResult = await this.apiRestService
      .post<any>(`${environment.api.core.baseUrl}/members/catalogs/products/search`, searchContext)
      .toPromise();

    let products = this.productListing.getValue();

    products = products.concat(searchResult.results);
    this.productListing.next(products);
    this.searchStatistics.next(searchResult.statistics);
    this.productListLoading.next(false);
  }

  refreshProductList(): void {
    this.resetProductListing();
    this.getProducts();
  }

  async getProduct(id: number): Promise<Product> {
    return this.apiRestService
      .get<Product>(`${environment.api.core.baseUrl}/members/catalogs/products/${id}`)
      .toPromise();
  }

  getCart(): Promise<Cart> {
    return this.apiRestService
      .get<Cart>(`${environment.api.core.baseUrl}/members/carts`)
      .toPromise();
  }

  updateCurrentCart(cart: Cart) {
    this.currentCart.next(cart);
  }

  addProductToCart(productId: number): Promise<Cart> {
    return this.apiRestService
      .post<Cart>(`${environment.api.core.baseUrl}/members/carts/products/${productId}`, {})
      .toPromise();
  }

  removeProductFromCart(productId: number): Promise<Cart> {
    return this.apiRestService
      .delete<Cart>(`${environment.api.core.baseUrl}/members/carts/products/${productId}`)
      .toPromise();
  }

  increaseProductInCart(productId: number): Promise<Cart> {
    return this.apiRestService
      .put<Cart>(`${environment.api.core.baseUrl}/members/carts/products/${productId}/increase`, {})
      .toPromise();
  }

  decreaseProductInCart(productId: number): Promise<Cart> {
    return this.apiRestService
      .put<Cart>(`${environment.api.core.baseUrl}/members/carts/products/${productId}/decrease`, {})
      .toPromise();
  }

  setProductQuantityInCart(productId: number, quantity: number): Promise<Cart> {
    return this.apiRestService
      .put<Cart>(
        `${environment.api.core.baseUrl}/members/carts/products/${productId}/quantity/${quantity}`,
        {},
      )
      .toPromise();
  }

  resetCart(): Promise<Cart> {
    return this.apiRestService
      .delete<Cart>(`${environment.api.core.baseUrl}/members/carts`)
      .toPromise();
  }

  addCheckoutAddress(address: Address): Promise<Address> {
    return this.apiRestService
      .post<Address>(`${environment.api.core}/members/checkout/address`, address)
      .toPromise();
  }

  updateCheckout(checkout: Checkout): Promise<Checkout> {
    return this.apiRestService
      .put<Checkout>(`${environment.api.core.baseUrl}/members/checkout`, checkout)
      .toPromise();
  }

  completeOrder(): Promise<Redemption> {
    return this.apiRestService
      .post<Redemption>(`${environment.api.core.baseUrl}/members/redemptions/complete-order`, {})
      .toPromise();
  }

  updateCurrentRedemption(redemption: Redemption) {
    this.currentRedemption.next(redemption);
  }

  private handleError(res: HttpErrorResponse | any) {
    console.error(res.error || res.body.error);
    return throwError(res.error || 'Server error');
  }
}
