<div class="component-row">
  <div class="row">
    <div class="col-sm-12">
      <h3>Cats Details</h3>
    </div>
  </div>
  <div class="row">
    <div class="col-sm-6">
      <div class="row">
        <div class="col-sm-6">
          <label>CHOOSE CAT</label>
        </div>
        <div class="col-sm-6"></div>
      </div>
    </div>
  </div>
  <div *ngIf="renderCatSelection" class="row">
    <div class="col-sm-12">
      <vet-member-cat-form *ngIf="renderCatSelection" [selectedCatDetails]="selectedCatDetails$">
      </vet-member-cat-form>
    </div>
  </div>
</div>
