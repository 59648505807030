import { Component, OnInit, OnDestroy, Input } from '@angular/core';
import { Subscription } from 'rxjs';
import { FormControl, FormGroup, Validators } from '@angular/forms';
import { TestResultFormData } from 'src/app/vet/models/test-result-form-data';
import { MemberManagerService } from 'src/app/vet/services/member-manager.service';
import { VetPractice, VetPracticeDetailed } from 'src/app/shared/models/vet-practice';

@Component({
  selector: 'vet-add-test-result-form',
  templateUrl: './add-test-result-form.component.html',
  styleUrls: ['./add-test-result-form.component.scss'],
})
export class AddTestResultFormComponent implements OnInit, OnDestroy {
  @Input() addTestMessage: string;

  private selectedMemberSub: Subscription;
  renderform = false;
  selectedMember: VetPracticeDetailed;
  submitModel: TestResultFormData = {
    catOwnerId: null,
    catId: null,
    dateOfTest: null,
    redeemPoints: false,
    testResult: '',
  } as TestResultFormData;

  testResultForm: FormGroup;

  cats: VetPractice[] = [];
  selectedCat: VetPractice;

  constructor(private managerService: MemberManagerService) {}

  ngOnInit(): void {
    this.intialiseForm();

    this.selectedMemberSub = this.managerService.selectedMember$.subscribe((member) => {
      if (member !== null && member.account !== null) {
        this.selectedMember = member;

        if (this.cats.length > 0) {
          this.selectedCat = this.cats[0];
        }

        this.renderform = true;
      }
    });
  }

  intialiseForm(): void {
    this.testResultForm = new FormGroup({
      testResult: new FormControl('', [Validators.required]),
      dateOfTest: new FormControl('', [Validators.required]),
      redeemPoints: new FormControl(false),
    });
  }

  ngOnDestroy(): void {
    if (this.selectedMemberSub) {
      this.selectedMemberSub.unsubscribe();
    }
  }

  onSubmit() {
    if (this.testResultForm.valid) {
      const formData = this.testResultForm.getRawValue();
      Object.keys(this.submitModel).forEach((key) => {
        if (key !== 'catOwnerId' && key !== 'catId') {
          this.submitModel[key] = formData[key];
        }
      });

      const testDate = new Date(this.submitModel.dateOfTest);
      let todaysDate = new Date();
      todaysDate.setMonth(todaysDate.getMonth() - 2);
      let twoMonthsAgo = new Date(todaysDate);

      if (testDate < twoMonthsAgo) {
        this.addTestMessage = 'You cannot add a test result for earlier than 8 weeks ago.';
      } else {
        this.managerService
          .addT4TestResult(this.submitModel)
          .then((result) => {
            if (!result.hasErrors) {
            } else {
              this.addTestMessage = result.message;
            }
          })
          .catch((error) => {
            this.addTestMessage = error;
            console.log(error);
          });
      }
    } else {
      this.addTestMessage =
        'Please ensure all fields on the purchase form are filled out correctly.';
      console.log('Add T4 Test Result form is invalid.');
    }
  }

  changeCat(event: Event): void {
    const catId = +(event.target as HTMLInputElement).value;
  }
}
