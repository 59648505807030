<div class="layout-boxed content">
    <app-header heading-copy="{{title}}" top-banner-url="{{banner}}"></app-header>
    <div class="container">
        <div class="faq-content">
            <div class="col-sm-12 pd-0" [innerHtml]="body"></div>
            <ngb-accordion>
                <ng-container *ngFor="let faq of faqs">
                    <ngb-panel [title]="faq.heading" class="accordion-header">
                        <ng-template ngbPanelContent>
                            <div *ngIf="faq.body" [innerHTML]="faq.body"></div>
                        </ng-template>
                    </ngb-panel>
                </ng-container>
            </ngb-accordion>
        </div>
        
        <app-footer></app-footer>
    </div>

</div>