import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { BaseCoreService } from 'src/app/core/services/base-core-service';
import { ApiRestService } from '@motivforce/mx-library-angular';

@Injectable({
  providedIn: 'root',
})
export class SupportService extends BaseCoreService {
  constructor(protected apiRestService: ApiRestService) {
    super('norbrook/support/contactus', apiRestService);
  }

  submitHelpRequest({ form, endpoint }: { form: any; endpoint: any; }): Promise<any> {
    const endpointUrl = `${this.memberBaseUrl}/${endpoint}`;
    return this.apiRestService.post<any>(endpointUrl, form).toPromise();
  }
}
