import { Component, OnInit } from '@angular/core';
import { Router, NavigationEnd } from '@angular/router';
import { TranslateService } from '@ngx-translate/core';
import { SplashScreenService } from './core/services/splash-screen.service';
import { SettingsProvider } from './shared/settings.provider';

@Component({
  selector: 'app-root',
  templateUrl: './app.component.html',
  styleUrls: ['./app.component.scss'],
})
export class AppComponent implements OnInit {
  title = 'norbrook-app';

  constructor(
    translate: TranslateService,
    private router: Router,
    private splashScreenService: SplashScreenService,
    settingsProvider: SettingsProvider,
  ) {
    translate.setDefaultLang('en-GB');

    translate.use(settingsProvider.settings.language);
  }

  ngOnInit() {
    this.router.events.subscribe((evt) => {
      if (!(evt instanceof NavigationEnd)) {
        return;
      }
      window.scrollTo(0, 0);
    });
  }
}
