<div class="vet-home-page">
  <app-header heading-copy="# {{ title }}" top-banner-url="{{ banner }}"></app-header>

  <div class="container">
    <div class="home-content">
      <div class="row">
        <div class="col-lg-8 col-md-8 col-xs-12 col-xs-12">
          <div class="row">
            <div class="bottom-banner">
              <div class="col-lg-12 col-md-12 col-sm-12 col-xs-12">
                <a href="/vet/tools-and-cpd">
                  <img src="../../../../assets/images/cpd-box.png" />
                </a>
              </div>
            </div>
          </div>
        </div>
        <div class="col-lg-4 col-md-4 col-xs-12 col-xs-12">
          <div class="top-boxes">
            <div class="col-lg-12 col-md-12 col-sm-12 col-xs-12">
              <a href="/vet/rewards">
                <img src="../../../../assets/images/vet-redeem-box.png" />
              </a>
            </div>
            <div class="col-lg-12 col-md-12 col-sm-12 col-xs-12">
              <a href="/vet/account-summary">
                <img src="../../../../assets/images/vet-account-summary-box.png" />
              </a>
            </div>
          </div>
        </div>
      </div>
    </div>
    <app-footer></app-footer>
  </div>
</div>
