import { Component, OnInit, Input } from '@angular/core';
import { Router } from '@angular/router';
import { Product } from 'src/app/vet/models/redemptions/product';
import { ProductCatalogueService } from 'src/app/vet/services/product-catalogue.service';

@Component({
  selector: 'pc-product-detail',
  templateUrl: './product-detail.component.html',
  styleUrls: ['./product-detail.component.scss'],
})
export class ProductDetailComponent implements OnInit {
  @Input()
  public productId: number;
  @Input()
  public isVoucher: boolean;

  public loading = true;
  public product: Product = null;  

  constructor(private productCatalogueService: ProductCatalogueService, private router: Router) { }

  async ngOnInit() {
    this.loading = true;
    this.product = await this.productCatalogueService.getProduct(this.productId);
    if (this.product.type === 'E-Voucher' || this.product.type === 'Gift Card' || this.product.type === 'Voucher') {
      this.product.isVoucher = true;
    }
    console.log(this.product);
    this.loading = false;
  }  
}
