import { Component, OnInit } from '@angular/core';
import { FormBuilder, FormControl, FormGroup, Validators, AbstractControl } from '@angular/forms';
import { Router } from '@angular/router';
import { NgxSpinnerService } from 'ngx-bootstrap-spinner';
import { Registration } from 'src/app/shared/models/registration';
import { RegistrationService } from 'src/app/shared/services/registration.service';

function passwordMatcher(c: AbstractControl): { [key: string]: boolean } | null {
  const passwordControl = c.get('password');
  const confirmControl = c.get('confirmPassword');

  if (passwordControl.pristine || confirmControl.pristine) {
    return null;
  }

  if (passwordControl.value === confirmControl.value) {
    return null;
  }

  return { match: true };
}

@Component({
  selector: 'vet-registration-form',
  templateUrl: './vet-registration-form.component.html',
  styleUrls: ['./vet-registration-form.component.scss'],
})
export class VetRegistrationFormComponent implements OnInit {
  submitted = false;
  submitModel: Registration = {
    vetPracticeName: '',
    vetPracticePostcode: '',
    firstName: '',
    lastName: '',
    email: '',
    confirmEmail: '',
    phoneNumber: '',
    password: '',
    acceptedTnCs: false,
  } as Registration;
  registrationDetailsForm: FormGroup;
  formInvalidAfterSubmit: boolean;
  errorMessages: string;
  passwordRequirements: string = 'The password needs to be at least 8 characters long and contain 1 uppercase letter, 1 '
  + 'lowercase letter, 1 number and 1 special character from the following = + - ^ $ * . [ ] { } ( ) ? ! @ # % & / \\ , > \' : ; | _ ~ `.';

  get email() {
    return this.registrationDetailsForm.get('email');
  }
  get confirmEmail() {
    return this.registrationDetailsForm.get('confirmEmail');
  }
  get firstName() {
    return this.registrationDetailsForm.get('firstName');
  }
  get lastName() {
    return this.registrationDetailsForm.get('lastName');
  }
  get vetPracticeName() {
    return this.registrationDetailsForm.get('vetPracticeName');
  }
  get vetPracticePostcode() {
    return this.registrationDetailsForm.get('vetPracticePostcode');
  }
  get phoneNumber() {
    return this.registrationDetailsForm.get('phoneNumber');
  }
  get acceptedTnCs() {
    return this.registrationDetailsForm.get('acceptedTnCs');
  }

  public get passwordFormControl(): AbstractControl {
    return this.registrationDetailsForm.get(['passwordGrp', 'password']);
  }

  public get confirmPasswordFormControl(): AbstractControl {
    return this.registrationDetailsForm.get(['passwordGrp', 'confirmPassword']);
  }

  constructor(
    private registrationService: RegistrationService,
    private router: Router,
    private spinner: NgxSpinnerService
  ) { }

  ngOnInit(): void {
    this.registrationDetailsForm = new FormGroup({
      vetPracticeName: new FormControl('', [Validators.required]),
      vetPracticePostcode: new FormControl('', [Validators.required]),
      firstName: new FormControl('', [Validators.required]),
      lastName: new FormControl('', [Validators.required]),
      email: new FormControl('', [Validators.required, Validators.email]),
      confirmEmail: new FormControl('', [Validators.required, Validators.email]),
      phoneNumber: new FormControl('', [Validators.required]),
      password: new FormControl('',
        [
          Validators.required,
          Validators.pattern('^(?=.{8,})(?=.*[a-z])(?=.*[A-Z])(?=.*[^\\w\\d])(?=.*\\d).*$'),
        ],
      ),
      confirmPassword: new FormControl('', [Validators.required]),
      acceptedTnCs: new FormControl(false, [Validators.requiredTrue]),
    });
  }

  onSubmit() {
    this.submitted = true;

    if (!this.registrationDetailsForm.value.acceptedTnCs) {
      this.errorMessages = 'You must accept the Terms & Conditions';
    } else {
      if (this.registrationDetailsForm.valid) {
        const formData = this.registrationDetailsForm.getRawValue();

        Object.keys(this.submitModel).forEach((key) => {
          if (key !== 'countryCode') {
            console.log('Form key: ' + key);
            this.submitModel[key] = formData[key];
          }
        });

        if (!this.submitModel.acceptedTnCs) {
          this.errorMessages = 'You Must accept the Terms & Conditions.';
          console.log('Must accept the Terms & Conditions.');
        } else if (this.submitModel.password.length < 8) {
          this.errorMessages = this.passwordRequirements;
        } else if (this.submitModel.email !== this.submitModel.confirmEmail) {
          this.errorMessages = 'Your email address does not match the confirm email address.';
        } else {
          this.spinner.show();
          this.registrationService
            .registerVet(this.submitModel)
            .then((result) => {
              if (result.hasErrors) {
                this.errorMessages = result.message;
              } else {
                this.router.navigate(['/vet/registration-successful']);
              }
            })
            .catch((error) => {
              console.log(error);
              this.errorMessages = 'There was an error submitting the form, please try again or Contact Us.';
            })
            .finally(() => this.spinner.hide());
        }

        console.log('Submitted form data:', this.submitModel);
      } else {
        this.errorMessages = 'Please ensure you have completed all required fields.';
        console.log('Form is still invalid.');
      }
    }

    return false;
  }

  showErrorMessage(formControl: AbstractControl, submitted: boolean): boolean {
    return (
      formControl && formControl.invalid && (formControl.dirty || formControl.touched || submitted)
    );
  }
}
