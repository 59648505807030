<div class="layout-boxed content">
    <app-header heading-copy="{{title}}" top-banner-url="{{banner}}"></app-header>
    <div class="container">
        <div class="col-sm-12 pd-0" [innerHtml]="body"></div>
        <div class="row">
            <div *ngIf="message !== ''" class="text-center">
                <p><i>{{message}}</i></p>
            </div>
            <div class="col-md-12 text-center">
                <div class="form-group"><button class="btn btn-primary" (click)="acceptTerms()">I ACCEPT THE TERMS AND
                        CONDITIONS</button></div>
            </div>
        </div>
        <app-footer></app-footer>
    </div>

</div>