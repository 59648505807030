import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { DialogComponent } from './components/dialog/dialog.component';
import { NgbModalModule } from '@ng-bootstrap/ng-bootstrap';
import { DialogNgbService, FormModule } from '@motivforce/mx-library-angular';
import { RouterModule } from '@angular/router';
import { TransferPointsDialogComponent } from './components/transfer-points-dialog/transfer-points-dialog.component';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { SharedModule } from 'src/app/shared/shared.module';

@NgModule({
  declarations: [DialogComponent, TransferPointsDialogComponent],
  imports: [
    CommonModule,
    NgbModalModule,
    RouterModule,
    FormsModule,
    ReactiveFormsModule,
    SharedModule,
  ],
  exports: [NgbModalModule],
  entryComponents: [],
})
export class DialogModule {
  constructor(private dialog: DialogNgbService) {
    this.dialog.registerNotificationDialog(DialogComponent);
    this.dialog.registerErrorDialog(DialogComponent);
    this.dialog.registerWarningDialog(DialogComponent);
    this.dialog.registerValidationDialog(DialogComponent);

    this.dialog.registerFeatureDialog('transferPointsDialog', TransferPointsDialogComponent);
  }
}
