import { Component, OnInit } from '@angular/core';
import { FormBuilder, FormControl, FormGroup, Validators } from '@angular/forms';
import { AbstractDialogComponent } from '@motivforce/mx-library-angular';
import { NgbActiveModal } from '@ng-bootstrap/ng-bootstrap';
import { TransferPoints } from 'src/app/account-manager/models/transferPoints';
import { MemberManagerService } from 'src/app/vet/services/member-manager.service';

@Component({
  selector: 'transfer-points-dialog',
  templateUrl: './transfer-points-dialog.component.html',
  styleUrls: ['./transfer-points-dialog.component.scss'],
})
export class TransferPointsDialogComponent extends AbstractDialogComponent implements OnInit {
  selectedMember: number;
  transferForm = this.formBuilder.group({
    pointsToAward: ['', [Validators.required]],
    reasonForAwarding: ['', [Validators.required]],
  });
  groups = [''];
  transferModel: TransferPoints = { points: 0, memberId: 0, message: '' };
  confirmationAmountChecked: boolean;
  valueToAward: number;

  constructor(
    public activeModal: NgbActiveModal,
    private managerService: MemberManagerService,
    private formBuilder: FormBuilder,
  ) {
    super();
  }

  ngOnInit(): void {
    if (this.inputs && this.inputs.length > 0) {
      this.selectedMember = this.inputs[0] as number;
    }

    this.transferForm.get('pointsToAward').valueChanges.subscribe((value) => {
      if (this.confirmationAmountChecked) {
        this.calculateValue();
      }
    });
  }

  initialisePointsAwardingForm(): void {
    this.transferForm = new FormGroup({
      pointsToAward: new FormControl(''),
      reasonForAwarding: new FormControl(''),
    });
  }

  calculateValue(): void {
    const formData = this.transferForm.getRawValue();

    this.valueToAward = formData.pointsToAward * 0.01;
    this.confirmationAmountChecked = true;
  }

  onSubmit() {
    const formData = this.transferForm.getRawValue();
    if (this.transferForm.valid) {
      const formData = this.transferForm.getRawValue();

      this.transferModel.points = formData.pointsToAward;
      this.transferModel.message = formData.reasonForAwarding;
      this.transferModel.memberId = this.selectedMember;

      this.managerService
        .transferPoints(this.transferModel)
        .then((result) => {
          if (!result.hasErrors) {
            this.activeModal.close();
          } else {
          }
          document.location.reload();
          this.initialisePointsAwardingForm();
        })
        .catch((error) => {
          console.log(error);
        });
    } else {
    }
  }
}
