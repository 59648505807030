import { Injectable } from '@angular/core';
import { PointsSummary } from '../models/points-summary';
import { Observable, BehaviorSubject } from 'rxjs';
import { BaseCoreService } from 'src/app/core/services/base-core-service';
import { CoreApiService } from 'src/app/core/services/core-api.service';
import { ApiRestService } from '@motivforce/mx-library-angular';

@Injectable({
  providedIn: 'root',
})
export class PointsSummaryService extends BaseCoreService {
  private pointsSummary = new BehaviorSubject<PointsSummary>({
    available: 0,
    earned: 0,
    redeemed: 0,
    totalTransfers: 0,
  });
  pointsSummary$ = this.pointsSummary.asObservable();

  constructor(private coreApiService: CoreApiService, protected apiRestService: ApiRestService) {
    super('norbrook/pointsSummary', apiRestService);
  }

  getAccountManagerPointsSummary(): Observable<PointsSummary> {
    const endpoint = `${this.memberBaseUrl}/account-managers`;
    this.apiRestService
      .get<PointsSummary>(endpoint)
      .toPromise()
      .then((result) => {
        this.pointsSummary.next(result);
      });

    return this.pointsSummary$;
  }

  getPointsSummary(): Observable<PointsSummary> {
    const endpoint = `${this.memberBaseUrl}`;
    this.apiRestService
      .get<PointsSummary>(endpoint)
      .toPromise()
      .then((result) => {
        this.pointsSummary.next(result);
      });

    return this.pointsSummary$;
  }

  refreshPointsSummary(): void {
    const endpoint = `${this.memberBaseUrl}`;
    this.apiRestService
      .get<PointsSummary>(endpoint)
      .toPromise()
      .then((result) => {
        this.pointsSummary.next(result);
      });
  }

  getVetActivitySummary(): Promise<any> {
    const endpoint = `${this.memberBaseUrl}/vetactivitysummary`;
    return this.coreApiService.get(endpoint).toPromise();
  }
}
