<div *ngIf="!loading">
  <span class="text-bold"><label>CHOOSE A CATEGORY:</label></span>
  <ul class="category-list">
    <li>
      <a
        [routerLink]=""
        [ngClass]="{ active: selectedCategoryId == null }"
        (click)="changeCategory(null)"
        >All Products</a
      >
    </li>
    <li *ngFor="let category of categories">
      <ul class="category-list">
        <li *ngFor="let subCategory of category.subCategories">
          <a
            [routerLink]=""
            [ngClass]="{ active: selectedCategoryId == category.id }"
            (click)="changeCategory(subCategory.id)"
            >{{ subCategory.name }}
          </a>
        </li>
      </ul>
    </li>
  </ul>
</div>
<content-loader primaryColor="#e0e0e0" secondaryColor="#a0a0a0" height="200" *ngIf="loading">
  <svg:rect x="0" y="0" width="100%" height="10" />
  <svg:rect x="0" y="20" width="70%" height="10" />
  <svg:rect x="0" y="40" width="80%" height="10" />
  <svg:rect x="0" y="60" width="50%" height="10" />
  <svg:rect x="0" y="80" width="100%" height="10" />
</content-loader>
