import { Address } from 'src/app/core/models/address';
import { BaseModel } from 'src/app/core/models/base-model';

export class Checkout extends BaseModel {
  totalPoints: number;
  shippingAddressId: number;
  billingAddressId: number;
  paidDate: Date;
  isPointsAndPay: boolean;
  reference: string;
  step: CheckoutStep;
  tsandCsAccepted: boolean;
  dateOfBirth: Date;
  shippingEmail: string;
  cartId: number;
  userId: number;
  programId: number;
  clientId: number;

  shippingAddress: Address;
  billingAddress: Address;
}

export enum CheckoutStep {
  OrderDetails = 0,
  OrderSummary = 1,
  OrderCompleted = 2,
}
