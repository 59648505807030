<div class="profile-page">
    <app-header heading-copy="# {{title}}" top-banner-url="{{banner}}"></app-header>

    <div class="container">

        <app-profile-form [bodyContent]="body"></app-profile-form>
        <app-footer></app-footer>

    </div>

</div>