<div class="landing-page">
  <div class="container">
    <div class="logos">
      <img src="../../../assets/images/norbrook-logo-bigger.png" class="norbrook-logo" /><br />
      <img
        src=" ../../../assets/images/advantage-programme-logo-bigger.png"
        class="advantage-logo"
      />
    </div>

    <div class="row site-choice-boxes">
      <div class="col-md-2"><p>&nbsp;</p></div>
      <div class="col-md-8">
        <h3><strong>Thank you for visiting the Norbrook Advantage Programme.</strong></h3>
        <p>&nbsp;</p>
        <h3>
          <strong>
            Unfortunately, the programme has now closed in order to allow us to offer our customers
            more bespoke and flexible support.</strong
          >
        </h3>
        <p>&nbsp;</p>
        <h3>
          <strong
            >For more information on Norbrook Laboratories (G.B.) Limited, please visit
            www.norbrook.com or contact your Norbrook Account Manager -
            <a
              href="https://norbrook-assets.s3.eu-west-2.amazonaws.com/NEW+Norbrook+Sales+Team+Map_July+2024.pdf"
              >CLICK HERE</a
            >
            for full contact details.</strong
          >
        </h3>
      </div>
      <div class="col-md-2"><p>&nbsp;</p></div>
      <!-- 
      <div class="col-md-6">
        <a href="/account-manager/login"
          ><img src="../../../../assets/images/i-am-an-account-manager.png"
        /></a>
      </div>
      <div class="col-md-6">
        <a href="/vet/login"><img src="../../../assets/images/i-am-a-vet.png" /></a>
      </div> -->
    </div>
    <div class="footer">
      <app-footer></app-footer>
    </div>
  </div>
</div>
