import { Type } from '@angular/core';
import { AcceptTermsComponent } from './account-manager/components/accept-terms/accept-terms.component';
import { ArticleDetailComponent } from './shared/components/articles/article-detail/article-detail.component';
import { ArticleListPageComponent } from './shared/components/articles/article-list-page/article-list-page.component';
import { ContactFormComponent } from './shared/components/contact-form/contact-form.component';
import { DefaultTemplateComponent } from './shared/components/default-template/default-template.component';
import { FaqPageComponent } from './shared/components/faq/faq-page.component';
import { LandingPageComponent } from './shared/components/landing-page/landing-page.component';
import { LoginComponent } from './shared/components/login/login.component';
import { ProfilePageComponent } from './shared/components/profile/profile-page/profile-page.component';
import { ResetPasswordComponent } from './shared/components/reset-password/reset-password.component';
import { VetHomeComponent } from './vet/components/home/vet-home.component';
import { MemberManagerPageComponent } from './vet/components/member-manager-page/member-manager-page.component';
import { ProductDetailPageComponent } from './vet/components/product-catalogue/product-detail-page/product-detail-page.component';
import { VetPointsSummaryComponent } from './vet/components/vet-points-summary/vet-points-summary.component';
import { VetRegistrationPageComponent } from './vet/components/vet-registration/registration-page/vet-registration-page.component';
import { VetRegistrationSuccessComponent } from './vet/components/vet-registration/registration-success/registration-success.component';
import { CartPageComponent } from './vet/components/cart-page/cart-page.component';
import { CheckoutDetailsPageComponent } from './vet/components/checkout-details-page/checkout-details-page.component';
import { RedemptionCompletePageComponent } from './vet/components/redemption-complete-page/redemption-complete-page.component';
import { MemberSummaryComponent } from './vet/components/member-summary/member-summary.component';
import { AccountManagerHomeComponent } from './account-manager/components/home/account-manager-home.component';
import { CataloguePageComponent } from './vet/components/catalogue-page/catalogue-page.component';
import { PointsAwardedSummaryComponent } from './account-manager/components/points-awarded-summary/points-awarded-summary.component';
import { AwardPointsComponent } from './account-manager/components/award-points/award-points.component';

const mapper = {
  landingPageComponent: LandingPageComponent as Type<any>,
  loginComponent: LoginComponent as Type<any>,
  resetPassword: ResetPasswordComponent as Type<any>,
  vetHomePage: VetHomeComponent as Type<any>,
  vetRegistration: VetRegistrationPageComponent as Type<any>,
  vetRegistrationSuccess: VetRegistrationSuccessComponent as Type<any>,
  manageMemberComponent: MemberManagerPageComponent as Type<any>,
  acceptTermsPage: AcceptTermsComponent as Type<any>,
  accountManagerHomePage: AccountManagerHomeComponent as Type<any>,
  profileComponent: ProfilePageComponent as Type<any>,
  contactUsPage: ContactFormComponent as Type<any>,
  cataloguePage: CataloguePageComponent as Type<any>,
  cartPage: CartPageComponent as Type<any>,
  checkoutPage: CheckoutDetailsPageComponent as Type<any>,
  productDetailsPage: ProductDetailPageComponent as Type<any>,
  redemptionCompletePage: RedemptionCompletePageComponent as Type<any>,
  vetAccountSummaryPage: VetPointsSummaryComponent as Type<any>,
  memberSummaryPage: MemberSummaryComponent as Type<any>,
  accordionPage: FaqPageComponent as Type<any>,
  articleListPage: ArticleListPageComponent as Type<any>,
  articleDetail: ArticleDetailComponent as Type<any>,
  pointsAwardedSummaryTemplate: PointsAwardedSummaryComponent as Type<any>,
  awardPointsTemplate: AwardPointsComponent as Type<any>,
  defaultTemplate: DefaultTemplateComponent as Type<any>,
  default: DefaultTemplateComponent as Type<any>, // fallback if no component can be found
};

export default mapper;
