import { NgModule } from '@angular/core';
import { APOLLO_OPTIONS } from 'apollo-angular';
import { HttpLinkModule, HttpLink } from 'apollo-angular-link-http';
import { InMemoryCache, IdGetterObj, defaultDataIdFromObject } from 'apollo-cache-inmemory';
import { Auth } from 'aws-amplify';
import { setContext } from 'apollo-link-context';
import { onError } from 'apollo-link-error';
import { ApolloLink } from 'apollo-link';

import * as ls from 'local-storage';
import { ErrorService } from '../shared/services/error.service';
import { environment } from 'src/environments/environment';

export function createApollo(httpLink: HttpLink, errorService: ErrorService): any {
  const errorHandler = onError(({ graphQLErrors, networkError }) => {
    if (graphQLErrors) {
      graphQLErrors.forEach((err) => {
        errorService.handleError(err.message, err.extensions.code, err);
      });
    }
    if (networkError) {
      console.log(`[Network error]: ${networkError}`);
    }
  });
  const auth = setContext(async (_, { headers }) => {
    // Grab token if there is one in storage or hasn't expired

    try {
      const session = await Auth.currentSession();
      const token = session.getIdToken().getJwtToken();
      const context: any = { headers: {} };

      context.headers.Authorization = `Bearer ${token}`;

      const impersonateUserId = ls.get<number>('m9-impersonate-user');
      if (impersonateUserId) {
        console.log('IMPERSONATE USER ID', impersonateUserId);
        context.headers['admin-impersonate-user-id'] = impersonateUserId;
      }

      // Return the headers as usual
      return context;
    } catch (err) {
      console.log(err);
      return undefined;
    }
  });
  const link = ApolloLink.from([
    errorHandler,
    auth,
    httpLink.create({ uri: `${environment.api.cms}/graphql` }),
  ]);
  return {
    link,
    cache: new InMemoryCache({
      dataIdFromObject: (object: any) => {
        if (object.children && object.children.length > 0) {
          return defaultDataIdFromObject(object) + '-withChildren';
        }

        return defaultDataIdFromObject(object) + '-withoutChildren';
      },
    }),
  };
}

@NgModule({
  exports: [HttpLinkModule],
  providers: [
    {
      provide: APOLLO_OPTIONS,
      useFactory: createApollo,
      deps: [HttpLink, ErrorService],
    },
  ],
})
export class GraphQLModule {}
