<div class="member-manager-page">
    <app-header heading-copy="# YOUR REWARDS" 
    top-banner-url="https://norbrook-assets.s3.eu-west-2.amazonaws.com/Vet+Practice+Rewards+Catalogue+Header+Image.jpg">
</app-header>
    <div class="container">
        <div class="product-detail-page">
            <div class="row">
                <div class="col-sm-12">
                    <p>&nbsp;</p>
                </div>
            </div>
            <div class="row">
                <div class="col-md-8">
                    <pc-product-detail [productId]="productId"></pc-product-detail>
                </div>
    
                <div class="col-md-3 offset-md-1">
                    <button
                    (click)="addToCart()"
                    class="btn btn-primary cart-button mt-25 full-width"
                    [disabled]="addingToCart ? 'disabled' : null"
                    >
                    ADD TO CART <i class="fa fa-spinner fa-spin" *ngIf="addingToCart"></i>
                    </button>
                    <a routerLink="/vet/rewards/cart" class="btn btn-primary cart-button mt-25 full-width">VIEW CART</a>
                    <a routerLink="/vet/rewards" class="btn btn-primary cart-button mt-25 full-width">BACK TO CATALOGUE</a>
                </div>
            </div>
        </div>        
        <app-footer></app-footer>
    </div>
</div>