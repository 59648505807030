import { Component, Input, OnInit } from '@angular/core';
import { Content } from 'src/app/core/models/content.model';
import { DynamicLayout } from 'src/app/core/models/dynamic-layout.model';
import { MemberManagerService } from 'src/app/vet/services/member-manager.service';
import { Transfers } from '../../models/transfers';
import { FormControl, FormGroup } from '@angular/forms';

@Component({
  selector: 'app-points-awarded-summary',
  templateUrl: './points-awarded-summary.component.html',
  styleUrls: ['./points-awarded-summary.component.scss'],
})
export class PointsAwardedSummaryComponent implements OnInit, DynamicLayout {
  @Input() baseContent: Content;

  title: string;
  banner: string;

  transfers: Transfers[] = [];
  visibleTransfers: Transfers[] = [];

  searchParamsForm: FormGroup = new FormGroup({
    fromDate: new FormControl(),
    toDate: new FormControl(),
    search: new FormControl(),
  });

  constructor(private managerService: MemberManagerService) {}

  ngOnInit(): void {
    const titleField = this.baseContent.fields.find((f) => f.name === 'Title');
    const bannerField = this.baseContent.fields.find((f) => f.name === 'Banner');
    this.title = titleField.value.value;
    this.banner = bannerField.value.url;

    this.managerService.getTransfersMade(this.searchParamsForm.value).then((transferData) => {
      this.processResults(transferData);
    });
  }

  processResults(transferData: Transfers[]): void {
    this.transfers = [];
    for (let m = 0; m < transferData.length; m++) {
      const transfer = transferData[m];
      if (transfer) {
        this.transfers.push({
          vetPracticeName: transfer.vetPracticeName,
          emailAddress: transfer.emailAddress,
          firstName: transfer.firstName,
          lastName: transfer.lastName,
          pointsAwardedFor: transfer.pointsAwardedFor,
          totalPoints: transfer.totalPoints,
          totalPointsValue: transfer.totalPointsValue,
          dateAwarded: transfer.dateAwarded,
        });
      }
    }

    this.visibleTransfers = [...this.transfers];
  }

  onSubmit(): void {
    this.managerService.getTransfersMade(this.searchParamsForm.value).then((transferData) => {
      this.processResults(transferData);
    });
  }

  onResetSearch(): void {
    this.searchParamsForm.reset();
    this.transfers = [];
  }

  onExport(): void {
    this.managerService.downloadTransfersMade(this.searchParamsForm.value);
  }
}
