import { Component, OnDestroy, OnInit } from '@angular/core';
import { Subscription } from 'rxjs';
import { PointsSummary } from 'src/app/shared/models/points-summary';
import { PointsSummaryService } from 'src/app/shared/services/points-summary.service';

@Component({
  selector: 'account-manager-summary',
  templateUrl: './account-manager-summary.component.html',
  styleUrls: ['./account-manager-summary.component.scss'],
})
export class AccountManagerSummaryComponent implements OnInit, OnDestroy {
  pointsSummary: PointsSummary;
  private pointsSummarySub: Subscription;

  constructor(private pointsSummaryService: PointsSummaryService) {}

  ngOnInit(): void {
    this.pointsSummary = null;
    this.pointsSummarySub = this.pointsSummaryService
      .getAccountManagerPointsSummary()
      .subscribe((result) => {
        this.pointsSummary = result;
      });
  }

  ngOnDestroy(): void {
    this.pointsSummarySub.unsubscribe();
  }
}
