import { Component, OnInit, OnDestroy, Input } from '@angular/core';
import { Subscription } from 'rxjs';
import { FormControl, FormGroup, Validators } from '@angular/forms';
import { ProductPurchaseFormData } from 'src/app/vet/models/product-purchase-form-data';
import { environment } from 'src/environments/environment';
import { MemberManagerService } from 'src/app/vet/services/member-manager.service';

@Component({
  selector: 'vet-add-purchase-form',
  templateUrl: './add-purchase-form.component.html',
  styleUrls: ['./add-purchase-form.component.scss'],
})
export class AddPurchaseFormComponent implements OnInit, OnDestroy {
  @Input() addPurchaseMessage: string;
  private selectedMemberSub: Subscription;
  errorMessages: string;
  renderform = false;
  submitModel: ProductPurchaseFormData = {
    catOwnerId: null,
    product100ml: false,
    product100mlQty: 0,
    product30ml: false,
    product30mlQty: 0,
    purchaseDate: null,
  } as ProductPurchaseFormData;
  productImgUrl = `${environment.asset.cdn}/thyronorm-product.png`;

  purchaseForm: FormGroup;

  constructor(private managerService: MemberManagerService) {}

  ngOnInit(): void {
    this.selectedMemberSub = this.managerService.selectedMember$.subscribe((member) => {
      if (member != null && member.account != null) {
        this.renderform = true;
      }
    });
    this.initialiseForm();
  }
  initialiseForm() {
    this.purchaseForm = new FormGroup({
      product30ml: new FormControl(false),
      product30mlQty: new FormControl(0),
      product100ml: new FormControl(false),
      product100mlQty: new FormControl(0),
      purchaseDate: new FormControl(null, [Validators.required]),
    });
  }

  ngOnDestroy(): void {
    if (this.selectedMemberSub) {
      this.selectedMemberSub.unsubscribe();
    }
  }

  onSubmit() {
    if (this.purchaseForm.valid) {
      const formData = this.purchaseForm.getRawValue();

      Object.keys(this.submitModel).forEach((key) => {
        if (key !== 'catOwnerId') {
          this.submitModel[key] = formData[key];
        }
      });

      const purchaseDate = new Date(this.submitModel.purchaseDate);
      let todaysDate = new Date();
      todaysDate.setMonth(todaysDate.getMonth() - 2);
      let twoMonthsAgo = new Date(todaysDate);

      if (purchaseDate < twoMonthsAgo) {
        this.addPurchaseMessage =
          'You cannot add a Thyronorm purchase for earlier than 8 weeks ago.';
      } else {
        this.managerService
          .addPurchase(this.submitModel)
          .then((result) => {
            if (!result.hasErrors) {
            } else {
              this.addPurchaseMessage = result.message;
            }
          })
          .catch((error) => {
            this.addPurchaseMessage = error;
            console.log(error);
          });
      }
    } else {
      this.addPurchaseMessage =
        'Please ensure all fields on the purchase form are filled out correctly.';
    }
  }
}
