import { NgModule } from '@angular/core';
import { Routes, RouterModule } from '@angular/router';
import { LayoutRoutes } from './core/layout/layout-routing';
import { ErrorModule } from './shared/components/error/error.module';
import { NotFoundErrorComponent } from './shared/components/error/not-found-error/not-found-error.component';
import { PermissionErrorComponent } from './shared/components/error/permission-error/permission-error.component';
import { ServerErrorComponent } from './shared/components/error/server-error/server-error.component';

const routes: Routes = [
  {
    path: 'auth',
    loadChildren: './core/authentication.module#AuthenticationModule',
  },
  {
    path: '404',
    component: NotFoundErrorComponent,
  },
  {
    path: '401',
    component: PermissionErrorComponent,
  },
  {
    path: '500',
    component: ServerErrorComponent,
  },
  {
    path: '',
    children: LayoutRoutes,
    // canActivate: [AuthGuard],
  },
];

@NgModule({
  imports: [RouterModule.forRoot(routes, { useHash: false, relativeLinkResolution: 'legacy' }), ErrorModule],
  exports: [RouterModule],
})
export class AppRoutingModule { }
