<div>
    <app-header heading-copy="# {{title}}" top-banner-url="{{banner}}"></app-header>
    <div class="container">
        <div class="cart-content">
            <div class="row">
                <div class="col-sm-12">
                    <p>&nbsp;</p>
                </div>
            </div>
            <div class="row">
                <div class="col-md-9">
                    <pc-cart></pc-cart>
                </div>
                <div class="col-md-3 ">
                    <a routerLink="/vet/rewards/checkout-details"
                        class="btn btn-primary full-width cart-button-top">PROCEED TO CHECKOUT</a>
                    <a routerLink="/vet/rewards" class="btn btn-primary full-width cart-button">BACK TO CATALOGUE</a>
                </div>
            </div>
        </div>        
        <app-footer></app-footer>
    </div>
</div>