<div class="cat-home-page">
  <app-header heading-copy="# {{ title }}" top-banner-url="{{ banner }}"></app-header>

  <div class="container">
    <div class="home-content">
      <div class="row">
        <div class="col-lg-12 col-md-12 col-xs-12 col-xs-12">
          <account-manager-summary></account-manager-summary>
        </div>
      </div>

      <div class="row component-row">
        <h2><strong>WHAT DO YOU WANT TO DO?</strong></h2>
        <div class="top-boxes">
          <div class="row">
            <div class="col-lg-4 col-md-4 col-sm-12 col-xs-12">
              <a href="/account-manager/award-points">
                <img src="../../../../assets/images/search-box.png" />
              </a>
            </div>
            <div class="col-lg-4 col-md-4 col-sm-12 col-xs-12">
              <a href="/account-manager/points-awarded-summary">
                <img src="../../../../assets/images/view-box.png" />
              </a>
            </div>
            <div class="col-lg-4 col-md-4 col-sm-12 col-xs-12">
              <a href="/account-manager/contact-us">
                <img src="../../../../assets/images/help-box.png" />
              </a>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>

  <app-footer></app-footer>
</div>
