<div class="vet-vet-points-summary">
  <app-header heading-copy="# {{ title }}" top-banner-url="{{ banner }}"></app-header>
  <div class="container">
    <div [innerHtml]="body"></div>
    <div class="component-row">
      <div class="row" *ngIf="pointsSummary">
        <div class="col-lg-12 points-summary">
          <div class="row banner">
            <div class="col-lg-4 col-xs-12">
              <h3>{{ pointsSummary.earned | number: '1.0-0':'en' }}</h3>
              <div>TOTAL POINTS EARNED</div>
            </div>
            <div class="col-lg-4 col-xs-12">
              <h3>{{ pointsSummary.available | number: '1.0-0':'en' }}</h3>
              <div>POINTS AVAILABLE TO SPEND</div>
            </div>
            <div class="col-lg-4 col-xs-12">
              <h3>{{ pointsSummary.redeemed | number: '1.0-0':'en' }}</h3>
              <div>POINTS REDEEMED</div>
            </div>
          </div>
        </div>
      </div>

      <div class="row" *ngIf="activitySummary">
        <div class="col-lg-12 points-summary">
          <table class="table">
            <thead>
              <tr>
                <th>ACTIVITY</th>
                <th>TOTAL POINTS EARNED</th>
              </tr>
            </thead>
            <tbody>
              <tr>
                <td>FELINE HYPERTHYROIDISM AWARENESS</td>
                <td>0</td>
              </tr>
              <tr>
                <td>ARTHRITIS AWARENESS</td>
                <td>0</td>
              </tr>
              <tr>
                <td>DERMATOLOGY</td>
                <td>0</td>
              </tr>
              <tr>
                <td>NURSE ACADEMY</td>
                <td>0</td>
              </tr>
            </tbody>
            <!-- <tbody *ngIf="activitySummary.pointsSummaryDetails.length > 0">
              <tr *ngFor="let pointSummaryDetail of activitySummary.pointsSummaryDetails">
                <td [innerHtml]="pointSummaryDetail.reference"></td>
                <td>{{ pointSummaryDetail.totalPoints | number: '1.0-0':'en' }}</td>
              </tr>
            </tbody> -->
          </table>
        </div>
      </div>
    </div>
    <div *ngIf="activitySummary" class="component-row">
      <div class="row">
        <div class="col-sm-12">
          <p>&nbsp;</p>
          <h3>REWARD REDEMPTION SUMMARY</h3>
        </div>
      </div>
      <div class="row">
        <div class="col-sm-12">
          <table class="table">
            <thead>
              <tr>
                <th>DATE</th>
                <th>ORDER NUMBER</th>
                <th>PRODUCT DESCRIPTION</th>
                <th>QUANTITY</th>
                <th>POINTS</th>
              </tr>
            </thead>
            <tbody>
              <tr *ngFor="let item of activitySummary.redeemedRewards">
                <td>{{ item.dateRedeemed | date }}</td>
                <td>{{ item.orderId }}</td>
                <td>{{ item.description }}</td>
                <td>{{ item.quantity }}</td>
                <td>{{ item.points }}</td>
              </tr>
            </tbody>
          </table>
        </div>
      </div>
    </div>
    <app-footer></app-footer>
  </div>
</div>
