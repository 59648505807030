import { Injectable } from '@angular/core';
import { HttpRequest, HttpHandler, HttpEvent, HttpInterceptor } from '@angular/common/http';
import { Observable } from 'rxjs/Observable';
import { environment } from '../../../environments/environment';
import { Auth } from 'aws-amplify';
import { from } from 'rxjs';
import { switchMap } from 'rxjs/operators';
import { AuthenticationService } from 'src/app/shared/services/authentication.service';
import { UserSession } from 'src/app/shared/models/user-session';

@Injectable()
export class CoreTokenInterceptor implements HttpInterceptor {
  constructor(public authService: AuthenticationService) { }

  intercept(request: HttpRequest<any>, next: HttpHandler): Observable<HttpEvent<any>> {

    if (request.url.indexOf(environment.api.core.baseUrl) > -1) {

      const sessionCallback = async (): Promise<UserSession> => {
        const userSession: UserSession = {
          isAuthenticated: false,
          sessionData: null
        };
        userSession.isAuthenticated = await this.authService.checkCurrentUserStatus();

        try {

          if (userSession.isAuthenticated) {
            this.authService.setLoggedIn();
            userSession.sessionData = await Auth.currentSession();
          }

        } catch (e) {
          console.error(e);
        }

        return new Promise<UserSession>((resolve, reject) => {
          setTimeout(() => {
            resolve(userSession);
          }, 1);
        });
      };

      return from(sessionCallback()).pipe(
        switchMap((userSession: UserSession) => {

          if (userSession.isAuthenticated) {
            const token = userSession.sessionData.getIdToken().getJwtToken();
            const headers = {
              Authorization: `Bearer ${token}`,
            };
            const impersonateUserId = this.authService.getImpersonatedUserId();
            if (impersonateUserId) {
              headers['admin-impersonate-user-id'] = impersonateUserId;
            }
            request = request.clone({
              setHeaders: headers,
            });
            return next.handle(request);
          } else {
            return next.handle(request);
          }

        }),
      );
    }

    return next.handle(request);
  }
}
