import { Injectable, Injector } from '@angular/core';
import { Router } from '@angular/router';

@Injectable({
  providedIn: 'root',
})
export class ErrorService {
  constructor(private injector: Injector) {}

  handleError(message: string, code: string, err: any) {
    const router = this.injector.get(Router);

    switch (code) {
      case '404':
        router.navigateByUrl('/404');
        break;
      case 'UNAUTHORIZED':
        router.navigateByUrl('/401');
        break;
      case 'FORBIDDEN':
        router.navigateByUrl('/404');
        break;
      default:
        console.log(message, err);
        router.navigateByUrl('/500');
        break;
    }
  }
}
