import { Component, OnInit, OnDestroy } from '@angular/core';
import { Subscription, BehaviorSubject } from 'rxjs';
import { MemberManagerService } from 'src/app/vet/services/member-manager.service';

@Component({
  selector: 'vet-member-cat-list',
  templateUrl: './member-cat-list.component.html',
  styleUrls: ['./member-cat-list.component.scss'],
})
export class MemberCatListComponent implements OnInit, OnDestroy {
  private selectedMemberSub: Subscription;
  renderCatSelection = false;

  constructor(private managerService: MemberManagerService) {}

  ngOnInit(): void {
    this.selectedMemberSub = this.managerService.selectedMember$.subscribe((member) => {});
  }

  ngOnDestroy(): void {
    if (this.selectedMemberSub) {
      this.selectedMemberSub.unsubscribe();
    }
  }

  changeCat(event: Event): void {
    this.renderCatSelection = false;
    const catId = +(event.target as HTMLInputElement).value;
    // const catFiltered = this.cats.filter(cat => cat.catId === catId);
    // console.log('Change cat to:', catFiltered[0]);
    // this.selectedCat = catFiltered[0];
    // this.selectedCatDetails.next(this.selectedCat);
    this.renderCatSelection = true;
  }
}
