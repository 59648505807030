import { Component, Input, OnInit, OnDestroy } from '@angular/core';
import { Subscription } from 'rxjs';
import { Content } from 'src/app/core/models/content.model';
import { DynamicLayout } from 'src/app/core/models/dynamic-layout.model';
import { PointsSummary } from 'src/app/shared/models/points-summary';
import { PointsSummaryService } from 'src/app/shared/services/points-summary.service';
import { MemberManagerService } from '../../services/member-manager.service';

@Component({
  selector: 'vet-member-manager-page',
  templateUrl: './member-manager-page.component.html',
  styleUrls: ['./member-manager-page.component.scss'],
})
export class MemberManagerPageComponent implements OnInit, OnDestroy, DynamicLayout {
  @Input() baseContent: Content;

  title: string;
  banner: string;
  selectedMemberSub: Subscription;
  renderForms = false;
  message: string;
  addTestMessage: string;
  addPurchaseMessage: string;
  pointsSummary: PointsSummary;

  constructor(
    private managerService: MemberManagerService,
    private pointsSummaryService: PointsSummaryService,
  ) {}

  ngOnInit(): void {
    const titleField = this.baseContent.fields.find((f) => f.name === 'Title');
    const bannerField = this.baseContent.fields.find((f) => f.name === 'Banner');
    this.title = titleField.value.value;
    this.banner = bannerField.value.url;

    this.selectedMemberSub = this.managerService.selectedMember$.subscribe((member) => {
      this.renderForms = false;
      this.message = member.memberManagerMessage;
    });
  }

  ngOnDestroy() {
    if (this.selectedMemberSub) {
      this.selectedMemberSub.unsubscribe();
    }
  }
}
