import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { AcceptTermsComponent } from './components/accept-terms/accept-terms.component';
import { TransfersMadeBoxComponent } from './components/transfers-made-box/transfers-made-box.component';
import { RedeemPointsBoxComponent } from './components/redeem-points-box/redeem-points-box.component';
import { SharedModule } from '../shared/shared.module';
import { RouterModule } from '@angular/router';
import { AmplifyAngularModule } from 'aws-amplify-angular';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { ContentLoaderModule } from '@netbasal/ngx-content-loader';
import { AccountManagerHomeComponent } from './components/home/account-manager-home.component';
import { AccountManagerSummaryComponent } from './components/points-summary/account-manager-summary.component';
import { PointsAwardedSummaryComponent } from './components/points-awarded-summary/points-awarded-summary.component';
import { AwardPointsComponent } from './components/award-points/award-points.component';
import { MemberSearchResultsComponent } from './components/member-search-results/member-search-results.component';
import { DialogModule } from '../core/dialog/dialog.module';

@NgModule({
  declarations: [
    AcceptTermsComponent,
    AccountManagerHomeComponent,
    TransfersMadeBoxComponent,
    AccountManagerSummaryComponent,
    RedeemPointsBoxComponent,
    PointsAwardedSummaryComponent,
    AwardPointsComponent,
    MemberSearchResultsComponent,
  ],
  imports: [
    SharedModule,
    RouterModule,
    AmplifyAngularModule,
    FormsModule,
    ReactiveFormsModule,
    ContentLoaderModule,
    CommonModule,
    DialogModule,
  ],
  exports: [],
})
export class AccountManagerModule {}
