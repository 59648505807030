<div class="errorMessage" *ngIf="inufficientPointsMessage && inufficientPointsMessage !==''">
    <p><i>{{inufficientPointsMessage}}</i></p>
</div>
<div class="errorMessage" *ngIf="message && message !==''">
    <p><i>{{message}}</i></p>
</div>

<form *ngIf="showForm" [formGroup]="checkoutDetailsForm" (ngSubmit)="onSubmit()">
    <div class="row">
        <div class="col-md-12">
            <h3>
                Shipping Details
            </h3>
        </div>
    </div>
    <div class="row">
        <div class="col-md-12">
            <div class="form-group">
                <label>EMAIL ADDRESS</label>
                <input class="form-control" formControlName="email" readonly style="border: none;" type="text" />
            </div>
        </div>
    </div>
    <div class="row">
        <div class="col-md-6">
            <div class="form-group">
                <label>FIRST NAME</label>
                <input class="form-control" formControlName="firstName" readonly style="border: none;" type="text" />
            </div>
        </div>
        <div class="col-md-6">
            <div class="form-group">
                <label>LAST NAME</label>
                <input class="form-control" formControlName="lastName" readonly style="border: none;" type="text" />
            </div>
        </div>
    </div>
    <div class="row">
        <div class="col-md-12">
            <div class="row">
                <div class="col-md-12">
                    <div class="form-group">
                        <label>ADDRESS*</label>
                        <input class="form-control" formControlName="address1" type="text" />
                    </div>
                </div>
            </div>
            <div class="row">
                <div class="col-md-12">
                    <div class="form-group">
                        <label>ADDRESS LINE 2</label>
                        <input class="form-control" formControlName="address2" type="text" />
                    </div>
                </div>
            </div>
            <div class="row">
                <div class="col-md-6">
                    <div class="form-group">
                        <label>CITY*</label>
                        <input class="form-control" formControlName="city" type="text" />
                    </div>
                </div>
                <div class="col-md-6">
                    <div class="form-group">
                        <label>COUNTY</label>
                        <input class="form-control" formControlName="county" type="text" />
                    </div>
                </div>
            </div>
            <div class="row">
                <div class="col-md-6">
                    <div class="form-group">
                        <label>POST CODE*</label>
                        <input class="form-control" formControlName="postCode" type="text" />
                    </div>
                </div>
                <div class="col-md-6">
                    <div class="form-group">
                        <label>PHONE*</label>
                        <input class="form-control" formControlName="telephone" type="text" />
                    </div>
                </div>
            </div>
        </div>
    </div>
    <div class="row">
        <div class="col-md-6">
            <button type="submit" class="btn btn-primary" [disabled]="submitting ? 'disabled' : null">
                SUBMIT ORDER <i class="fa fa-spinner fa-spin" *ngIf="submitting"></i>
            </button>
        </div>
    </div>
</form>