<div *ngIf="renderForm">
    <form [formGroup]="catForm" (ngSubmit)="onSubmit()" class="form-standard">
        <div class="row">

            <div class="col-sm-6">
                <div class="row">
                    <div class="col-sm-6">
                        <label>Cat's Name</label>
                    </div>
                    <div class="col-sm-6">
                        <input formControlName="name" class="form-control text-input" type="text"
                            placeholder="Cat Name" />
                    </div>
                </div>
                <div class="row">
                    <div class="col-sm-6">
                        <label>Birth Date</label>
                    </div>
                    <div class="col-sm-6">
                        <input formControlName="birthDate" class="form-control text-input" type="date" />
                    </div>
                </div>
                <div class="row checkbox-input" [ngClass]="{'fldhidden' : submitModel.catId != null}">
                    <div class="col-md-1">
                        <input formControlName="isDeceased" type="checkbox" class="checkbox" />&nbsp;&nbsp;
                    </div>
                    <div class="col-md-11">
                        <label class="checkbox-label small-label">Treatment Stopped</label>
                    </div>
                </div>
            </div>
            <div class="col-sm-6">
                <div class="row">
                    <div class="col-sm-6">
                        <label>Next T4 Test Due</label>
                    </div>
                    <div class="col-sm-6">
                        <input formControlName="t4TestDueDate" class="form-control text-input" type="datetime-local" />
                    </div>
                </div>
                <div class="row">
                    <div class="col-sm-6">
                        <label>Next Thyronorm<sup>&reg;</sup><br>Prescription Due</label>
                    </div>
                    <div class="col-sm-6">
                        <input formControlName="prescriptionDueDate" class="form-control text-input" type="date" />
                    </div>
                </div>
                <div class="row">
                    <div class="col-sm-6">
                        <button type="submit" class="btn btn-primary">SUBMIT</button>

                    </div>

                </div>
                <div class="row">
                    <div class="col-sm-12">
                        <div *ngIf="errorMessages && errorMessages != ''" class="error-message">
                            <i>{{ errorMessages }}</i>
                        </div>
                    </div>
                </div>


            </div>
        </div>
    </form>
    <div class="row">
        <div class="col-sm-6">
            <button (click)="toggleTestResults()"
                class="btn btn-primary">{{ showTestResultHistory ? 'HIDE PREVIOUS T4 TEST RESULTS' : 'VIEW PREVIOUS T4 TEST RESULTS' }}</button>
        </div>
    </div>
    <div *ngIf="showTestResultHistory">
        <div class="row">
            <div class="col-md-12">
                <div class="cat-test-results">
                    <h5>{{catDetails.name}}'s PREVIOUS TEST RESULTS</h5>
                    <table *ngIf="catDetails.testResults.length > 0" class="table">
                        <thead>
                            <tr>
                                <th class="width100">DATE</th>
                                <th>TOTAL T4 RESULT</th>
                            </tr>
                        </thead>
                        <tbody>
                            <tr *ngFor="let testResult of catDetails.testResults">
                                <td class="w-250">{{testResult.dateOfTest | date: 'dd LLLL yyyy' }}</td>
                                <td>{{testResult.testResult}}</td>
                            </tr>
                        </tbody>

                    </table>
                    <table *ngIf="catDetails.testResults.length == 0" class="table">
                        <thead>
                            <tr>
                                <th>No T4 Test results to show currently.</th>
                            </tr>
                        </thead>
                    </table>
                </div>
            </div>
        </div>
    </div>
</div>