<div class="registration-success-page">
  <app-header heading-copy="# {{ title }}" top-banner-url="{{ banner }}"></app-header>

  <div class="container">
    <div class="registration-success">
      <span [innerHTML]="body"></span>
      <div class="row">
        <div class="col-lg-12 col-md-12 col-sm-12 col-xs-12">
          <a class="btn btn-primary" href="/vet/login">LOG IN NOW</a>
        </div>
      </div>
      <app-footer></app-footer>
    </div>
  </div>
</div>
