<form *ngIf="profileForm" [formGroup]="profileForm" (ngSubmit)="onSubmit()" onsubmit="return false"
    class="form-standard">


    <div *ngIf="bodyContent">
        <span [innerHtml]="bodyContent"></span>
    </div>
    <div *ngIf="message !== ''" class="text-center">
        <h3>{{message}}</h3>
    </div>
    <div class="row">
        <div class="col-lg-6 col-md-12 col-sm-12 col-xs-12">
            <div class="row">
                <div class="col-lg-6 col-md-6 col-sm-6 col-xs-12">
                    <label>FIRST NAME</label>
                </div>
                <div class="col-lg-6 col-md-6 col-sm-6 col-xs-12">
                    <input formControlName="firstName" class="form-control" type="text" />
                </div>
            </div>
            <div class="row">
                <div class="col-lg-6 col-md-6 col-sm-6 col-xs-12">
                    <label>LAST NAME</label>
                </div>
                <div class="col-lg-6 col-md-6 col-sm-6 col-xs-12">
                    <input formControlName="lastName" class="form-control" type="text" />
                </div>
            </div>
            <div class="row">
                <div class="col-lg-6 col-md-6 col-sm-6 col-xs-12">
                    <label>PHONE NUMBER</label>
                </div>
                <div class="col-lg-6 col-md-6 col-sm-6 col-xs-12">
                    <input formControlName="phone" class="form-control" type="text" />
                </div>
            </div>
            <div class="row">
                <div class="col-lg-6 col-md-6 col-sm-6 col-xs-12">
                    <label>EMAIL ADDRESS</label>
                </div>
                <div class="col-lg-6 col-md-6 col-sm-6 col-xs-12">
                    <input formControlName="email" class="form-control" type="text" />
                </div>
            </div>
        </div>
    </div>
    <div class="row">
        <div class="col-lg-6 col-md-12 col-sm-12 col-xs-12">
            <div class="row">
                <div class="col-lg-6 col-md-6 col-sm-6 col-xs-12">
                    <label>VET PRACTICE</label>
                </div>
                <div class="col-lg-6 col-md-6 col-sm-6 col-xs-12">
                    <input formControlName="companyName" class="form-control" type="text" />
                </div>
            </div>
        </div>
    </div>
    <div *ngIf="isVet">
        <div class="row">
            <div class="col-lg-6 col-md-12 col-sm-12 col-xs-12">
                <div class="row">
                    <div class="col-lg-6 col-md-6 col-sm-6 col-xs-12">
                        <label>VET PRACTICE POSTCODE</label>
                    </div>
                    <div class="col-lg-6 col-md-6 col-sm-6 col-xs-12">
                        <input formControlName="postcode" class="form-control" type="text" />
                    </div>
                </div>
            </div>
        </div>
        <div class="row">
            <div class="col-lg-6 col-md-12 col-sm-12 col-xs-12">
                <div class="row">
                    <div class="col-lg-6 col-md-6 col-sm-6 col-xs-12">
                        <label>SALES MANAGER NAME</label>
                    </div>
                    <div class="col-lg-6 col-md-6 col-sm-6 col-xs-12">
                        <input formControlName="salesManagerName" class="form-control" type="text" />
                    </div>
                </div>
            </div>
        </div>
    </div>

    <div class="row" *ngIf="!isVet">
        <div class="col-lg-12 col-md-12 col-sm-12 col-xs-12">
            <div class="form-group"><button class="btn btn-primary" type="submit">SAVE CHANGES</button></div>
        </div>
    </div>
</form>
<div *ngIf="cats && cats.length > 0">
    <h3>YOUR CAT'S DETAILS</h3>
    <div class="col-lg-12 col-md-12 col-sm-12 col-xs-12">

    </div>
    <div class="row" *ngFor="let cat of cats">
        <div class="col-lg-6 col-md-12 col-sm-12 col-xs-12">
            <div class="row">
                <div class="col-lg-6 col-md-6 col-sm-6 col-xs-12">
                    <label>NAME</label>
                </div>
                <div class="col-lg-6 col-md-6 col-sm-6 col-xs-12">
                    <label>{{cat.name}}</label>
                </div>
            </div>
            <div class="row">
                <div class="col-lg-6 col-md-6 col-sm-6 col-xs-12">
                    <label>DATE OF BIRTH</label>
                </div>
                <div class="col-lg-6 col-md-6 col-sm-6 col-xs-12">
                    <label>{{cat.birthDate | date: 'dd MMMM yyyy'}}</label>
                </div>
            </div>
            <div class="row">
                <div class="col-lg-6 col-md-6 col-sm-6 col-xs-12">
                    <label>AGE</label>
                </div>
                <div class="col-lg-6 col-md-6 col-sm-6 col-xs-12">
                    <label>{{cat.age}}</label>
                </div>
            </div>
            <div class="row">
                <div class="col-md-11">
                    <div (click)="updateCat(cat.id)">
                        <p class="underline"><i>My cat no longer requires Thyronorm</i></p>
                    </div>
                    <div *ngIf="cat.message !== ''">
                        <p><i>{{cat.message}}</i></p>
                    </div>
                </div>
            </div>
        </div>
        <hr />

    </div>
</div>
<form *ngIf="passwordForm" [formGroup]="passwordForm" (ngSubmit)="updatePassword()" onsubmit="return false"
    class="form-standard">
    <h3>UPDATE YOUR PASSWORD</h3>
    <div class="col-lg-12 col-md-12 col-sm-12 col-xs-12">

    </div>
    <div class="row">

        <div class="col-lg-12 col-md-12 col-sm-12 col-xs-12">
            <div *ngIf="passwordMessage !== ''">
                <h3>{{passwordMessage}}</h3>
            </div>
        </div>
        <div class="col-lg-6 col-md-12 col-sm-12 col-xs-12">
            <div class="row">
                <div class="col-lg-6 col-md-6 col-sm-6 col-xs-12">
                    <label>CURRENT PASSWORD</label>
                </div>
                <div class="col-lg-6 col-md-6 col-sm-6 col-xs-12">
                    <input formControlName="oldPassword" class="form-control" type="password" />
                </div>
            </div>
            <div class="row">
                <div class="col-lg-6 col-md-6 col-sm-6 col-xs-12">
                    <label>NEW PASSWORD</label>
                </div>
                <div class="col-lg-6 col-md-6 col-sm-6 col-xs-12">
                    <input formControlName="newPassword" class="form-control" type="password" />
                </div>
            </div>
            <div class="row">
                <div class="col-lg-6 col-md-6 col-sm-6 col-xs-12">
                    <label>CONFIRM NEW PASSWORD</label>
                </div>
                <div class="col-lg-6 col-md-6 col-sm-6 col-xs-12">
                    <input formControlName="confirmPassword" class="form-control" type="password" />
                </div>
            </div>
            <div class="row">
                <div class="col-lg-12 col-md-12 col-sm-12 col-xs-12">
                    <div class="form-group"><button class="btn btn-primary" type="submit">UPDATE
                            PASSWORD</button></div>
                </div>
            </div>
        </div>
    </div>
</form>