<a [routerLink]="[article.url]" _target="_blank" class="link-box">
  <div class="article-box">
    <div class="content-row">
      <div class="img-box">
        <img alt="" [src]="article.imageUrl" />
      </div>
      <div class="article-details">
        <div class="article-overview" [innerHTML]="article.overview"></div>
        <div class="article-read-more">
          <button class="btn btn-primary">VIEW MORE</button>
        </div>
      </div>
    </div>
  </div>
</a>
