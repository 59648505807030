import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { Routes, RouterModule } from '@angular/router';
import { AmplifyAngularModule } from 'aws-amplify-angular';
import { LoginRedirectComponent } from '../shared/components/login-redirect/login-redirect.component';
import { LoginImpersonateComponent } from '../shared/components/login-impersonate/login-impersonate.component'; //'./ /login-impersonate/login-impersonate.component';

const routes: Routes = [
  {
    path: 'impersonate',
    component: LoginImpersonateComponent,
  },
  {
    path: 'redirect',
    component: LoginRedirectComponent,
  },
];

@NgModule({
  declarations: [
    LoginRedirectComponent,
    LoginImpersonateComponent,
  ],
  imports: [RouterModule.forChild(routes), CommonModule, AmplifyAngularModule],
})
export class AuthenticationModule {}
