<header>
  <div class="top-banner">
    <img
      *ngIf="headerContent.bannerUrl != ''"
      [src]="headerContent.bannerUrl"
      class="banner-image"
    />
    <div class="absolute-wrapper">
      <div class="container">
        <div class="navigation-header">
          <div class="row">
            <div class="col-md-3">
              <div class="programme-logo">
                <a href="{{ redirectUrl }}">
                  <img src="../../../assets/images/advantage-programme-logo.png" />
                </a>
              </div>
            </div>
            <div class="col-md-9">
              <app-lower-nav></app-lower-nav>
            </div>
          </div>
        </div>
      </div>
    </div>

    <div class="container">
      <div class="header-text">
        <h1 *ngFor="let heading of headerContent.headings">
          <span [innerHTML]="heading"></span>
        </h1>
      </div>
    </div>
  </div>
</header>
