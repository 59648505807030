import { Component, OnInit, Input, Output, EventEmitter } from '@angular/core';
import { Cart } from 'src/app/vet/models/redemptions/cart';
import { CartProduct } from 'src/app/vet/models/redemptions/cart-product';
import { ProductCatalogueService } from 'src/app/vet/services/product-catalogue.service';

@Component({
  // tslint:disable-next-line: component-selector
  selector: 'pc-cart-item',
  templateUrl: './cart-item.component.html',
  styleUrls: ['./cart-item.component.scss'],
})
export class CartItemComponent implements OnInit {
  @Input() cartProduct: CartProduct;
  @Input() updateDisabled = false;
  @Output() cartUpdated: EventEmitter<Cart> = new EventEmitter();
  updating = false;
  constructor(private productCatalogueService: ProductCatalogueService) { }

  ngOnInit() {
    console.log(this.cartProduct);
  }

  async updateQuantity() {
    this.updating = true;
    const cart = await this.productCatalogueService.setProductQuantityInCart(
      this.cartProduct.productId,
      this.cartProduct.quantity,
    );
    this.updating = false;

    this.cartUpdated.emit(cart);
  }

  async removeProduct() {
    this.updating = true;
    const cart = await this.productCatalogueService.removeProductFromCart(
      this.cartProduct.productId,
    );
    this.updating = false;

    this.cartUpdated.emit(cart);
  }
}
