import { Component, OnInit, Input } from '@angular/core';
import { Cart } from 'src/app/vet/models/redemptions/cart';
import { ProductCatalogueService } from 'src/app/vet/services/product-catalogue.service';

@Component({
  // tslint:disable-next-line: component-selector
  selector: 'pc-cart',
  templateUrl: './cart.component.html',
  styleUrls: ['./cart.component.scss'],
})
export class CartComponent implements OnInit {
  @Input() updateDisabled = false;
  public cart: Cart;
  public loading = true;
  constructor(private productCatalogService: ProductCatalogueService) { }

  async ngOnInit() {
    this.cart = await this.productCatalogService.getCart();
    this.productCatalogService.updateCurrentCart(this.cart);
    this.loading = false;
  }

  onCartUpdated(cart: Cart) {
    console.log('Cart Updated', cart);
    this.cart = cart;
    this.productCatalogService.updateCurrentCart(cart);
  }

  getTotal() {
    let total = 0;

    this.cart.products.forEach(cp => {
      total += cp.points * cp.quantity;
    });

    return total;
  }
}
